import React from "react";
import moment from "moment";
import AudioChip from "components/core/AudioChip";
import { setAudioID } from "reduxStore/audioplayer";
import { NotificationStatusHistoryDto } from "services/notification";
import { AccountFeatureDto } from "services/users";

export const openInNewTab = (url: string) => {
  const newWindow = window.open();
  newWindow.location = url;
  newWindow.focus();
};

export const colorsList = ["#eae8fd", "#e4ffef", "#ffd4d4"];

export const subJobColorList = ["#D2D6ED", "#CAF0D4", "#F0F0B1", "#F0CACA"];

export const transformObjectWithPrefix = (prefix: string, object: object) => {
  return Object.fromEntries(
    Object.entries(object).map((e) => [`${prefix}_${e[0]}`, e[1]])
  );
};

export const getTranscriptStatusText = (
  status: "COMPLETED" | "PENDING" | "IN_PROGRESS"
) => {
  switch (status) {
    case "PENDING":
      return "Is Pending";
    case "IN_PROGRESS":
      return "In Progress";
  }
};

export const getPlatformStatus = (
  key: string,
  role: "PAH" | "SAH" | "NAH"
): { mobile: boolean; web: boolean } => {
  switch (key) {
    case "timesheet":
      if (role === "PAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: false,
          web: false,
        };
      }
    case "documents":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: true,
          web: false,
        };
      }
    case "documents.view-sign":
      if (role === "PAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: true,
          web: false,
        };
      }
    case "documents.manage":
      if (role === "PAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: false,
          web: false,
        };
      }
    case "pst":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: false,
          web: false,
        };
      }
    case "pssc":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: false,
          web: false,
        };
      }
    case "notifications":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: true,
          web: false,
        };
      }
    case "notifications.create":
      if (role === "PAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: true,
          web: false,
        };
      }
    case "notifications.make-safe":
      if (role === "PAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: true,
          web: false,
        };
      }
    case "notifications.repair-work":
      if (role === "PAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: true,
          web: false,
        };
      }
    case "swms":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: true,
          web: false,
        };
      }
    case "swms.create":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: false,
          web: false,
        };
      }
    case "swms.view":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: true,
          web: false,
        };
      }
    case "swms.send":
      if (role === "PAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: true,
          web: false,
        };
      }
    case "jsa":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: true,
          web: false,
        };
      }
    case "audits":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: true,
          web: false,
        };
      }
    case "audits.sharing":
      if (role === "PAH") {
        return {
          mobile: false,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: false,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: false,
          web: false,
        };
      }
    case "progress":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: false,
          web: false,
        };
      }
    case "variations":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: false,
          web: false,
        };
      }
    case "defects":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: false,
          web: false,
        };
      }
    case "incident":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: false,
          web: false,
        };
      }
    case "purchases":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: false,
          web: false,
        };
      }
    case "inductions":
      if (role === "PAH") {
        return {
          mobile: true,
          web: true,
        };
      } else if (role === "SAH") {
        return {
          mobile: true,
          web: false,
        };
      } else if (role === "NAH") {
        return {
          mobile: true,
          web: false,
        };
      }
    default:
      return {
        mobile: false,
        web: false,
      };
  }
};

export const getFeatureTitle = (key: string) => {
  switch (key) {
    case "timesheet":
      return "TimeSheet";
    case "documents":
      return "Site Documents & Information";
    case "documents.view-sign":
      return "View & Sign Site Documents";
    case "documents.manage":
      return "Manage Site Documents";
    case "pst":
      return "Pre-Start Talk";
    case "pssc":
      return "Pre-Start Safety Check";
    case "notifications":
      return "Hazard Response";
    case "notifications.create":
      return "Hazard Notifications & Immediate Make Safe";
    case "notifications.make-safe":
      return "Make Safe Actions";
    case "notifications.repair-work":
      return "Repair Work";
    case "swms":
      return "SWMS Management";
    case "swms.create":
      return "Generate SWMS";
    case "swms.view":
      return "View & Sign SWMS";
    case "swms.send":
      return "Send SWMS";
    case "audits.sharing":
      return "Audit Sharing";
    case "jsa":
      return "JSA Report";
    case "audits":
      return "Audit Tool";
    case "progress":
      return "Progress Reports";
    case "variations":
      return "Work Variations";
    case "defects":
      return "Work Defects";
    case "incident":
      return "Incident Report";
    case "purchases":
      return "Record Purchases";
    case "inductions":
      return "Courses/Inductions";
    default:
      return key;
  }
};

declare type featureMethods = {
  showTimesheet?: () => boolean;
  showDocuments?: () => boolean;
  showDocumentsviewsign?: () => boolean;
  showDocumentsmanage?: () => boolean;
  showPst?: () => boolean;
  showPssc?: () => boolean;
  showNotifications?: () => boolean;
  showNotificationscreate?: () => boolean;
  showNotificationsmakesafe?: () => boolean;
  showNotificationsrepairwork?: () => boolean;
  showSwms?: () => boolean;
  showSwmscreate?: () => boolean;
  showSwmsview?: () => boolean;
  showSwmssend?: () => boolean;
  showJsa?: () => boolean;
  showAudits?: () => boolean;
  showProgress?: () => boolean;
  showIncident?: () => boolean;
  showVariations?: () => boolean;
  showDefects?: () => boolean;
  showPurchases?: () => boolean;
  showInductions?: () => boolean;
};

export const calculateFeatures = (features: AccountFeatureDto[]) => {
  const featureMethods: featureMethods = {};

  features.forEach(({ feature, enabled }) => {
    const featureName = feature.split(".").join("").split("-").join("");

    const methodName = `show${
      featureName.charAt(0).toUpperCase() + featureName.slice(1)
    }`;
    featureMethods[methodName] = () => enabled;
  });

  return featureMethods;
};

export const reorder = (
  list: Array<any>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const subStatusToAllow = ["none", "raised", "received"];

export const FormatRiskScore = (value: string) => {
  const singleAlphabetRegex = RegExp("^[a-zA-Z]{1}$");

  if (singleAlphabetRegex.test(value)) {
    return ` ${value.toUpperCase()} ()`;
  } else {
    return value;
  }
};

export function dataURLToBlob(dataURL: string) {
  const parts = dataURL.split(";base64,");
  const contentType = parts[0].split(":")[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
}

export const isUUID = (uuid: string) => {
  const uuidRegex =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
  return uuidRegex.test(uuid);
};

export const getSWMSRedirectPath = (url: string) => {
  switch (url) {
    case "project_details":
      return "/swms/contractor-detail";

    case "employees":
      return "/swms/section-three";

    case "confirm_details":
      return "/swms/generated";

    case "sds_msds":
      return "/swms/generate-swms";

    case "trades":
      return "/swms/work-activities";

    case "work_activities":
      return "/swms/links";

    default:
      return undefined;
  }
};
export function generatePassword() {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export function getTableFinalValue(value?: string, defaultValue?: string) {
  if (!value) return defaultValue ?? "-";

  if (value === "") return defaultValue ?? "-";

  return value;
}

export function checkValue(value) {
  if (value && value != "") return value;
  return "N/A";
}

export function getPermissionName(key) {
  if (key == "project_documents") {
    return "Project documents";
  } else if (key == "job_documents") {
    return "Site information and documents issued to staff or contractors";
  } else if (key == "purchases") {
    return "Recorded purchases";
  } else if (key == "notifications") {
    return "Safety notifications raised and responses";
  } else if (key == "transactions") {
    return "Times on site";
  } else if (key == "progress_reports") {
    return "Progress reports";
  } else if (key == "prestart_sc") {
    return "Pre start safety checklists";
  } else if (key == "eodc") {
    return "End of day safety checklists";
  } else if (key == "prestart_talks") {
    return "Pre start talks";
  } else if (key == "swms_whs") {
    return "SWMS & WHS Management Plans";
  } else if (key == "employee_details") {
    return "Employee details";
  } else if (key == "audits") {
    return "Completed audits";
  } else if (key == "variations") {
    return "Work Variations & Defect Reports";
  } else {
    return "";
  }
}

export function getTimeDiffrence(localCreatedOn) {
  // milliseconds between now & Christmas
  if (!localCreatedOn) return "";
  const diffInMs = new Date().getTime() - new Date(localCreatedOn).getTime();

  const diffDays = Math.floor(diffInMs / 86400000); // days
  const diffHrs = Math.floor((diffInMs % 86400000) / 3600000); // hours
  const diffMins = Math.round(((diffInMs % 86400000) % 3600000) / 60000); // minutes

  return `${diffDays ? `${diffDays} days` : ""} ${
    diffHrs ? `${diffHrs} hours` : ""
  } ${diffMins ? `${diffMins} mins` : ""}`;
}

export function getActionInNotificationFromType(type: string) {
  if (type == undefined) return false;
  const splitedType = type.split("/").shift();
  if (splitedType == "make_safe") {
    return "Make Safe";
  } else if (splitedType == "repair_work") {
    return "Repair Work";
  } else if (splitedType == "notification") {
    return "Notification";
  } else {
    return "";
  }
}

export function getStatusPlainTest(
  notificationHistory: NotificationStatusHistoryDto
) {
  switch (notificationHistory?.extendedData?.type) {
    case "notification/raised":
      return "Raised";
    case "notification/received":
      return "Received";
    case "notification/reviewed":
      return "Reviewed";
    case "make_safe/issued_self":
      return "Self issued Make Safe created";
    case "make_safe/issued_self/timesheet_in":
      return "Self issued, employee signed in to site";
    case "make_safe/issued_self/timesheet_out":
      return "Self issued, employee signed out of site";
    case "make_safe/issued_self/in":
      return "Self issued, Make Safe action commenced by employee";
    case "make_safe/issued_self/out":
      return "Self issued Make Safe Action completed onsite";
    case "make_safe/issued_self/awaiting_review":
      return "Self issued, Make Safe Awaiting review";
    case "make_safe/issued_self/complete_onsite":
      return "Self issued, Make Safe action marked as complete onsite";
    case "make_safe/issued_self/review_complete":
      return "Review complete";
    case "repair_work/assigned":
    case "make_safe/assigned":
      return (
        <div>
          <span>Issued to employee:</span>
          {notificationHistory?.extendedData?.employees.map((e, id) => (
            <p className="m-0" key={id}>
              {e}
            </p>
          ))}
        </div>
      );
    case "make_safe/assigned/accepted":
      return "Accepted by employee";
    case "make_safe/assigned/declined":
      return "Declined by employee";
    case "make_safe/assigned/timesheet_in":
      return "Employee signed in to site";
    case "make_safe/assigned/timesheet_out":
      return "Employee signed out of site";
    case "make_safe/assigned/in":
      return "Work commenced by employee";
    case "make_safe/assigned/out":
      return "Work paused by employee";
    case "make_safe/assigned/break_in":
      return "Break ended by employee";
    case "make_safe/assigned/break_out":
      return "Break commenced by employee";
    case "make_safe/assigned/complete_onsite":
      return "Marked as complete onsite";
    case "make_safe/assigned/awaiting_review":
      return "Awaiting review";
    case "make_safe/assigned/review_complete":
      return "Review complete";
    case "make_safe/contracted":
      return "Issued to contractor";
    case "make_safe/contracted/accepted":
      return "Accepted by contractor";
    case "make_safe/contracted/declined":
      return "Declined by contractor";
    case "make_safe/contracted/assigned":
      return "Work assigned to contractor employee";
    case "make_safe/contracted/assigned/accepted":
      return "Work accepted by contractor employee";
    case "make_safe/contracted/assigned/declined":
      return "Work declined by contractor employee";
    case "make_safe/contracted/in":
      return "Work commenced by contractor employee";
    case "make_safe/contracted/out":
      return "Work paused by contractor employee";
    case "make_safe/contracted/timesheet_in":
      return "Contractor employee signed in to site";
    case "make_safe/contracted/timesheet_out":
      return "Contractor employee signed out of site";
    case "make_safe/contracted/break_in":
      return "Break ended by contractor employee";
    case "make_safe/contracted/break_out":
      return "Break commenced by contractor employee";
    case "make_safe/contracted/complete_onsite":
      return "Marked as complete onsite";
    case "make_safe/contracted/awaiting_review":
      return "Awaiting review by contractor";
    case "make_safe/contracted/review_complete":
      return "Review complete";
    case "make_safe/issuer/review_complete":
      return "Review complete by issuer";
    case "repair_work/contracted":
      return "Issued to contractor";
    case "repair_work/assigned/accepted":
      return "Accepted by employee";
    case "repair_work/assigned/declined":
      return "Declined by employee";
    case "repair_work/contracted/assigned":
      return "Work assigned to contractor employee";
    case "repair_work/contracted/assigned/accepted":
      return "Work accepted by contractor employee";
    case "repair_work/contracted/assigned/declined":
      return "Work declined by contractor employee";
    case "repair_work/assigned/in":
      return "Work commenced by employee";
    case "repair_work/assigned/out":
      return "Work paused by employee";
    case "repair_work/assigned/timesheet_in":
      return "Employee signed in to site";
    case "repair_work/assigned/timesheet_out":
      return "Employee signed out of site";
    case "repair_work/assigned/break_in":
      return "Break ended by employee";
    case "repair_work/assigned/break_out":
      return "Break commenced by employee";
    case "repair_work/assigned/complete_onsite":
      return "Marked as complete onsite";
    case "repair_work/assigned/awaiting_review":
      return "Awaiting review";
    case "repair_work/assigned/review_complete":
      return "Review complete";
    case "repair_work/contracted/accepted":
      return "Accepted by contractor";
    case "repair_work/contracted/declined":
      return "Declined by contractor";
    case "repair_work/contracted/in":
      return "Work commenced by contractor";
    case "repair_work/contracted/out":
      return "Work paused by contractor";
    case "repair_work/contracted/timesheet_in":
      return "Contractor employee signed in to site";
    case "repair_work/contracted/timesheet_out":
      return "Contractor employee signed out of site";
    case "repair_work/contracted/break_in":
      return "Break ended by contractor employee";
    case "repair_work/contracted/break_out":
      return "Break commenced by contractor employee";
    case "repair_work/contracted/complete_onsite":
      return "Marked as complete onsite";
    case "repair_work/contracted/awaiting_review":
      return "Awaiting review by contractor";
    case "repair_work/contracted/review_complete":
      return "Review complete by contractor";
    case "repair_work/issuer/awaiting_review":
      return "Awaiting review by issuer";
    case "repair_work/issuer/review_complete":
      return "Review complete by issuer";
    default:
      return "";
  }
}

export function getTeamMember(
  extendedData,
  isContractor: boolean,
  contractedOut: boolean,
  subContractedOut: boolean,
  isNotificationOwner: boolean
) {
  switch (extendedData.type) {
    case "notification/raised":
    case "notification/received":
      return isNotificationOwner ? (
        <div>
          <span>
            {`${extendedData?.notification?.createdBy?.firstName ?? ""} ${
              extendedData?.notification?.createdBy?.lastName ?? ""
            }`.trim() || "-"}
          </span>
        </div>
      ) : (
        <div>
          <span className="fw-600 fs-6 my-2">
            {extendedData?.notification?.createdBy?.organizationName || "-"}
          </span>
          <br />
          <span>
            {`${extendedData?.notification?.createdBy?.firstName ?? ""} ${
              extendedData?.notification?.createdBy?.lastName ?? ""
            }`.trim() || "-"}
          </span>
        </div>
      );

    case "make_safe/contracted/timesheet_in":
    case "make_safe/contracted/timesheet_out":
    case "make_safe/contracted/out":
    case "make_safe/contracted/in":
    case "make_safe/contracted/break_in":
    case "make_safe/contracted/break_out":
    case "repair_work/contracted/timesheet_out":
      return (
        <div>
          {contractedOut ? (
            <>
              <span className="fw-600 fs-6 my-2">
                {extendedData?.companyName || "-"}
              </span>
              <br />
              <span className="m-0">{extendedData?.employeeName || "-"}</span>
            </>
          ) : (
            <span className="m-0">{extendedData?.employeeName || "-"}</span>
          )}
        </div>
      );
    case "make_safe/assigned":
    case "repair_work/assigned":
      return contractedOut ? (
        <div>
          {extendedData?.companyName && (
            <>
              <span className="fw-600 fs-6 my-2">
                {extendedData?.companyName}
              </span>
              <br />
            </>
          )}
          <span>{extendedData?.manager}</span>
        </div>
      ) : (
        extendedData?.manager || "-"
      );
    case "make_safe/contracted":
    case "repair_work/contracted":
      return subContractedOut ? (
        <div>
          {extendedData?.issuerCompanyName && (
            <div className="my-2">
              <span className="fw-600 fs-6 ">
                {extendedData?.issuerCompanyName}
              </span>
            </div>
          )}
          <span>{extendedData?.manager}</span>
        </div>
      ) : (
        extendedData?.manager || "-"
      );
    case "make_safe/issued_self/timesheet_in":
    case "make_safe/issued_self/timesheet_out":
    case "make_safe/assigned/timesheet_in":
    case "make_safe/assigned/timesheet_out":
    case "make_safe/assigned/break_out":
    case "make_safe/assigned/accepted":
    case "make_safe/assigned/declined":
    case "repair_work/assigned/declined":
    case "repair_work/contracted/timesheet_in":
    case "repair_work/assigned/in":
    case "repair_work/assigned/timesheet_in":
    case "repair_work/assigned/timesheet_out":
    case "repair_work/assigned/out":
      return contractedOut ? (
        <div>
          {extendedData?.companyName && (
            <>
              <span className="fw-600 fs-6">{extendedData?.companyName}</span>
              <br />
            </>
          )}
          <span>{extendedData?.employeeName}</span>
        </div>
      ) : (
        extendedData?.employeeName || "-"
      );
    case "make_safe/issued_self/complete_onsite":
    case "make_safe/contracted/awaiting_review":
      return isContractor ? (
        extendedData?.managerName || "-"
      ) : (
        <span className="fw-600">{extendedData?.sourceCompanyName || "-"}</span>
      );
    case "make_safe/contracted/review_complete":
    case "make_safe/issuer/review_complete":
    case "make_safe/contracted/accepted":
    case "repair_work/contracted/accepted":
    case "make_safe/contracted/declined":
    case "repair_work/contracted/declined":
    case "repair_work/contracted/in":
    case "repair_work/contracted/out":
      return isContractor ? (
        extendedData?.managerName || "-"
      ) : (
        <span className="fw-600">{extendedData?.companyName || "-"}</span>
      );
    default:
      return extendedData.employeeName || "-";
  }
}

export function getDate(notificationHistory: NotificationStatusHistoryDto) {
  switch (notificationHistory.extendedData.type) {
    case "notification/raised":
    case "notification/received":
      return moment(
        notificationHistory.extendedData?.notification.createdOn
      ).format("DD/MM/YYYY");
    case "make_safe/issued_self/timesheet_in":
    case "make_safe/issued_self/timesheet_out":
    case "make_safe/assigned/timesheet_in":
    case "make_safe/assigned/timesheet_out":
    case "make_safe/assigned/break_in":
    case "make_safe/assigned/break_out":
    case "make_safe/contracted/timesheet_in":
    case "make_safe/contracted/timesheet_out":
    case "make_safe/contracted/break_in":
    case "make_safe/contracted/break_out":
    case "repair_work/contracted/timesheet_in":
    case "repair_work/assigned/timesheet_in":
    case "repair_work/contracted/timesheet_out":
    case "repair_work/assigned/timesheet_out":
    case "repair_work/assigned/break_in":
    case "repair_work/assigned/break_out":
    case "repair_work/contracted/break_in":
    case "repair_work/contracted/break_out":
      return moment(notificationHistory.extendedData?.localCreatedOn).format(
        "DD/MM/YYYY"
      );
    default:
      return moment(notificationHistory.createdOn).format("DD/MM/YYYY");
  }
}

export const getDescriptionFromNotificationData = (
  historyItem: NotificationStatusHistoryDto,
  dispatch,
  audioID
) => {
  const extendedData = historyItem.extendedData;
  switch (extendedData?.type) {
    case "notification/raised":
    case "notification/received":
    case "make_safe/issued_self":
      return (
        <div className="text-start p-2 white-space-normal">
          <span>Issue/Hazard: </span>
          <p className="m-0">{extendedData?.notification?.description}</p>
        </div>
      );
    case "notification/reviewed":
      return (
        <div className="text-start p-2 white-space-normal">
          <p className="m-0 mb-2">
            {getNotificationResponse(
              historyItem.status,
              historyItem?.substatus
            )}
          </p>
          <p className="m-0 mb-2">Admin Note:</p>
          <p>{extendedData?.note}</p>
        </div>
      );

    case "make_safe/issued_self/in":
      return (
        <div className="text-start p-2">
          <div className="pb-2">
            <span>Recommendations from party who found the issue:</span>
            <br />
            {extendedData?.file ? (
              <AudioChip
                refetchAudio
                audioData={extendedData?.file || {}}
                isPlayDto={extendedData?.file?.id === audioID}
                onClickPlay={(value) => {
                  dispatch(setAudioID(value.id));
                }}
              />
            ) : (
              <i className="text-break">{extendedData?.suggestedResolution}</i>
            )}
          </div>
        </div>
      );
    case "make_safe/assigned":
    case "repair_work/assigned":
      return (
        <div className="text-start p-2">
          <div className="pb-2">
            <span>Recommendations from party who found the issue: </span>
            <br />
            {extendedData?.file ? (
              <AudioChip
                refetchAudio
                audioData={extendedData?.file || {}}
                isPlayDto={extendedData?.file?.id === audioID}
                onClickPlay={(value) => {
                  dispatch(setAudioID(value.id));
                }}
              />
            ) : (
              <i className="text-break">{extendedData?.suggestedResolution}</i>
            )}
          </div>
          <div className="pb-2">
            <span>{`Job instructions from ${
              extendedData?.manager || ""
            }: `}</span>
            <br />
            <i className="text-break">{extendedData?.additionalInstructions}</i>
          </div>
        </div>
      );
    case "make_safe/contracted":
    case "repair_work/contracted":
      return (
        <div className="text-start p-2">
          <div className="pb-2">
            <span>Recommendations from party who found the issue: </span>
            <br />
            {extendedData?.file ? (
              <AudioChip
                refetchAudio
                audioData={extendedData?.file || {}}
                isPlayDto={extendedData?.file?.id === audioID}
                onClickPlay={(value) => {
                  dispatch(setAudioID(value.id));
                }}
              />
            ) : (
              <i className="text-break">{extendedData?.suggestedResolution}</i>
            )}
          </div>
          <div className="pb-2">
            <span>{`Job instructions from ${
              extendedData?.manager || ""
            }: `}</span>
            <br />
            <i className="text-break">{extendedData?.additionalInstructions}</i>
          </div>
          <div className="pb-2">
            <span>Scheduled for:</span>
            <i>{moment(extendedData?.startDate).format("hh:mm a")}</i>
          </div>
        </div>
      );
    case "make_safe/issued_self/out":
    case "make_safe/contracted/out":
    case "make_safe/issued_self/awaiting_review":
    case "make_safe/issued_self/complete_onsite":
      return (
        <div className="text-start p-2">
          <div className="pb-2">
            <span>Details of work performed:</span>
            <br />
            {extendedData?.file ? (
              <AudioChip
                refetchAudio
                audioData={extendedData?.file || {}}
                isPlayDto={extendedData?.file?.id === audioID}
                onClickPlay={(value) => {
                  dispatch(setAudioID(value.id));
                }}
              />
            ) : (
              <i className="text-break">
                {extendedData?.suggestedResolution ||
                  extendedData?.descriptionOfWork}
              </i>
            )}
          </div>
        </div>
      );
    case "make_safe/assigned/in":
    case "repair_work/contracted/in":
      return (
        <div className="text-start p-2">
          <div className="pb-2">
            <span>Onsite assessment and work recommendations:</span>
            <br />
            {extendedData?.file ? (
              <AudioChip
                refetchAudio
                audioData={extendedData?.file || {}}
                isPlayDto={extendedData?.file?.id === audioID}
                onClickPlay={(value) => {
                  dispatch(setAudioID(value.id));
                }}
              />
            ) : (
              <i className="text-break">{extendedData?.suggestedResolution}</i>
            )}
          </div>
        </div>
      );

    case "make_safe/contracted/in":
    case "repair_work/assigned/in":
      return (
        <div className="text-start p-2">
          <div className="pb-2">
            <span>Onsite assessment and work recommendations:</span>
            <br />
            {extendedData?.file ? (
              <AudioChip
                refetchAudio
                audioData={extendedData?.file || {}}
                isPlayDto={extendedData?.file?.id === audioID}
                onClickPlay={(value) => {
                  dispatch(setAudioID(value.id));
                }}
              />
            ) : (
              <i className="text-break">{extendedData?.proposedWorks}</i>
            )}
          </div>
        </div>
      );
    case "make_safe/assigned/out":
    case "repair_work/contracted/out":
    case "repair_work/assigned/out":
      return (
        <div className="text-start p-2">
          <div className="pb-2">
            <span>Description of work performed:</span>
            <br />
            {extendedData?.file ? (
              <AudioChip
                refetchAudio
                audioData={extendedData?.file || {}}
                isPlayDto={extendedData?.file?.id === audioID}
                onClickPlay={(value) => {
                  dispatch(setAudioID(value.id));
                }}
              />
            ) : (
              <i className="text-break">{extendedData?.descriptionOfWork}</i>
            )}
          </div>
        </div>
      );
    case "make_safe/contracted/accepted":
    case "repair_work/contracted/accepted":
    case "make_safe/assigned/accepted":
    case "repair_work/assigned/accepted":
      return (
        <div className="text-start p-2">
          <span>
            Confirmed for:
            <br />
            {moment(extendedData?.startDate).format("DD/MM/YYYY - hh:mm a")}
          </span>
        </div>
      );
    case "make_safe/assigned/awaiting_review":
    case "make_safe/contracted/awaiting_review":
    case "make_safe/contracted/complete_onsite":
    case "make_safe/assigned/complete_onsite":
    case "repair_work/assigned/complete_onsite":
    case "repair_work/contracted/complete_onsite":
      return (
        <div className="text-start p-2">Review pending for work completed</div>
      );
    case "make_safe/issuer/review_complete":
      return <div className="text-start p-2">Review pending</div>;
    case "make_safe/assigned/review_complete":
      return <div className="text-start p-2">Review complete</div>;
    case "make_safe/contracted/declined":
    case "make_safe/issued_self/timesheet_in":
    case "repair_work/contracted/declined":
    case "make_safe/assigned/declined":
    case "repair_work/assigned/declined":
    case "repair_work/contracted/timesheet_in":
    case "repair_work/contracted/timesheet_out":
    case "repair_work/assigned/timesheet_in":
    case "repair_work/assigned/timesheet_out":
      return "";
    default:
      return "";
  }
};

export const getPhotoQuestion = (type: string): string | null => {
  switch (type) {
    case "job_out_site_ready_photo":
      return "Photos showing site secure/ready for public:";
    case "job_out_completed_work_photo":
      return "Photos of work:";
    case "job_out_safety_steps_photo":
      return "Photos showing any safety steps done to secure site:";
    case "job_out_clear_rubbish_photo":
      return "Photos of site clear of rubbish:";
    case "job_out_safety_barricades_photo":
      return "Photos of site with displayed barricades/tape:";
    case "job_out_safety_sign_photo":
      return "Photos of site with displayed safety signs:";
    case "job_out_clear_equipment_photo":
      return "Photos of site cleared of all materials/equipment associated with the work:";
    case "job_in_hazard_photo":
      return "Photos of any hazards:";
    case "job_in_safety_sign_photo":
      return "Photos of safety signs, barricades and tape:";
    case "job_in_proposed_works_photo":
      return "Photos of the work to be performed:";
    default:
      return null;
  }
};

export const getNotificationResponse = (
  status: string,
  substatus: string,
  showCreator = false
) => {
  const combineStatus = `${status}-${substatus}`;
  switch (combineStatus) {
    case "not_actioned-none":
      return `N/A`;
    case "not_actioned-raised":
      return `Action Required`;
    case "not_actioned-received":
      return `Action Required`;
    case "not_actioned-reviewed":
      return `Action Required`;
    case "pending-no_immediate_action":
      return `Marked No Immediate Action Required ${
        showCreator ? "by XXXX date/time" : ""
      }`;
    case "pending-issued_self":
      return `Incomplete`;
    case "pending-issued_contractor":
      return `Incomplete`;
    case "in_progress-work_in_progress":
      return `Incomplete`;
    case "review_required-pending_review":
      return `Incomplete`;
    case "completed-work_complete":
      return `Complete and Notification Closed ${
        showCreator ? "by XXXX date/time" : ""
      }`;
    case "completed-no_work_required":
      return `Complete and Notification Closed ${
        showCreator ? "by XXXX date/time" : ""
      }`;
  }
};
