export default {
  showActivatePopup: () => {
    return Boolean(localStorage.getItem("showActivatePopup"));
  },
  activatePopupShown: () => {
    // change flag to true when app has alert user about this
    return localStorage.setItem("showActivatePopup", "true");
  },
  showDetailsPopup: () => {
    return Boolean(localStorage.getItem("showDetailsPopup"));
  },
  detailsPopupShown: () => {
    return localStorage.setItem("showDetailsPopup", "true");
  },
  logout: () => {
    localStorage.clear();
  },
};
