import { createSlice } from "@reduxjs/toolkit";

import { PricingItem } from "pages/purchase/Pricing";
import { CouponType } from "pages/purchase/UserCart";

import { ReduxState } from "./store";

// action + pricing state types
type PricingState = {
  selectedPlan?: PricingItem;
  selectedCoupon?: CouponType;
  staffCount: number;
  nahCount: number;
  pahCount: number;
  additionalHrs: number;
  assistantCount: number;
};

export { PricingState };

const emptyState: PricingState = {
  selectedPlan: undefined,
  selectedCoupon: undefined,
  staffCount: 0,
  nahCount: 0,
  pahCount: 0,
  additionalHrs: 0,
  assistantCount: 0,
};

type PlanSelectedAction = {
  type: "PLAN_SELECTED";
  payload: {
    plan: PricingItem;
  };
};

type UpdateCountAction = {
  type: "UPDATE";
  payload: number;
};

type SelectCouponAction = {
  type: "UPDATE";
  payload: CouponType;
};

// slice with action and reducer
const CartSlice = createSlice({
  name: "cart",
  initialState: emptyState,
  reducers: {
    planSelected(state: PricingState, action: PlanSelectedAction) {
      state.selectedPlan = action.payload.plan;
    },
    selectCoupon(state: PricingState, action: SelectCouponAction) {
      state.selectedCoupon = action.payload;
    },
    updateStaffCount(state: PricingState, action: UpdateCountAction) {
      state.staffCount = action.payload;
    },
    updateNahCount(state: PricingState, action: UpdateCountAction) {
      state.nahCount = action.payload;
    },
    updatePahCount(state: PricingState, action: UpdateCountAction) {
      state.pahCount = action.payload;
    },
    updateAdditionalHrs(state: PricingState, action: UpdateCountAction) {
      state.additionalHrs = action.payload;
    },
    updateAssistantCount(state: PricingState, action: UpdateCountAction) {
      state.assistantCount = action.payload;
    },
  },
});

// selector
export const getSelectedPlan = (state: ReduxState) => {
  return state?.cart.selectedPlan;
};
export const getSelectedCoupon = (state: ReduxState) => {
  return state?.cart.selectedCoupon;
};
export const getStaffCount = (state: ReduxState) => {
  return state?.cart.staffCount;
};
export const getNahCount = (state: ReduxState) => {
  return state?.cart.nahCount;
};
export const getPahCount = (state: ReduxState) => {
  return state?.cart.pahCount;
};
export const getAdditionalHrs = (state: ReduxState) => {
  return state?.cart.additionalHrs;
};
export const getAssistantCount = (state: ReduxState) => {
  return state?.cart.assistantCount;
};

// actions
export const {
  planSelected,
  updateStaffCount,
  updateNahCount,
  updatePahCount,
  updateAdditionalHrs,
  selectCoupon,
  updateAssistantCount,
} = CartSlice.actions;

// reducer
export default CartSlice.reducer;
