import React, { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import users, { FileDto } from "../../../services/users";

import CenterLoading from "components/core/CenterLoading";
import SignModel from "components/swms/SignModel";
import SignSWMSAgreementModal from "components/swms/SignSWMSAgreementModal";
import SendSWMSModal from "components/swms/SendSWMSModal";
import swms, { SWMSRequestDetails, SWMSResponse } from "../../../services/swms";
import { openInNewTab } from "../../../utils/CommonUtils";
import { useTitle } from "../../../utils/UseTitleUtils";

interface SWMSGeneratedProps {}
declare type SWMSGeneratedParams = {
  jobId?: number;
  worksiteId?: number;
  projectId?: number;
  swmsPerson?: SWMSResponse;
};

const SWMSGenerated = (props: SWMSGeneratedProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [onSignModel, setOnSignModel] = useState(false);
  const [sendSWMS, setSendSWMS] = useState(false);
  const [onSignSWMSVisible, setOnSignSWMSVisible] = useState(false);
  const [onSignImage, setOnSignImage] = useState<string>();
  const [requestDetails, setRequestDetails] = useState<SWMSRequestDetails>();

  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  useTitle("Generate SWMS-Section 6");

  const state: SWMSGeneratedParams = location.state;

  if (!params?.id)
    if (!state?.jobId) return <Navigate to={"/swms/select-job"} replace />;

  const [fileError, setFileError] = useState<string>();

  function dataURLToBlob(dataURL: string) {
    // Code taken from https://github.com/ebidel/filer.js
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  const uploadDoc = async () => {
    setIsLoading(true);

    const file = dataURLToBlob(onSignImage!);

    const formData = new FormData();
    formData.append("fileType", "swms_signature_photo");
    formData.append("file", file, "sign.png");

    try {
      const response: FileDto = !params?.id
        ? await users.uploadFile(formData)
        : await users.uploadSWMSNoAuthFile(params?.id, formData);

      setIsLoading(false);
      return response.id;
      // createProject(response.id);
    } catch (err) {
      setFileError(err.message);
      console.error(err);
      setIsLoading(false);
      return undefined;
    }
  };

  const signSWMS = async () => {
    try {
      const fileId = await uploadDoc();

      if (!fileId) return;
      setIsLoading(true);

      const response = params?.id
        ? await swms.signNoAuthSWMSRequest({
            signingRequestId: params?.id,
            fileId,
          })
        : await swms.signSWMSRequest({
            swmsId: state?.swmsPerson?.id!,
            fileId,
          });

      await getSWMSDetails();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSWMSDetails = async () => {
    try {
      setIsLoading(true);
      const response: SWMSRequestDetails = params?.id
        ? await swms.swmsRequestDetails(params?.id)
        : await swms.swmsDetails(state?.swmsPerson?.id!);

      setRequestDetails(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (onSignImage != undefined) signSWMS();
  }, [onSignImage]);

  useEffect(() => {
    getSWMSDetails();
  }, []);

  return (
    <div className="d-flex flex-column p-3 p-md-5 content-section poppins generated-swms">
      <CenterLoading isVisible={isLoading} />
      <SendSWMSModal
        swmsId={
          params?.id
            ? Number(requestDetails?.swmsId)
            : Number(requestDetails?.id)
        }
        onClose={() => {
          setSendSWMS(false);
        }}
        isVisible={sendSWMS}
        onOkay={() => setSendSWMS(false)}
      />

      <SignSWMSAgreementModal
        isVisible={onSignSWMSVisible}
        onClose={() => setOnSignSWMSVisible(false)}
        onSign={() => {
          setOnSignSWMSVisible(false);
          setOnSignModel(true);
        }}
      />

      <SignModel
        isVisible={onSignModel}
        onClose={() => {
          setOnSignModel(false);
        }}
        onSignatureBase64={(base64: string) => {
          setOnSignImage(base64);
          setOnSignModel(false);
        }}
      />

      <h1 className="h1-text font-color-black poppins">
        Generate SWMS-Section 6
      </h1>

      <span className="d-block pb-3 body-text">
        You've generated a SWMS for the worksite. Section six will allow you to
        view, sign, send, and download your SWMS.
      </span>
      <span className="body-text">
        Please confirm all details and sign the SWMS now or later on a touch
        screen device:
      </span>

      <div
        className="d-flex flex-fill my-3 p-3"
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <div className="m-auto bg-white col-11 col-md-8">
          <div className="black-bg p-3 text-center position-relative section-header">
            <span className="font-color-white fw-bold">
              WHS Management Plan &amp; SWMS:
            </span>
          </div>

          <div className="py-4">
            <div className="row justify-content-center">
              <div className="w-auto">
                <button
                  title="view"
                  aria-label="View SWMS"
                  type="button"
                  className="btn small-btn primary-btn-black"
                  onClick={() => {
                    openInNewTab(requestDetails?.file?.inlineUrl!);
                  }}
                >
                  VIEW
                </button>
              </div>
              <div className="w-auto">
                <button
                  title="send"
                  aria-label="Send SWMS"
                  type="button"
                  disabled={params?.id ? true : false}
                  className="btn small-btn primary-btn-black"
                  onClick={() => {
                    setSendSWMS(true);
                  }}
                >
                  SEND
                </button>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="w-auto">
                <button
                  type="button"
                  title="download"
                  aria-label="Download SWMS"
                  className="btn small-btn primary-btn-black"
                  onClick={() => {
                    openInNewTab(requestDetails?.file?.url!);
                  }}
                >
                  DOWNLOAD
                </button>
              </div>
              <div className="w-auto">
                <button
                  title="sign"
                  aria-label="Sign SWMS"
                  type="button"
                  className="btn small-btn primary-btn "
                  onClick={() => {
                    setOnSignSWMSVisible(true);
                  }}
                >
                  SIGN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!params?.id && (
        <div className={`d-flex justify-content-end row g-0`}>
          <div className="col-12 col-sm-3 mt-2 mt-sm-0">
            <button
              type="button"
              title="finish"
              aria-label="Finish"
              disabled={requestDetails?.file == undefined}
              className="align-items-center btn primary-btn col-12 col-sm-auto nav-btn w-100"
              onClick={() => {
                navigate("/");
              }}
            >
              Finish
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SWMSGenerated;
