import React, { createRef, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import { useDispatch, useSelector } from "react-redux";
import { changeisPlayStatus, getPlayStatus } from "../../redux/audioplayer";
import { ReduxState } from "reduxStore/store";

interface AudioPlayerProps {}

const AudioPlayer = (props: AudioPlayerProps) => {
  const srcAudio = useSelector(
    (state: ReduxState) => state.audioPlayer.audioSrc
  );
  const ref: React.RefObject<ReactAudioPlayer> = createRef();
  const dispatch = useDispatch();
  const isPlay = useSelector(getPlayStatus);
  useEffect(() => {
    window.audioRef = ref.current?.audioEl;
  }, [ref, srcAudio]);

  return (
    <div>
      <ReactAudioPlayer
        id="audio-player"
        src={srcAudio}
        autoPlay
        ref={ref}
        onEnded={(event) => {
          dispatch(changeisPlayStatus(false));
        }}
        onPause={(event) => {
          dispatch(changeisPlayStatus(false));
        }}
        onPlay={(event) => {
          dispatch(changeisPlayStatus(true));
        }}
        listenInterval={1000}
      />
    </div>
  );
};

export default AudioPlayer;
