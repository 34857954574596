import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";

import common, { Country, Province } from "../../services/common";

import Autocomplete from "../core/Autocomplete";

type AddressData = {
  id?: number;
  place: google.maps.places.PlaceResult;
  geo?: {
    lat?: number;
    long?: number;
  };
  placeId: string;
  exactPlaceMatch?: boolean;
  usePlaceLatLongOnly?: boolean;
  formattedAddress?: string;
  streetNumber: string;
  streetName?: string;
  subpremise: string;
  suburb: string;
  state: string;
  country: string;
  postCode: string;
  postalCode?: string;
  unitNumber?: string;
};

type PrimaryTypes =
  | "subpremise"
  | "street_number"
  | "route"
  | "locality"
  | "sublocality_level_1"
  | "administrative_area_level_1"
  | "country"
  | "postal_code";

type AddressProps = {
  inputLabel?: string;
  className?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  asterisk?: boolean;
  onAddressChange?: (address: AddressData) => void;
  defaultAddress?: AddressData;
};

const Address = ({
  inputLabel,
  className,
  onAddressChange,
  error,
  defaultAddress,
  disabled,
  required = true,
  asterisk,
  ...props
}: AddressProps) => {
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");

  const [displayAddress, setDisplayAddress] = useState("");

  // input fields
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [subPremise, setSubPremise] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [suburb, setSuburb] = useState("");
  const [postCode, setPostCode] = useState("");

  const [currIndex, setCurrIndex] = useState(-1);
  const [countries, setCountries] = useState<Country[]>([]);
  const [province, setProvince] = useState<Province[]>([]);

  // Metadata fields
  /**
   * @var {google.maps.places.PlaceResult} place
   */
  const [place, setPlace] = useState<google.maps.places.PlaceResult>({});

  // set JQuery on click callback
  useEffect(() => {
    getCountryList();
    // to enable expansion of field
    setTimeout(() => {
      window.loadAutoCompleteEvents(".custom-address");
    }, 300);
  }, []);

  useEffect(() => {
    // this will be called once
    if (
      defaultAddress &&
      (defaultAddress?.formattedAddress?.trim() ||
        (defaultAddress?.placeId && defaultAddress.placeId !== place?.place_id))
    ) {
      setCountry(defaultAddress.country ?? "");
      setState(defaultAddress.state ?? "");
      setSubPremise(defaultAddress.unitNumber ?? "");
      setStreetAddress(
        `${defaultAddress.streetNumber ?? ""} ${
          defaultAddress.streetName ?? ""
        }`.trim()
      );
      // setStreetAddress(`${defaultAddress.streetName ?? ""}`);
      setStreetName(defaultAddress.streetName ?? "");
      setStreetNumber(defaultAddress.streetNumber ?? "");
      setSuburb(defaultAddress.suburb ?? "");
      setPostCode(defaultAddress.postalCode ?? "");
      setAddressLine("");

      setValue(defaultAddress.formattedAddress ?? "", false);
    } else {
      if (defaultAddress === undefined) {
        setValue("");
        setState("");
        setCountry("");
        setStreetAddress("");
        setSubPremise("");
        setAddressLine("");
        setSuburb("");
        setPostCode("");
      }
    }
  }, [defaultAddress]);

  // as soon as country changes, get province list.
  useEffect(() => {
    getStateList();
  }, [country]);

  // Get list of countries
  const getCountryList = async () => {
    try {
      const response = await common.countries();
      setCountries(response);
    } catch (err) {}
  };

  // Get state list from selected country
  const getStateList = async () => {
    try {
      const response = await common.state({
        country: country,
      });

      setProvince(response);
    } catch (err) {}
  };

  // Google place auto complete
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: {
        country: ["au", "nz"],
      },
    },
    debounce: 300,
  });

  // send updates to the parent component with address
  useEffect(() => {
    sendAddressUpdate();
  }, [
    place,
    streetNumber,
    streetAddress,
    streetName,
    subPremise,
    suburb,
    state,
    country,
    postCode,
    value,
  ]);

  /**
    @param {google.maps.places.PlaceResult} place
    @returns {void}
    */
  const translateGooglePlace = (place: google.maps.places.PlaceResult) => {
    // Get the place object passed to us by Google, and convert it to our traditional 'Address' text fields.
    let componentsFirstAddress = ["street_number", "street_address", "route"];
    let arrFirstAddress = [];

    // We need to flush old address information.
    let countryDetail = "";
    let stateDetail = "";
    let subPremiseDetail = "";
    let streetNumberDetail = "";
    let streetDetail = "";
    let streetAddressDetail = "";
    let addressLineDetail = "";
    let suburbDetail = "";
    let postCodeDetail = "";

    for (const component of place.address_components) {
      // Loop through the components, get the primary type.
      let primaryType = component.types[0];

      if (componentsFirstAddress.includes(primaryType)) {
        arrFirstAddress.push(component.long_name);
      }

      switch (primaryType) {
        case "subpremise":
          subPremiseDetail = component.long_name;
          break;
        case "street_number":
          streetNumberDetail = component.long_name;
          break;

        // this type is for the "Line 1" input
        case "route":
          streetDetail = component.long_name;
          streetAddressDetail =
            `${streetNumberDetail} ${component.long_name}`.trim();
          break;

        case "locality":
          suburbDetail = component.long_name;
          break;

        case "sublocality_level_1":
          addressLineDetail = component.long_name;
          break;

        case "administrative_area_level_1":
          stateDetail = component.long_name;
          break;

        case "country":
          countryDetail = component.long_name;
          break;

        case "postal_code":
          postCodeDetail = component.long_name;
          break;

        default:
          break;
      }
    }

    setCountry(countryDetail);
    setState(stateDetail);
    setSubPremise(subPremiseDetail);
    setStreetAddress(streetAddressDetail);
    setAddressLine(addressLineDetail);
    setSuburb(suburbDetail);
    setPostCode(postCodeDetail);

    setStreetNumber(streetNumberDetail);
    setStreetName(streetDetail);

    // Set first address line
    const firstAddr = arrFirstAddress.join(" ");

    if (firstAddr !== displayAddress) {
      // setAddressStreet(firstAddr)
      setDisplayAddress(firstAddr);
    }
  };

  const checkIfSame = (
    value: string,
    key: PrimaryTypes,
    name_type: "long_name" | "short_name" = "long_name"
  ) => {
    const data = (place?.address_components ?? []).find(
      (e) => e.types[0] == key
    );
    return data ? data[name_type] == value.trim() : value.trim() == "";
  };

  const checkIfAllSame = () => {
    return (
      checkIfSame(subPremise, "subpremise", "long_name") &&
      checkIfSame(streetNumber, "street_number", "long_name") &&
      checkIfSame(streetName, "route", "long_name") &&
      checkIfSame(suburb, "locality", "long_name") &&
      checkIfSame(addressLine, "sublocality_level_1", "long_name") &&
      checkIfSame(state, "administrative_area_level_1", "long_name") &&
      checkIfSame(country, "country", "long_name") &&
      checkIfSame(postCode, "postal_code", "long_name")
    );
  };

  const handleSelect = ({ place_id, description }) => {
    setCurrIndex(-1);
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get place details
    getDetails({
      placeId: place_id,
      fields: [
        "address_component",
        "adr_address",
        "business_status",
        "formatted_address",
        "geometry",
        "icon",
        "icon_mask_base_uri",
        "icon_background_color",
        "name",
        "place_id",
        "plus_code",
        "type",
        "url",
        "vicinity",
      ],
    })
      .then((result: google.maps.places.PlaceResult) => {
        // clear existing state.
        setDisplayAddress(result.formatted_address);
        translateGooglePlace(result);
        setPlace(result);
        // setOutput()
      })
      .catch((error) => {
        console.error("😱 Error: ", error);
      });
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setDisplayAddress(e.target.value);
  };

  const sendAddressUpdate = () => {
    if (onAddressChange) {
      const temp = streetAddress.split(" ");

      let pattern = /[0-9]/g;
      onAddressChange({
        place,
        placeId: place.place_id,
        streetNumber: pattern.test(temp[0] ?? "") ? temp[0] : "",
        streetName: pattern.test(temp[0] ?? "")
          ? streetAddress.trim().replace(temp[0], "").trim()
          : streetAddress.trim(),
        subpremise: subPremise.trim(),
        suburb,
        state,
        country,
        geo: place?.geometry?.location?.toJSON() && {
          lat: place?.geometry?.location?.toJSON().lat,
          long: place?.geometry?.location?.toJSON().lng,
        },
        postCode,
        exactPlaceMatch: checkIfAllSame(),
        usePlaceLatLongOnly: place.place_id
          ? !checkIfSame(subPremise, "subpremise", "long_name")
          : false,
      });
    }
  };
  return (
    <div className="custom-address custom-autocomplete position-relative">
      <Autocomplete
        asterisk={asterisk}
        title={inputLabel ?? "Address:"}
        controlId="address"
        data={data}
        value={value}
        error={error}
        expandable
        disabled={disabled}
        onChange={handleInput}
        onSuggestionClick={handleSelect}
        clearSuggestions={() => {
          clearSuggestions();
        }}
        renderSuggestionItem={(
          item: google.maps.places.AutocompletePrediction,
          index: number
        ) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = item;
          return (
            <>
              <strong>{main_text}</strong> <small>{secondary_text}</small>
            </>
          );
        }}
      />

      <div id="expanded-autocomplete" className="col-12">
        <div className="row">
          <Form.Group className="col-6 mb-3" controlId="country">
            <Form.Select
              value={country}
              // required
              disabled={disabled}
              autoComplete="country-name"
              onChange={(ev) => {
                setProvince([]);
                setCountry(ev.target.value);
                setState("");
              }}
            >
              <option key={`address-country-placeholder`} value={""}>
                Country
              </option>
              {countries.map((e: Country, index: number) => {
                return (
                  <option key={`address-country-${index}`} value={e.name}>
                    {e.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="col-6 mb-3" controlId="state">
            <Form.Select
              value={state}
              disabled={disabled}
              autoComplete="address-level1"
              onChange={(ev) => {
                setState(ev.target.value);
              }}
            >
              <option key={`address-state-placeholder`} value={""}>
                State
              </option>
              {province.map((e: Province, index: number) => {
                return (
                  <option key={`address-state-${index}`} value={e.name}>
                    {e.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </div>

        <Form.Group className="col-12 mb-3" controlId="subPremise">
          <Form.Control
            type="text"
            disabled={disabled}
            placeholder="Subpremise"
            value={subPremise}
            onChange={(e) => {
              setSubPremise(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="col-12 mb-3" controlId="streetAddress">
          <Form.Control
            type="text"
            disabled={disabled}
            // required={required}
            autoComplete="street-address"
            placeholder="Street Address"
            value={streetAddress}
            onChange={(e) => {
              setStreetAddress(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="col-12 mb-3" controlId="line2">
          <Form.Control
            type="text"
            disabled={disabled}
            autoComplete="address-line2"
            placeholder="Address Line"
            value={addressLine}
            onChange={(e) => {
              setAddressLine(e.target.value);
            }}
          />
        </Form.Group>
        <div className="row">
          <Form.Group className="col-6 mb-3" controlId="suburb">
            <Form.Control
              type="text"
              disabled={disabled}
              // required={required}
              autoComplete="address-level2"
              placeholder="Suburb"
              value={suburb}
              onChange={(e) => {
                setSuburb(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="col-6 mb-3" controlId="postcode">
            <Form.Control
              type="text"
              // required={required}
              autoComplete="postal-code"
              placeholder="Postcode"
              value={postCode}
              disabled={disabled}
              onChange={(e) => {
                setPostCode(e.target.value);
              }}
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default Address;

export { AddressData };
