import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import {
  AnswerResponseEnum,
  CreateAuditTemplateDto,
  CreateAuditTemplateSectionDto,
  CreateAuditTemplateSectionElementDto,
  CreateAuditTemplateSectionElementOptionDto,
  auditSectionDto,
} from "services/audit";
import { ReduxState } from "./store";

const emptyState: CreateAuditTemplateDto = {
  name: "",
  description: "",
  error: {
    name: "",
    description: "",
  },
  sections: [],
};

const newAuditSlice = createSlice({
  name: "audit",
  initialState: emptyState,
  reducers: {
    replaceAudit: (state, action: PayloadAction<CreateAuditTemplateDto>) => {
      state.derivedFromAuditTemplateId =
        action.payload.derivedFromAuditTemplateId;
      state.description = action.payload.description;
      state.error = action.payload.error;
      state.name = action.payload.name;
      state.sections = action.payload.sections;
    },
    updateAuditTemplateDetails: (
      state,
      action: PayloadAction<{ name: string; description: string }>
    ) => {
      if (action.payload.name.trim()) {
        state.error = { ...state.error, name: "" };
      }
      if (action.payload.description.trim()) {
        state.error = { ...state.error, description: "" };
      }
      state.name = action.payload.name;
      state.description = action.payload.description;
    },
    addAuditSection: (
      state,
      action: PayloadAction<CreateAuditTemplateSectionDto>
    ) => {
      state.sections.push(action.payload);
    },
    updateAuditSectionDetails: (
      state,
      action: PayloadAction<CreateAuditTemplateSectionDto>
    ) => {
      const currentSection = state.sections.find(
        (e) => e.id === action.payload.id
      );
      if (currentSection) {
        state.sections = state.sections.map((e) =>
          e.id === action.payload.id ? { ...e, ...action.payload } : e
        );
      }
    },
    deleteAuditSection: (state, action: PayloadAction<string>) => {
      state.sections = state.sections.filter((e) => e.id !== action.payload);
    },
    updateAuditSectionOrder: (
      state,
      action: PayloadAction<{ startId: string; replaceId: string }>
    ) => {
      const startIndex = state.sections.findIndex(
        (e) => e.id === action.payload.startId
      );
      const endIndex = state.sections.findIndex(
        (e) => e.id === action.payload.replaceId
      );
      const currentList = [...state.sections];
      const [removed] = currentList.splice(startIndex, 1);
      currentList.splice(endIndex, 0, removed);
      state.sections = currentList.map((e, id) => ({ ...e, order: id + 1 }));
    },
    addAuditQuestion: (
      state,
      action: PayloadAction<{
        sectionId: string;
        question: CreateAuditTemplateSectionElementDto;
      }>
    ) => {
      const currentSection = state.sections.find(
        (e) => e.id === action.payload.sectionId
      );

      if (currentSection) {
        currentSection.elements.push(action.payload.question);
      }

      state.sections = state.sections.map((e) =>
        e.id === action.payload.sectionId ? { ...currentSection } : { ...e }
      );
    },
    updateQuestionComponentDetails: (
      state,
      action: PayloadAction<{
        sectionId: string;
        questionComponent: CreateAuditTemplateSectionElementDto;
      }>
    ) => {
      const currentSection = state.sections.find(
        (e) => e.id === action.payload.sectionId
      );

      if (currentSection) {
        const updateQuestionComponentList = [...currentSection.elements].map(
          (e) => {
            let error = { ...e.error };
            if (action.payload.questionComponent.prompt.trim()) {
              error = { ...error, prompt: "" };
            }

            if (
              action.payload.questionComponent.type ===
                AnswerResponseEnum.SIGNATURE &&
              action.payload.questionComponent.constraints
                .signatureRequireTerms &&
              action.payload.questionComponent.secondaryText.trim()
            ) {
              error = { ...error, secondaryText: "" };
            }
            return e.id === action.payload.questionComponent.id
              ? { ...action.payload.questionComponent, error: error }
              : { ...e };
          }
        );

        state.sections = state.sections.map((e) =>
          e.id === action.payload.sectionId
            ? { ...e, elements: updateQuestionComponentList }
            : { ...e }
        );
      }
    },
    replaceQuestionComponent: (
      state,
      action: PayloadAction<{
        sectionId: string;
        questionList: CreateAuditTemplateSectionElementDto[];
      }>
    ) => {
      const currentSection = state.sections.find(
        (e) => e.id === action.payload.sectionId
      );
      if (currentSection) {
        state.sections = state.sections.map((e) =>
          e.id === action.payload.sectionId
            ? { ...currentSection, elements: action.payload.questionList }
            : { ...e }
        );
      }
    },
    deleteQuestionComponent: (
      state,
      action: PayloadAction<{ sectionId: string; questionId: string }>
    ) => {
      const currentSection = state.sections.find(
        (e) => e.id === action.payload.sectionId
      );

      if (currentSection) {
        const updatedQuestionList = [...currentSection.elements].filter(
          (e) => e.id !== action.payload.questionId
        );
        state.sections = state.sections.map((e) =>
          e.id === action.payload.sectionId
            ? { ...currentSection, elements: updatedQuestionList }
            : { ...e }
        );
      }
    },

    // options reducers
    addQuestionOption: (
      state,
      action: PayloadAction<{
        sectionId: string;
        elementId: string;
        option: CreateAuditTemplateSectionElementOptionDto;
      }>
    ) => {
      const currentSection = state.sections.find(
        (e) => e.id === action.payload.sectionId
      );
      if (currentSection) {
        const currentElement = {
          ...currentSection.elements.find(
            (e) => e.id === action.payload.elementId
          ),
        };
        if (currentElement) {
          currentElement.options.push(action.payload.option);
        }
        const updateCurrentElementList = currentSection.elements.map((e) =>
          e.id === action.payload.elementId ? { ...currentElement } : { ...e }
        );
        const updatedCurrentSection = {
          ...currentSection,
          elements: updateCurrentElementList,
        };
        state.sections.map((e) =>
          e.id === action.payload.sectionId
            ? { ...updatedCurrentSection }
            : { ...e }
        );
      }
    },

    deleteQuestionOption: (
      state,
      action: PayloadAction<{
        sectionId: string;
        elementId: string;
        optionId: string;
      }>
    ) => {
      const currentSection = state.sections.find(
        (e) => e.id === action.payload.sectionId
      );
      if (currentSection) {
        const currentElement = currentSection.elements.find(
          (e) => e.id === action.payload.elementId
        );

        if (currentElement) {
          const updatedOptionList = [...currentElement.options].filter(
            (e) => e.id !== action.payload.optionId
          );
          const updatedElementList = [...currentSection.elements].map((e) =>
            e.id === action.payload.elementId
              ? { ...e, options: updatedOptionList }
              : { ...e }
          );
          state.sections = state.sections.map((e) =>
            e.id === action.payload.sectionId
              ? { ...e, elements: updatedElementList }
              : { ...e }
          );
        }
      }
    },

    updateOptionDetails: (
      state,
      action: PayloadAction<{
        sectionId: string;
        elementId: string;
        option: CreateAuditTemplateSectionElementOptionDto;
      }>
    ) => {
      const currentSection = state.sections.find(
        (e) => e.id === action.payload.sectionId
      );
      if (currentSection) {
        const currentElement = currentSection.elements.find(
          (e) => e.id === action.payload.elementId
        );
        if (currentElement) {
          const updatedOptionList = [...currentElement.options].map((e) => {
            let error = { ...e.error };

            if (e.value.trim()) {
              error = { value: "" };
            }

            return e.id === action.payload.option.id
              ? { ...e, ...action.payload.option, error: error }
              : { ...e };
          });
          const updatedElementList = [...currentSection.elements].map((e) =>
            e.id === action.payload.elementId
              ? { ...e, options: updatedOptionList }
              : { ...e }
          );
          state.sections = state.sections.map((e) =>
            e.id === action.payload.sectionId
              ? { ...e, elements: updatedElementList }
              : { ...e }
          );
        }
      }
    },

    replaceOptionList: (
      state,
      action: PayloadAction<{
        sectionId: string;
        elementId: string;
        optionList: CreateAuditTemplateSectionElementOptionDto[];
      }>
    ) => {
      const currentSection = state.sections.find(
        (e) => e.id === action.payload.sectionId
      );
      if (currentSection) {
        const updatedElementList = [...currentSection.elements].map((e) =>
          e.id === action.payload.elementId
            ? { ...e, options: action.payload.optionList }
            : { ...e }
        );
        state.sections = state.sections.map((e) =>
          e.id === action.payload.sectionId
            ? { ...e, elements: updatedElementList }
            : { ...e }
        );
      }
    },
  },
});

export const getAuditDetails = (state: ReduxState) => state.audit;
export const getSectionList = (state: ReduxState) => state.audit.sections;

export const {
  updateAuditTemplateDetails,
  addAuditSection,
  updateAuditSectionDetails,
  deleteAuditSection,
  updateAuditSectionOrder,
  addAuditQuestion,
  replaceQuestionComponent,
  deleteQuestionComponent,
  updateQuestionComponentDetails,
  addQuestionOption,
  deleteQuestionOption,
  updateOptionDetails,
  replaceAudit,
  replaceOptionList,
} = newAuditSlice.actions;

export default newAuditSlice.reducer;
