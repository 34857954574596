import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import auth from "services/auth";
import users, { CreateDocumentAuditDto } from "services/users";
import { ReduxState } from "./store";
type WorkSiteDocState = {};

type LoginAction = {
  type: string;
  payload: {
    auth: WorkSiteDocState;
    save: boolean;
  };
};

// export state types
export { WorkSiteDocState };

const emptyState: WorkSiteDocState = {
  user: {},
  token: "",
  refreshToken: "",
};

const auditDocumentActionThunk = createAsyncThunk(
  "users/refreshApi401",
  async (data: any, thunkAPI) => {
    const { documentId, shareId } = data;
    try {
      const request: CreateDocumentAuditDto = {
        jobDocumentId: Number(documentId),
        action: "SIGNATURE_DECLINE",
      };
      const response = shareId
        ? await users.auditShareDocument(shareId, request)
        : await users.auditDocument(documentId, request);

      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

// actual redux reducer + actions with initial state
const WorkSiteDocSlice = createSlice({
  name: "auth",
  initialState: { ...emptyState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      auditDocumentActionThunk.fulfilled,
      (state, { payload }) => {}
    );
    // handle rejection in case you want to
    builder.addCase(auditDocumentActionThunk.rejected, (state, action) => {});
  },
});

// reducer
export default WorkSiteDocSlice.reducer;

export { auditDocumentActionThunk };
