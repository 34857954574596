import React from "react";
import { Svgs } from "utils/IconUtils";

function ResponseSuccess() {
  return (
    <div className="p-3 p-md-5 content-section">
      <div className="d-flex align-content-center align-items-center flex-column justify-content-center gap-4 mt-3 mt-md-4">
        <Svgs.WorkAccepted style={{ height: 140, width: 140 }} />
        <h1 className="h1-text text-center text-dark">Submission Successful</h1>
        <p className="text-center lh-lg">
          Your form has been submitted, this page can now be closed
        </p>
      </div>
    </div>
  );
}

export default ResponseSuccess;
