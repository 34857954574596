import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { useTitle } from "../../utils/UseTitleUtils";

interface EmailVerificationErrorProps {}

const ERRORS = {
  EMPTY: "Activation code cannot be empty",
  INVALID: "Activation code invalid",
  NOT_FOUND: "Activation code cannot be found",
};

const EmailVerificationError = (props: EmailVerificationErrorProps) => {
  const [params] = useSearchParams();
  useTitle("Activation failed");
  const error = params.get("error");

  return (
    <div className="content-section d-flex poppins">
      <div className="col-12 col-lg-7 m-auto text-center">
        <div className="align-items-center d-flex flex-column vertical-center">
          <img
            src="/assets/svgs/user_cancel.svg"
            className="mb-4"
            alt="activation failed"
            style={{ height: 100, width: 100 }}
          />
          <h1 className="h1-text poppins font-color-red">Activation failed!</h1>
          <div className="col-12 col-lg-11 col-md-10 pt-4 px-4">
            <span className="body-text d-block mb-3 lh-2">
              You email has not successfully been confirmed.
              <br />
              Please contact support or try again later sometime.
            </span>
            <span className="body-text d-block mb-4 lh-2 fw-bold font-color-red">
              {error && ERRORS[error]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationError;
