import React, { StyleHTMLAttributes } from "react";

import CloseIcon from "./icons/CloseIcon";
import ErrorIcon from "./icons/ErrorIcon";
import InfoIcon from "./icons/InfoIcon";
import SuccessIcon from "./icons/SuccessIcon";

const alertStyle = {
  // backgroundColor: "#0c85f2",
  backgroundColor: "#e6e6e6",
  color: "white",
  padding: "10px",
  // textTransform: "uppercase",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 2px 2px 2px rgba(0, 0, 0, 0.03)",
  fontFamily: "Arial",
  width: "500px",
  boxSizing: "border-box",
};

const errorContainer: StyleHTMLAttributes<"div">["style"] = {
  backgroundColor: "red",
};

const buttonStyle = {
  marginLeft: "20px",
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  // color: "#FFFFFF",
  color: "#000",
};

const AlertTemplate = ({ message, options, style, close }) => {
  const styleMe = () => {
    switch (options.type) {
      case "error":
        return errorContainer;
      default:
        return {};
    }
  };
  return (
    <div style={{ ...alertStyle, ...style, ...styleMe() }}>
      {options.type === "info" && <InfoIcon />}
      {options.type === "success" && <SuccessIcon />}
      {options.type === "error" && <ErrorIcon />}
      <span
        className="poppins fw-600"
        style={{ color: options.type === "error" ? "white" : "#000" }}
      >
        {message}
      </span>
      <button
        aria-label="Close"
        title="close"
        onClick={close}
        style={buttonStyle}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export default AlertTemplate;
