import { doFetch } from './fetcher';

type Country = {
  code: string;
  name: string;
};

type Province = Country

export default {
  countries: () => doFetch("/api/v3/address/countries", "GET"),
  state: (data: { country: string }) =>
    doFetch("/api/v3/address/provinces", "POST", data),
};

export { Country, Province };
