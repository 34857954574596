import { UserProfile } from "../redux/auth/index";

import { doFetch } from "./fetcher";
import { pageMetaData } from "./notification";
import {
  Contact,
  FileDto,
  SwmsDto,
  SwmsRecipientDto,
  WorkActivityDto,
} from "./users";

export declare type SWMSRequest = {
  isWhsManagementPlan?: boolean;
  jobId?: number;
  workActivities?: number[];
  scopes?: number[];
  sds_msds?: string[];
  recipients?: SwmsRecipientDto[];
  step: string;
};

export declare type PersonDTO = {
  contact?: Contact;
  user?: UserProfile;
  role?: string;
};

export declare type ActivityDTO = {
  companyId: number;
  id: number;
  user_id: number;
  description: string;
  group: string;
  kind: string;
  is_selected: boolean;
};
export declare type SWMSResponse = {
  workActivities: WorkActivityDto;
  isWhsManagementPlan?: boolean;
  id?: number;
  persons?: PersonDTO[];
  status?: string;
  step?: string;
};

export declare type SWMSRequestDetails = {
  id: string;
  file: FileDto;
  user: UserProfile;
  contact: Contact;
  worksiteId: number;
  swmsId: number;
  worksiteName: string;
  projectId: number;
  projectName: string;
  jobId: number;
  jobName: string;
  isSigned: boolean;
};

export declare type SignNoAuthSWMSRequest = {
  signingRequestId: string;
  fileId: number;
};

export declare type SignSWMSRequest = {
  swmsId: number;
  fileId: number;
};

export const arrWHSControlCategory = [
  "Elimination",
  "Substitution",
  "Isolation",
  "Engineering",
  "Administrative",
  "PPE",
  "Other",
] as const;
export type WHSControlCategory = (typeof arrWHSControlCategory)[number];

export const arrOperationType = ["add", "update", "remove"] as const;
export type WHSOperationType = (typeof arrOperationType)[number];

export class CreateWHSControlDto {
  id?: number;
  localId?: string; // only for local
  op?: WHSOperationType;
  category: WHSControlCategory = "Other";
  control: string;
  riskRatingBeforeControlImpact?: number;
  riskRatingBeforeControlOccurrence?: number;
  riskRatingAfterControlImpact?: number;
  riskRatingAfterControlOccurrence?: number;
}

export class UpdateWHSControlDto {
  id: number;
  op: WHSOperationType;
  category?: WHSControlCategory = "Other";
  control?: string;
  riskRatingBeforeControlImpact?: number;
  riskRatingBeforeControlOccurrence?: number;
  riskRatingAfterControlImpact?: number;
  riskRatingAfterControlOccurrence?: number;
}

export interface CreateWHSPotentialHazardDto {
  id?: number;
  localId?: string; // only for local
  op?: WHSOperationType;
  removedControls?: CreateWHSControlDto[];
  /**
   * Only required when adding a new potential hazard - but can be updated
   */
  potentialHazard: string;

  /**
   * Only required when adding a new potential hazard - but can be updated
   */
  responsiblePerson: string;

  /**
   * Only required when adding a new potential hazard - but can be updated
   *
   * Need to check how ValidateIf works for the update state.
   */
  controls: CreateWHSControlDto[];
  legacyRiskScoreBeforeControl?: string;
  legacyRiskScoreAfterControl?: string;
}

export interface UpdateWHSPotentialHazardDto {
  id: number;
  op: WHSOperationType;
  potentialHazard?: string;
  responsiblePerson?: string;
  controls: UpdateWHSControlDto[];
  legacyRiskScoreBeforeControl?: string;
  legacyRiskScoreAfterControl?: string;
}

export interface CreateWHSWorkActivityDto {
  /**
   * Only required when adding a new work activity - but can be updated
   */
  name: string;

  /**
   * Only required when adding a new work activity - but can be updated
   */
  workActivityGroup: string;

  /**
   * Only required when adding a new work activity - but can be updated
   */
  potentialHazards: CreateWHSPotentialHazardDto[];

  /**
   * Only required when adding a new work activity - but can be updated
   * High risk activity ids
   */
  highRisks: number[];
}

export class UpdateWHSWorkActivityDto {
  workActivityGroup?: string;
  potentialHazards?: UpdateWHSPotentialHazardDto[];
  name?: string;
  highRisks?: number[];
}

export interface WHSHighRisks {
  id: number;
  description: string;
}

export interface WHSControl {
  id: number;
  category: WHSControlCategory;
  control: string;
  createdOn: Date;
  riskRatingBeforeControlImpact: number;
  riskRatingBeforeControlOccurrence: number;
  riskRatingAfterControlImpact: number;
  riskRatingAfterControlOccurrence: number;
  approved: boolean;
  author: string;
}

export interface WHSWorkActivityGroup {
  id: number;
  name: string;
}

export class WHSPotentialHazard {
  id: number;
  potentialHazard: string;
  legacyRiskScoreBeforeControl?: string;
  legacyRiskScoreAfterControl?: string;
  responsiblePerson: string;
  createdOn: Date;
  approved: boolean;
  controls: WHSControl[];
}

export interface WHSWorkActivity {
  id: number;
  name: string;
  workActivityGroup: WHSWorkActivityGroup;
  createdOn: Date;
  highRisks: WHSHighRisks[];
  legacyWorkActivityId: number | null;
  /**
   * Temporary - while the QA process is underway
   */
  userPrompt: string;
  companyId: number;
  potentialHazards: WHSPotentialHazard[];
}

export interface WorkActivityQuery {
  order?: "asc" | "desc";
  page?: number;
  take?: number;
  all?: boolean;
  companyId?: number;
  workActivityGroupId?: number;
  approved?: boolean;
}

export default {
  getAllWorksites: () => doFetch(`/api/v3/worksite`, "GET"),
  createSWMS: (data: SWMSRequest) => doFetch("/api/v3/swms", "POST", data),
  updateSWMS: (id: number, data: SWMSRequest): Promise<SwmsDto> =>
    doFetch(`/api/v3/swms/${id}`, "PUT", data),
  swmsRequestDetails: (id: string) =>
    doFetch(`/api/v3/swms/request/${id}`, "GET"),
  swmsDetails: (id: number) => doFetch(`/api/v3/swms/${id}`, "GET"),
  signNoAuthSWMSRequest: (data: SignNoAuthSWMSRequest) =>
    doFetch(`/api/v3/swms/sign/request`, "POST", data),
  signSWMSRequest: (data: SignSWMSRequest) =>
    doFetch(`/api/v3/swms/sign/user`, "POST", data),
  getSWMSJobAllById: (jobId: number) =>
    doFetch(`/api/v3/swms/job/${jobId}/all`, "GET"),
  workActivities: (type: string) =>
    doFetch(`/api/v3/workactivities?type=${type}`),
  getWorkActivityById: (type: string, id: number) =>
    doFetch(`/api/v3/workactivities/${id}?type=${type}`, "GET"),

  highRisks: () => doFetch(`/api/v3/workactivities/highRisks`),

  // legacy methods
  getJobs: () => doFetch("/api/jobs/staff"),
  create: (data) => doFetch("/api/swms", "POST", data),
  update: (id, data) => doFetch(`/api/swms/${id}`, "PUT", data),
  delete: (id) => doFetch(`/api/swms/${id}`, "DELETE"),

  getCompany: (id) => doFetch(`/api/swms/${id}/company_details`),
  getClient: (id) => doFetch(`/api/swms/${id}/client_details`),
  getSite: (id) => doFetch(`/api/swms/${id}/site_details`),
  getProject: (id) => doFetch(`/api/swms/${id}/project_details`),
  createSubContractors: (id, data) =>
    doFetch(`/api/swms/${id}/subcontractors`, "POST", data),
  deleteSubContractors: (id, section_param) =>
    doFetch(`/api/swms/${id}/subcontractors/${section_param}`, "DELETE"),

  createWhsPerson: (id, data) =>
    doFetch(`/api/whs/${id}/whs_person`, "POST", data),
  deleteWhsPerson: (id, section_param) =>
    doFetch(`/api/whs/${id}/whs_person/${section_param}`, "DELETE"),

  getEmployees: (id) => doFetch(`/api/swms/${id}/employees`),

  getHazardsAndDescr: (id) => doFetch(`/api/swms/${id}/worksite_hazards`),
  getRisks: (id) => doFetch(`/api/swms/${id}/high_risks`),
  getWorkActivities: (id, type = "both") =>
    doFetch(`/api/swms/${id}/work_activities/${type}`),

  createWorkActivity: (data) => doFetch(`/api/v3/workactivities`, "POST", data),
  updateWorkActivity: (id, data) =>
    doFetch(`/api/v3/workactivities/${id}`, "PUT", data),
  deleteWorkActivity: (id) => doFetch(`/api/v3/workactivities/${id}`, "DELETE"),
  createHazard: (wa_id, data) =>
    doFetch(`/api/v3/potentialhazards/${wa_id}`, "POST", data),
  updateHazard: (id, data) =>
    doFetch(`/api/v3/potentialhazards/${id}`, "PUT", data),
  deleteHazard: (id) => doFetch(`/api/v3/potentialhazards/${id}`, "DELETE"),
  makePublic: (id) =>
    doFetch(`/api/v3/workactivities/set-public/${id}`, "POST"),

  // s4
  getSdsLinks: (id) => doFetch(`/api/swms/${id}/sds_msds`),
  addSdsLink: (id, sds_msds) =>
    doFetch(`/api/swms/${id}/sds_msds`, "POST", { sds_msds }),
  deleteSdsLink: (id, sds_msds_id) =>
    doFetch(`/api/swms/${id}/sds_msds/${sds_msds_id}`, "DELETE"),

  // s5
  getConfirmDetails: (id) => doFetch(`/api/swms/${id}/confirm_details`),

  // s6
  getViewSwms: (id) => doFetch(`/api/swms/${id}/view_swms`),
  getPreviewSwmsHtml: (id) => doFetch(`/api/swms/${id}/html_swms`),
  getSwmsPdf: (id) => doFetch(`/api/swms/${id}/pdf_swms`),
  getWhsPdf: (id) => doFetch(`/api/swms/${id}/pdf_whs`),
  sign: (id, signature_file_id) =>
    doFetch(`/api/swms/${id}/signature`, "POST", { signature_file_id }),
  sendSwms: (data) => doFetch(`/api/v3/swms/send`, "POST", data),
  sendWhs: (id, emails) =>
    doFetch(`/api/swms/${id}/send_pdf_whs`, "POST", { emails }),

  //Trades
  // getTrades: () => doFetch(`/api/trades`),
  getTrades: () => doFetch(`/api/v3/trades/all`),
  // getTradesOfSwms: swms_id => doFetch(`/api/trades/${swms_id}`),
  getTradesOfSwms: (swms_id) =>
    doFetch(`/api/v3/trades/swms/trades/${swms_id}`),
  getTradesClasses: () => doFetch(`/api/trades/classes`),
  getTradesScopes: (trade_id, class_id) =>
    doFetch(`/api/trades/${trade_id}/scopes/${class_id}`),
  getAllTradesScopes: () => doFetch(`/api/trades/scopes`),
  getScopesForSwms: (id) => doFetch(`/api/v3/trades/swms/scopes/${id}`),
  // getScopesForSwms: (id) => doFetch(`/api/v2/trades/swms_scopes/${id}`),
  // updateScopes: (id, data) => doFetch(`/api/swms/${id}/swms_scopes`, 'PUT', data),
  updateScopes: (id, data) =>
    doFetch(`/api/v3/trades/swms/scopes/${id}`, "PUT", data),
  createCustomTrade: (data) => doFetch(`/api/v2/trades/c_trade`, "POST", data),
  createCustomScope: (data) => doFetch(`/api/v2/trades/c_scope`, "POST", data),
  deleteTrade: (id) => doFetch(`/api/v2/trades/c_trade/${id}`, "DELETE"),
  deleteScope: (id) => doFetch(`/api/v2/trades/c_scope/${id}`, "DELETE"),

  // workactivity new apis

  getActivity: ({
    order,
    take,
    page,
    companyId,
    approved,
    workActivityGroupId,
  }: WorkActivityQuery): Promise<pageMetaData<WHSWorkActivity[]>> =>
    doFetch(
      `/api/v3/whs/activities?all=${false}${
        workActivityGroupId ? `&workActivityGroupId=${workActivityGroupId}` : ""
      }${typeof approved === "boolean" ? `&approved=${approved}` : ""}${
        order ? `&order=${order}` : ""
      }${take ? `&take=${take}` : ""}${page ? `&page=${page}` : ""}${
        companyId ? `&companyId=${companyId}` : ""
      }`,
      "GET"
    ),

  getAllActivity: ({
    companyId,
    workActivityGroupId,
  }: WorkActivityQuery): Promise<WHSWorkActivity[]> =>
    doFetch(
      `/api/v3/whs/activities?all=${true}${
        workActivityGroupId ? `&workActivityGroupId=${workActivityGroupId}` : ""
      }${companyId ? `&companyId=${companyId}` : ""}`,
      "GET"
    ),

  getActivityById: (workActivityId: number): Promise<WHSWorkActivity> =>
    doFetch(`/api/v3/whs/activities/${workActivityId}`, "GET"),

  createActivity: (
    payload: CreateWHSWorkActivityDto
  ): Promise<WHSWorkActivity> =>
    doFetch(`/api/v3/whs/activities`, "POST", payload),
  updateAdminActivity: (
    workActivityId: number,
    payload: UpdateWHSWorkActivityDto
  ) =>
    doFetch(`/api/v3/whs/activities/admin/${workActivityId}`, "PUT", payload),
  updateActivity: (
    workActivityId: number,
    payload: UpdateWHSWorkActivityDto
  ): Promise<WHSWorkActivity> =>
    doFetch(`/api/v3/whs/activities/${workActivityId}`, "PUT", payload),
  deleteActivity: (workActivityId: number) =>
    doFetch(`/api/v3/whs/activities/${workActivityId}`, "DELETE"),
};
