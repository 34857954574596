import { pricingPlanDto } from "utils/PricingUtils";
import { doFetch } from "./fetcher";

export interface EstimateRequestDto {
  level: number;
  bundles: Array<string>;
  features: Array<string>;
  sahCount: number;
  nahCount: number;
  pahCount: number;
  additionalPermanentAuditLinks: number;
  additionalTemporaryAuditLinks: number;
  onsiteTrainingHours: number;
  assistedSetup: number;
  couponId: string;
  country: string;
}

export interface EstimateResponseDto {
  currency: string;
  lines: any[];
  subtotal: number;
  id: string;
  subtotal_excluding_tax: number;
  tax: number;
  total: number;
  total_excluding_tax: number;
}

export interface CurrentPlanDto {
  subscriptionId: string;
  customerId: string;
  status: string;
  expires: string;
  basePlanName: string;
  totalCost: 0;
  purchasedBundles: Array<string>;
  purchasedFeatures: Array<string>;
  nahSubscriptionCount: number;
  pahSubscriptionCount: number;
  sahSubscriptionCount: number;
  additionalPermanentAuditLinks: number;
  additionalTemporaryAuditLinks: number;
  level: number;
}

export interface UpdatePaymentDetails {
  returnUrl: string;
}

export default {
  getPricingPage: (): Promise<Array<pricingPlanDto>> =>
    doFetch("/api/v4/payments/plans", "GET"),
  getPriceEstimate: (data: EstimateRequestDto): Promise<EstimateResponseDto> =>
    doFetch("/api/v4/payments/estimate", "POST", data),

  stripeCheckout: (data: EstimateRequestDto, token?: string) =>
    doFetch(
      "/api/v4/payments/checkout",
      "POST",
      data,
      token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {}
    ),
  updatePaymentPortal: (data: UpdatePaymentDetails): Promise<{ url: string }> =>
    doFetch("/api/v4/payments/portal", "POST", data),
  stripeUpdateCheckout: (data: EstimateRequestDto) =>
    doFetch("/api/v4/payments/update", "POST", data),
  getCurrentPlan: (): Promise<CurrentPlanDto> =>
    doFetch("/api/v4/payments/current", "GET"),
};
