import React from "react";
import { useNavigate } from "react-router";
import BSModal, { BSModalDefaultProps } from "../../components/core/BSModal";
import CommonIcons from "utils/CommonIcons";
declare type ReqCompanyModalProps = BSModalDefaultProps & {
  onClickLeftMenu: boolean;
};

const ReqCompanyModal = ({
  isVisible,
  onClose,
  onClickLeftMenu,
  ...props
}: ReqCompanyModalProps) => {
  const navigate = useNavigate();
  return (
    <BSModal
      hideHeader
      isVisible={isVisible}
      onClose={onClose}
      modalBodyClassName="px-0 pt-0 text-center"
      modalProps={{
        centered: true,
      }}
    >
      <div className="px-5 pt-4">
        <h2 className="h2-text pb-3">
          {onClickLeftMenu ? "Company Details Required" : "Company Details"}
        </h2>
        <CommonIcons.LockIcon className="pb-3" style={{ height: "7rem" }} />
        {onClickLeftMenu ? (
          <p className="body-text lh-2">
            To access this feature and unlock the full capabilities of
            insite.work please enter your Company Details
          </p>
        ) : (
          <div className="my-3">
            <span className="body-text d-block">
              Company Details are required to unlock the full suite of
              insite.work features.
            </span>
            <span className="body-text d-block my-3">
              You can edit these details at anytime via the My Account page.
            </span>
            <span className="body-text d-block">
              Please finish entering these details before leaving this page.
            </span>
          </div>
        )}
      </div>
      <div className="">
        <button
          type="button"
          title="okay"
          aria-label="Okay and Confirm"
          className="btn primary-btn px-5 mb-3"
          onClick={() => {
            onClose && onClose();
            // navigate("/user/account");
            const menu = document.getElementsByClassName("left-section");
            if (menu.length > 0) {
              menu[0].classList.add("close");
              document.body.classList.remove("body-hidden");
            }
          }}
        >
          OKAY
        </button>
      </div>
    </BSModal>
  );
};

export default ReqCompanyModal;
