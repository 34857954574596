import React from "react";

import BSModal, { BSModalDefaultProps } from "../core/BSModal";

declare type CenterLoadingProps = BSModalDefaultProps;

const CenterLoading = ({ isVisible, onClose }: CenterLoadingProps) => {
  return (
    <BSModal
      size="lg"
      isVisible={isVisible}
      onClose={onClose}
      hideHeader
      modalStyle={{ zIndex: "1061" }}
      modalBodyClassName=""
      modalProps={{
        centered: true,
        contentClassName: "transparent-bg border-0",
      }}
    >
      <div className="sk-folding-cube sk-folding-cube-large">
        <div className="sk-cube1 sk-cube sk-cube-white"></div>
        <div className="sk-cube2 sk-cube sk-cube-white"></div>
        <div className="sk-cube4 sk-cube sk-cube-white"></div>
        <div className="sk-cube3 sk-cube sk-cube-white"></div>
      </div>
    </BSModal>
  );
};

export default CenterLoading;
