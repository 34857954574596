import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTitle } from "../utils/UseTitleUtils";
import { FallbackProps } from "react-error-boundary";
import { captureException, captureMessage } from "@sentry/react";

const FallBackUI = ({ error, resetErrorBoundary }: FallbackProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  useTitle("Oops");

  useEffect(() => {
    if (error.name === "ChunkLoadError") {
      console.log("reloading");
      window.location.reload();
      console.log("reloaded");
    } else {
      captureException(error);
      captureMessage(`Exception caught on page ${location.pathname}`);
    }
  }, [error]);

  return (
    <div className="content-section my-5 py-5 text-center">
      <div className="four_zero_four_bg"></div>
      <div className="text-center">
        <span className="d-block h2-text">Oops! Something went wrong.</span>
        <span className="d-block">You're not supposed to view this page.</span>
      </div>
      <div className="justify-content-center row mt-3">
        <div className="col-12 col-md-4">
          <button
            title="navigate back"
            aria-label="Navigate Back"
            className="btn primary-btn w-100"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default FallBackUI;
