import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../utils/UseTitleUtils";
import { GAEvents } from "utils/GoogleAnalytics";
import CommonIcons from "utils/CommonIcons";

interface EmailVerifiedProps {}

const EmailVerified = (props: EmailVerifiedProps) => {
  const navigate = useNavigate();
  useTitle("Email Confirmed!");
  const eventTracker = new GAEvents();

  React.useEffect(() => {
    eventTracker.userConversion();

    setTimeout(() => {
      navigate("/auth/login", {
        replace: true,
      });
    }, 5000);
  }, []);

  return (
    <div className="content-section d-flex poppins">
      <div className="col-12 col-lg-7 m-auto text-center">
        <div className="align-items-center d-flex flex-column vertical-center">
          <CommonIcons.EmailConfirmed
            className="mb-4"
            style={{ height: 100, width: 100 }}
          />
          <h1 className="h1-text poppins font-color-black">Email Confirmed!</h1>
          <div className="col-12 col-lg-11 col-md-10 pt-4 px-4">
            <span className="body-text d-block mb-3 lh-2">
              You email has successfully been confirmed.
            </span>
            <span className="body-text d-block mb-4 lh-2">
              You will automatically be redirected to the login page.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerified;
