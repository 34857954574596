import {
  AnyAction,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import users, { JobDto, ProjectDto } from "services/users";

import { ReduxState } from "./store";
import { WHSWorkActivity } from "services/swms";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;

const isPendingAction = (action: AnyAction): action is PendingAction =>
  action.type.startsWith(swmsSlice.name) && action.type.endsWith("/pending");
const isRejectedAction = (action: AnyAction): action is RejectedAction =>
  action.type.startsWith(swmsSlice.name) && action.type.endsWith("/rejected");

// action + pricing state types
export declare type SWMSState = {
  workActivities: WorkActivityType[];
  jobDetails: JobDto | null;
  projectDetails: ProjectDto | null;
  loading: boolean;
};

const emptyState: SWMSState = {
  workActivities: [],
  jobDetails: null,
  projectDetails: null,
  loading: false,
};

export type WorkActivityType = {
  name: string;
  id: number;
  isCollapse: boolean;
  is_selected: boolean;
  workActivityList: Array<
    WHSWorkActivity & {
      is_selected: boolean;
    }
  >;
};

export const getProjectDetails = createAsyncThunk(
  `swms/getProjectDetails`,
  async (id: Number) => {
    try {
      const data: ProjectDto = await users.getProjectByJob(Number(id));
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getJobDetails = createAsyncThunk(
  `swms/getJobDetails`,
  async (id: Number) => {
    try {
      const data: JobDto = await users.jobDetail(Number(id));
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// slice with action and reducer
const swmsSlice = createSlice({
  name: "swms",
  initialState: emptyState,
  reducers: {
    setSelectedWorkActivities(
      state,
      action: PayloadAction<WorkActivityType[]>
    ) {
      state.workActivities = action.payload;
    },
    addUpdateWorkActivity(
      state,
      action: PayloadAction<{
        groupId: number;
        workActivity: WHSWorkActivity;
      }>
    ) {
      const group = state.workActivities.find(
        (wag) => wag.id === action.payload.groupId
      );

      if (group) {
        const workActivity: WHSWorkActivity & {
          is_selected: boolean;
        } = {
          ...action.payload.workActivity,
          is_selected: true,
        };
        const updatedGroup: WorkActivityType = {
          ...group,
          workActivityList: [...group.workActivityList, workActivity],
        };

        state.workActivities = state.workActivities.map((wag) =>
          wag.id === updatedGroup.id ? { ...updatedGroup } : wag
        );
      } else {
        const workActivity = action.payload.workActivity;
        const workActivityList = [...state.workActivities];
        workActivityList.push({
          name: workActivity.workActivityGroup.name,
          id: workActivity.workActivityGroup.id,
          is_selected: true,
          isCollapse: false,
          workActivityList: [
            {
              ...workActivity,
              is_selected: true,
            },
          ],
        });
        state.workActivities = workActivityList;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobDetails.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            loading: false,
            jobDetails: action.payload,
          };
        } else {
          return { ...state, isLoading: false };
        }
      })
      .addCase(getProjectDetails.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            loading: false,
            projectDetails: action.payload,
          };
        } else {
          return { ...state, isLoading: false };
        }
      })
      .addMatcher(isPendingAction, (state) => {
        state.loading = true;
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = false;
      });
  },
});

// actions
export const { setSelectedWorkActivities, addUpdateWorkActivity } =
  swmsSlice.actions;

// reducer
export default swmsSlice.reducer;

export const swmsSelector = (state: ReduxState) => state.swms;
