import React, { useState } from "react";
import { Form } from "react-bootstrap";

import BSModal, { BSModalDefaultProps } from "../core/BSModal";

type SignSWMSAgreementModalProps = BSModalDefaultProps & {
  onSign?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const SignSWMSAgreementModal = ({
  isVisible,
  onClose,
  onSign,
}: SignSWMSAgreementModalProps) => {
  const [isCheck1, setIsCheck1] = useState(false);
  const [isCheck2, setIsCheck2] = useState(false);
  const [isCheck3, setIsCheck3] = useState(false);
  const [isCheck4, setIsCheck4] = useState(false);
  const [isCheck5, setIsCheck5] = useState(false);
  const [isCheck6, setIsCheck6] = useState(false);

  // value 1 represents that user presented pre start talk and 0 means he was present in pre start talk (not presented)
  const [presentedPreStart, setPresentedPreStart] = useState(-1);
  return (
    <BSModal
      isVisible={isVisible}
      onClose={onClose}
      hideHeader
      modalProps={{
        centered: true,
        onShow: () => {
          setIsCheck1(false);
          setIsCheck2(false);
          setIsCheck3(false);
          setPresentedPreStart(-1);
        },
      }}
      modalBodyClassName="px-0 pt-0"
      size="lg"
    >
      <div className="px-3 px-md-5 py-3 sign-swms-modal">
        <h1 className="h1-text font-color-black text-center border-dark border-bottom pb-4">
          Sign SWMS
        </h1>
        <div className="mt-2">
          <h5 className="fs-6 fw-600">
            Please tick to confirm the following and then press Sign SWMS to
            continue:
          </h5>
          <Form.Group controlId="check-1">
            <Form.Check
              name="check-1"
              label="I have completed a safety induction."
              className="py-2"
              required
              checked={isCheck1}
              onChange={(e) => {
                setIsCheck1(e.target.checked);
              }}
            />
          </Form.Group>
          <Form.Group controlId="check-2">
            <Form.Check
              name="check-2"
              label="I received/have correct PPE."
              className="py-2"
              required
              checked={isCheck2}
              onChange={(e) => {
                setIsCheck2(e.target.checked);
              }}
            />
          </Form.Group>
          <Form.Group controlId="check-3">
            <Form.Check
              name="check-3"
              label="I understand that all people are responsible for:"
              className="py-2"
              required
              checked={isCheck3}
              onChange={(e) => {
                setIsCheck3(e.target.checked);
              }}
            />
          </Form.Group>
          <span className="d-block body-text lh-2 my-3">
            reading, familiarising themselves with and acting in compliance with
            the site rules, SWMS and Management Plan where there is one,
            <br />
            <br />
            working in a safe manner without risk to themselves, others or the
            environment and taking all reasonable actions to protect themselves
            and others from harm, and
            <br />
            <br />
            correctly using PPE.
          </span>
        </div>
        <div className="my-4">
          <h5 className="fs-6 fw-600">Reporting to a work supervisor</h5>
          <Form.Group controlId="check-4">
            <Form.Check
              name="check-4"
              label="I understand my responsibilities to report any of the following to a work supervisor:"
              className="py-2"
              required
              checked={isCheck4}
              onChange={(e) => {
                setIsCheck4(e.target.checked);
              }}
            />
          </Form.Group>
          <div className="ps-3">
            <span className="d-block body-text lh-2 my-3">
              - all incidents, near misses and any breach of work health and
              safety standards,
              <br />
              <br />- any WHS hazards, faulty tools or plant, and
              <br />
              <br />- any injuries or illnesses.
            </span>
          </div>
          <Form.Group controlId="check-5">
            <Form.Check
              name="check-5"
              label="I agree to engage in consultation with work supervisors to provide suggestions on how to improve WHS issues."
              className="py-2"
              required
              checked={isCheck5}
              onChange={(e) => {
                setIsCheck5(e.target.checked);
              }}
            />
          </Form.Group>
          <Form.Group controlId="check-6">
            <Form.Check
              name="check-6"
              label="I also agree to comply with emergency and evacuation procedures"
              className="py-2"
              required
              checked={isCheck6}
              onChange={(e) => {
                setIsCheck6(e.target.checked);
              }}
            />
          </Form.Group>
        </div>

        <div className="mb-5">
          <h5 className="fs-6 fw-600">Pre-Start Talk:</h5>
          <Form.Group controlId="radio-1">
            <Form.Check
              name="pre-start"
              label="I presented the Pre-Start Talk and am familiar with the risks and hazards associated with the site and how they will be managed."
              className="py-2"
              required
              type="radio"
              checked={presentedPreStart == 1}
              onChange={(e) => {
                setPresentedPreStart(1);
              }}
            />
          </Form.Group>
          <Form.Group controlId="radio-2">
            <Form.Check
              name="pre-start"
              label="I was present for the Pre-Start Talk or the site supervisor has briefed me on site safety, risks and hazards associated with this site and how they will be managed."
              className="py-2"
              type="radio"
              required
              checked={presentedPreStart == 0}
              onChange={(e) => {
                setPresentedPreStart(0);
              }}
            />
          </Form.Group>
        </div>

        <div className="d-flex flex-fill justify-content-between my-2">
          <div className="col-4">
            <button
              title="close"
              aria-label="Close Modal"
              type="button"
              className="btn primary-btn-black fw-bold h-100 w-100"
              onClick={onClose}
            >
              CANCEL
            </button>
          </div>
          <div className="col-4">
            <button
              title="send"
              aria-label="Sign SWMS agreement"
              type="button"
              className="btn primary-btn fw-bold h-100 w-100"
              onClick={onSign}
            >
              SIGN SWMS
            </button>
          </div>
        </div>
      </div>
    </BSModal>
  );
};
export default SignSWMSAgreementModal;
