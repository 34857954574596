import React from "react";
import { Navigate, Route } from "react-router-dom";
import loadable from "@loadable/component";
import CenterLoading from "components/core/CenterLoading";
import AcceptWorkVariation from "pages/pah/variation/AcceptWorkVariation";
import { AccountFeatureDto } from "services/users";
import { calculateFeatures } from "utils/CommonUtils";
import ResponseSuccess from "pages/pah/audits/ResponseSuccess";

const AcceptWorkDefect = loadable(
  () => import("pages/pah/defects/AcceptWorkDefect"),
  {
    fallback: <CenterLoading isVisible />,
  }
);
const AuditResponse = loadable(
  () => import("./src/pages/pah/audits/AuditResponse"),
  {
    fallback: <CenterLoading isVisible />,
  }
);
// const TempWorkActivity = loadable(
//   () => import("pages/pah/swms/SectionThreeViewWorks"),
//   {
//     fallback: <CenterLoading isVisible />,
//   }
// );
// const TempWorkActivitySelection = loadable(
//   () => import("pages/pah/swms/TempWorkActivitySelection"),
//   { fallback: <CenterLoading isVisible /> }
// );
const ViewWorkActivities = loadable(
  () => import("pages/pah/swms/admin/ViewWorkActivities"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

// lazy loading components
const Scheduler = loadable(() => import("pages/pah/Scheduler/Scheduler"), {
  fallback: <CenterLoading isVisible />,
});

const DeleteAccount = loadable(() => import("pages/DeleteAccount"), {
  fallback: <CenterLoading isVisible />,
});

const PrivacyPolicy = loadable(() => import("pages/PrivacyPolicy"), {
  fallback: <CenterLoading isVisible />,
});
const TermsAndConditions = loadable(() => import("pages/TermsAndConditions"), {
  fallback: <CenterLoading isVisible />,
});
const Induction = loadable(() => import("pages/induction/Induction"), {
  fallback: <CenterLoading isVisible />,
});

const TimeSheet = loadable(() => import("pages/pah/worksites/jobs/TimeSheet"), {
  fallback: <CenterLoading isVisible />,
});
const WorkVariation = loadable(
  () => import("pages/pah/variation/WorkVariation"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const CreateWorkVariation = loadable(
  () => import("pages/pah/variation/CreateWorkVariation"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const WorkVariationDetails = loadable(
  () => import("pages/pah/variation/WorkVariationDetails"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const WorkDefects = loadable(() => import("pages/pah/defects/WorkDefects"), {
  fallback: <CenterLoading isVisible />,
});

const CreateWorkDefect = loadable(
  () => import("pages/pah/defects/CreateWorkDefect"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const WorkDefectDetails = loadable(
  () => import("pages/pah/defects/WorkDefectDetails"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const AuthVerification = loadable(
  () => import("./src/components/auth/AuthVerification"),
  { fallback: <CenterLoading isVisible /> }
);
const Login = loadable(() => import("./src/pages/auth/Login"), {
  fallback: <CenterLoading isVisible />,
});
const Logout = loadable(() => import("./src/pages/auth/Logout"), {
  fallback: <CenterLoading isVisible />,
});
const SignUp = loadable(() => import("./src/pages/auth/SignUp"), {
  fallback: <CenterLoading isVisible />,
});
const Contact = loadable(() => import("./src/pages/Contact"), {
  fallback: <CenterLoading isVisible />,
});
const GettingStarted = loadable(() => import("./src/pages/GettingStarted"), {
  fallback: <CenterLoading isVisible />,
});
const Help = loadable(() => import("./src/pages/Help"), {
  fallback: <CenterLoading isVisible />,
});
const UserGuide = loadable(() => import("./src/pages/UserGuide"), {
  fallback: <CenterLoading isVisible />,
});
const FAQ = loadable(() => import("./src/pages/FAQ"), {
  fallback: <CenterLoading isVisible />,
});
const Home = loadable(() => import("./src/pages/Home"), {
  fallback: <CenterLoading isVisible />,
});

const AuditHome = loadable(() => import("./src/pages/pah/audits/AuditHome"), {
  fallback: <CenterLoading isVisible />,
});

const CreateAudit = loadable(
  () => import("./src/pages/pah/audits/CreateAudit"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const AssignAudit = loadable(
  () => import("./src/pages/pah/audits/AssignAudit"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const AssignAuditManagement = loadable(
  () => import("./src/pages/pah/audits/AssignAuditManagement"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const ManageAudit = loadable(
  () => import("./src/pages/pah/audits/ManageAudit"),
  { fallback: <CenterLoading isVisible /> }
);

const AuditWeblinkManagement = loadable(
  () => import("./src/pages/pah/audits/AuditWeblinkManagement"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const AccountFeatures = loadable(
  () => import("./src/pages/pah/AccountFeatures"),
  { fallback: <CenterLoading isVisible /> }
);

const AccountManagement = loadable(
  () => import("./src/pages/pah/ManageAccount"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const ReviewAudit = loadable(
  () => import("./src/pages/pah/audits/ReviewAudit"),
  { fallback: <CenterLoading isVisible /> }
);

const ContractedAuditAssignmentDetail = loadable(
  () => import("./src/pages/pah/audits/ContractedAuditAssignmentDetail"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const AuditNewSite = loadable(
  () => import("./src/pages/pah/audits/AuditNewSite"),
  {
    fallback: <CenterLoading isVisible />,
  }
);
const AuditManageSite = loadable(
  () => import("./src/pages/pah/audits/AuditManageSite"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const ReviewCompletedAuditHome = loadable(
  () => import("./src/pages/pah/audits/ReviewCompletedAuditHome"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const IncidentReports = loadable(
  () => import("./src/pages/pah/audits/IncidentReports"),
  { fallback: <CenterLoading isVisible /> }
);

const JSAReports = loadable(() => import("./src/pages/pah/audits/JSAReports"), {
  fallback: <CenterLoading isVisible />,
});

const PAHAssignFirstJob = loadable(
  () => import("./src/pages/pah/contractor/PAHAssignFirstJob"),
  { fallback: <CenterLoading isVisible /> }
);
const DataManagement = loadable(
  () => import("./src/pages/pah/DataManagement"),
  { fallback: <CenterLoading isVisible /> }
);
const PAHEmployeeSetup = loadable(
  () => import("./src/pages/pah/employee/PAHEmployeeSetup"),
  { fallback: <CenterLoading isVisible /> }
);
const GantChart = loadable(() => import("./src/pages/pah/GanttChart"), {
  fallback: <CenterLoading isVisible />,
});
const GenerateInvoice = loadable(
  () => import("./src/pages/pah/GenerateInvoice"),
  { fallback: <CenterLoading isVisible /> }
);
const PAHAccountDetails = loadable(
  () => import("./src/pages/pah/PAHAccountDetails"),
  { fallback: <CenterLoading isVisible /> }
);
const PAHCompanyDetails = loadable(
  () => import("./src/pages/pah/PAHCompanyDetails"),
  { fallback: <CenterLoading isVisible /> }
);
const PAHGettingStarted = loadable(
  () => import("./src/pages/pah/PAHGettingStarted"),
  { fallback: <CenterLoading isVisible /> }
);
const PAHHome = loadable(() => import("./src/pages/pah/PAHHome"), {
  fallback: <CenterLoading isVisible />,
});
const PAHShareCompanyDetails = loadable(
  () => import("./src/pages/pah/PAHShareCompanyDetails"),
  { fallback: <CenterLoading isVisible /> }
);
const QuoteTool = loadable(() => import("./src/pages/pah/QuoteTool"), {
  fallback: <CenterLoading isVisible />,
});
const Pricing = loadable(() => import("./src/pages/purchase/Pricing"), {
  fallback: <CenterLoading isVisible />,
});

const PricingNew = loadable(() => import("./src/pages/purchaseFlow/Pricing"), {
  fallback: <CenterLoading isVisible />,
});

const UserCart = loadable(() => import("./src/pages/purchase/UserCart"), {
  fallback: <CenterLoading isVisible />,
});
const Admin = loadable(() => import("./src/pages/pah/contacts/admin/Admin"), {
  fallback: <CenterLoading isVisible />,
});
const WorkAccepted = loadable(
  () => import("./src/pages/pah/notification/WorkAccepted"),
  { fallback: <CenterLoading isVisible /> }
);
const WorkRejected = loadable(
  () => import("./src/pages/pah/notification/WorkRejected"),
  { fallback: <CenterLoading isVisible /> }
);
const AcceptAssignedWork = loadable(
  () => import("pages/pah/notification/AcceptAssignedWork"),
  { fallback: <CenterLoading isVisible /> }
);
const MyJobs = loadable(() => import("pages/staff/MyJobs"), {
  fallback: <CenterLoading isVisible />,
});
const StaffHome = loadable(() => import("pages/staff/StaffHome"), {
  fallback: <CenterLoading isVisible />,
});

// "/documents" routes imports
const WorkSiteInfoDocs = loadable(
  () => import("./src/pages/pah/worksitesInfoAndDocs/WorksiteInfoDocs"),
  { fallback: <CenterLoading isVisible /> }
);

const WorksiteViewAndSignDocument = loadable(
  () =>
    import("./src/pages/pah/worksitesInfoAndDocs/WorksiteViewAndSignDocument"),
  { fallback: <CenterLoading isVisible /> }
);

const WorksiteInfoDocUpload = loadable(
  () => import("./src/pages/pah/worksitesInfoAndDocs/WorksiteInfoDocUpload"),
  { fallback: <CenterLoading isVisible /> }
);

const ViewDocument = loadable(
  () => import("./src/pages/pah/worksites/ViewDocument"),
  { fallback: <CenterLoading isVisible /> }
);

const WorksiteHome = loadable(
  () => import("./src/pages/pah/worksites/WorksiteHome"),
  { fallback: <CenterLoading isVisible /> }
);

// "/notification" routes imports
const NotificationDashboard = loadable(
  () => import("./src/pages/pah/notification/NotificationDashboard"),
  { fallback: <CenterLoading isVisible /> }
);

const NotificationHome = loadable(
  () => import("./src/pages/pah/notification/NotificationHome"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const NotificationDetail = loadable(
  () => import("./src/pages/pah/notification/NotificationDetail"),
  { fallback: <CenterLoading isVisible /> }
);

const NotificationIssueWork = loadable(
  () => import("./src/pages/pah/notification/NotificationIssueWork"),
  { fallback: <CenterLoading isVisible /> }
);

const AcceptContract = loadable(
  () => import("./src/pages/pah/notification/AcceptContract"),
  { fallback: <CenterLoading isVisible /> }
);

const NotificationConfirmation = loadable(
  () => import("./src/pages/pah/notification/NotificationConfirmation"),
  { fallback: <CenterLoading isVisible /> }
);

const NotificationManagement = loadable(
  () => import("./src/pages/pah/notification/NotificationManagement"),
  { fallback: <CenterLoading isVisible /> }
);

// "/staff" routes imports
const HomeEmployeeSetup = loadable(
  () => import("./src/pages/pah/employee/HomeEmployeeSetup"),
  { fallback: <CenterLoading isVisible /> }
);

const PAHAddEmployee = loadable(
  () => import("./src/pages/pah/employee/PAHAddEmployee"),
  { fallback: <CenterLoading isVisible /> }
);

const PAHAddNotificationEmployee = loadable(
  () => import("./src/pages/pah/employee/PAHAddNotificationEmployee"),
  { fallback: <CenterLoading isVisible /> }
);

const ManageContractor = loadable(
  () => import("./src/pages/pah/contractor/ManageContractor"),
  { fallback: <CenterLoading isVisible /> }
);

const PAHConnectContractor = loadable(
  () => import("./src/pages/pah/contractor/PAHConnectContractor"),
  { fallback: <CenterLoading isVisible /> }
);

const ViewContractor = loadable(
  () => import("./src/pages/pah/contractor/ViewContractor"),
  { fallback: <CenterLoading isVisible /> }
);

const ManageContacts = loadable(
  () => import("./src/pages/pah/contacts/ManageContacts"),
  { fallback: <CenterLoading isVisible /> }
);

const AddContact = loadable(
  () => import("./src/pages/pah/contacts/AddContact"),
  { fallback: <CenterLoading isVisible /> }
);

// "/swms" routes imports
const SelectJob = loadable(() => import("./src/pages/pah/swms/SelectJob"), {
  fallback: <CenterLoading isVisible />,
});

const SWMSSectionOne = loadable(
  () => import("./src/pages/pah/swms/SWMSSectionOne"),
  { fallback: <CenterLoading isVisible /> }
);

const SWMSSectionDetails = loadable(
  () => import("./src/pages/pah/swms/SWMSSectionDetails"),
  { fallback: <CenterLoading isVisible /> }
);

const ContractorDetails = loadable(
  () => import("./src/pages/pah/swms/ContractorDetail"),
  { fallback: <CenterLoading isVisible /> }
);

const SectionThree = loadable(
  () => import("./src/pages/pah/swms/SectionThree"),
  { fallback: <CenterLoading isVisible /> }
);

const SectionThreeChooseTrade = loadable(
  () => import("./src/pages/pah/swms/SectionThreeChooseTrade"),
  { fallback: <CenterLoading isVisible /> }
);

const SectionThreeViewWorks = loadable(
  () => import("./src/pages/pah/swms/SectionThreeViewWorks"),
  { fallback: <CenterLoading isVisible /> }
);

const SectionThreeWorks = loadable(
  () => import("./src/pages/pah/swms/SectionThreeWorks"),
  { fallback: <CenterLoading isVisible /> }
);

const SectionThreeWorksEdit = loadable(
  () => import("./src/pages/pah/swms/SectionThreeWorksEdit"),
  { fallback: <CenterLoading isVisible /> }
);

const Links = loadable(() => import("./src/pages/pah/swms/Links"), {
  fallback: <CenterLoading isVisible />,
});

const GenerateSWMS = loadable(
  () => import("./src/pages/pah/swms/GenerateSWMS"),
  { fallback: <CenterLoading isVisible /> }
);

const SWMSGenerated = loadable(
  () => import("./src/pages/pah/swms/SWMSGenerated"),
  { fallback: <CenterLoading isVisible /> }
);

// "/worksite" routes imports
const ReviewOffers = loadable(
  () => import("./src/pages/pah/worksites/ReviewOffers"),
  { fallback: <CenterLoading isVisible /> }
);
const ManageWorksite = loadable(
  () => import("./src/pages/pah/worksites/ManageWorksite"),
  { fallback: <CenterLoading isVisible /> }
);
const ProjectList = loadable(
  () => import("./src/pages/pah/worksites/projects/ProjectList"),
  { fallback: <CenterLoading isVisible /> }
);
const CreateWorksite = loadable(
  () => import("./src/pages/pah/worksites/CreateWorksite"),
  { fallback: <CenterLoading isVisible /> }
);

const EditWorksite = loadable(
  () => import("./src/pages/pah/worksites/EditWorksite"),
  { fallback: <CenterLoading isVisible /> }
);

const CreateProjectIntro = loadable(
  () => import("./src/pages/pah/worksites/projects/CreateProjectIntro"),
  { fallback: <CenterLoading isVisible /> }
);

const CreateProject = loadable(
  () => import("./src/pages/pah/worksites/projects/CreateProject"),
  { fallback: <CenterLoading isVisible /> }
);

const EditProjectDetails = loadable(
  () => import("./src/pages/pah/worksites/projects/EditProjectDetails"),
  { fallback: <CenterLoading isVisible /> }
);

const PrincipalContractorDetails = loadable(
  () => import("./src/pages/pah/worksites/projects/PrincipalContractorDetails"),
  { fallback: <CenterLoading isVisible /> }
);

const EnterClientDetails = loadable(
  () => import("./src/pages/pah/worksites/projects/EnterClientDetails"),
  { fallback: <CenterLoading isVisible /> }
);

const CreateJob = loadable(
  () => import("./src/pages/pah/worksites/jobs/CreateJob"),
  { fallback: <CenterLoading isVisible /> }
);

const JobListNew = loadable(
  () => import("./src/pages/pah/worksites/jobs/JobListNew"),
  { fallback: <CenterLoading isVisible /> }
);

const ViewSWMS = loadable(
  () => import("./src/pages/pah/worksites/jobs/ViewSWMS"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const ProgressReport = loadable(
  () => import("./src/pages/pah/worksites/jobs/ViewProgressReports"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const RecordedPurchases = loadable(
  () => import("./src/pages/pah/worksites/jobs/ViewRecordedPurchases"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const ViewPST = loadable(
  () => import("./src/pages/pah/worksites/jobs/ViewPST"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const ViewPSSC = loadable(
  () => import("./src/pages/pah/worksites/jobs/ViewPSSC"),
  {
    fallback: <CenterLoading isVisible />,
  }
);

const CreateJobOption = loadable(
  () => import("./src/pages/pah/worksites/jobs/CreateJobOption"),
  { fallback: <CenterLoading isVisible /> }
);

const PageNotFound = loadable(() => import("./src/pages/PageNotFound"), {
  fallback: <CenterLoading isVisible />,
});

const AssignJobOption = loadable(
  () => import("./src/pages/pah/worksites/jobs/AssignJobOption"),
  { fallback: <CenterLoading isVisible /> }
);

const ContractAJob = loadable(
  () => import("./src/pages/pah/worksites/jobs/ContractAJob"),
  { fallback: <CenterLoading isVisible /> }
);

const AssignStaffToJob = loadable(
  () => import("./src/pages/pah/worksites/jobs/AssignStaffToJob"),
  { fallback: <CenterLoading isVisible /> }
);

const ViewJobDetails = loadable(
  () => import("./src/pages/pah/worksites/jobs/ViewJobDetails"),
  { fallback: <CenterLoading isVisible /> }
);

const ManageContractorOnSite = loadable(
  () => import("./src/pages/pah/worksites/jobs/ManageContractorOnsite"),
  { fallback: <CenterLoading isVisible /> }
);

// "/admin-swms" routes imports
const WorkActivities = loadable(
  () => import("pages/pah/swms/admin/WorkActivities"),
  { fallback: <CenterLoading isVisible /> }
);
const AdminSwmsChooseTrade = loadable(
  () => import("pages/pah/swms/admin/AdminSwmsChooseTrade"),
  { fallback: <CenterLoading isVisible /> }
);
const CustomTrade = loadable(() => import("pages/pah/swms/admin/CustomTrade"), {
  fallback: <CenterLoading isVisible />,
});

const EditScope = loadable(() => import("pages/pah/swms/admin/EditScope"), {
  fallback: <CenterLoading isVisible />,
});

export const authRoutes = (
  <Route path="/">
    <Route index element={<Home />} />
    <Route path="home" element={<Home />} />
    <Route path="getting-started" element={<GettingStarted />} />
    <Route path="pricing" element={<PricingNew />} />
    <Route path="pricing-old">
      <Route index element={<Pricing />} />
      <Route path="cart" element={<UserCart />} />
    </Route>
    <Route path="documents">
      <Route path=":shareId">
        <Route index element={<WorkSiteInfoDocs />} />
        <Route path="sign">
          <Route path=":documentId">
            <Route index element={<WorksiteViewAndSignDocument />} />
          </Route>
        </Route>
      </Route>
      <Route path=":documentId">
        <Route path="view" element={<ViewDocument />} />
      </Route>
    </Route>
    <Route path="auth">
      <Route path="signup">
        <Route index element={<SignUp />} />
      </Route>
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
    </Route>
    {/* <Route path="help">
      <Route index element={<Help />} />
      <Route path="user-guide" element={<UserGuide />} />
    </Route> */}
  </Route>
);

export const commonRoutes = (
  <Route path="/">
    <Route
      path="work-request/accept/:uuid"
      element={<WorkAccepted from={"auth"} />}
    />
    <Route
      path="work-request/decline/:uuid"
      element={<WorkRejected from={"auth"} />}
    />
    <Route
      path="notification/:notificationId/job/:jobId/assigned"
      element={<AcceptAssignedWork />}
    />
    <Route
      path="variation/action/:variationUUID"
      element={<AcceptWorkVariation />}
    />
    <Route path="defects/action/:defectUUID" element={<AcceptWorkDefect />} />
    <Route path="delete-account" element={<DeleteAccount />} />
    <Route path="privacy-policy" element={<PrivacyPolicy />} />
    <Route path="terms" element={<TermsAndConditions />} />
    <Route path="audits/link/:Audituuid" element={<AuditResponse />} />
    <Route
      path="audits/link/:Audituuid/response-success"
      element={<ResponseSuccess />}
    />
  </Route>
);

export const pahRoutes = (features: AccountFeatureDto[]) => {
  const featureMethod = calculateFeatures(features);

  return (
    <Route path="/" element={<AuthVerification />}>
      <Route index element={<PAHHome />} />
      <Route path="home">
        <Route index element={<PAHHome />} />
        <Route path="getting-started" element={<PAHGettingStarted />} />

        <Route path="notification-employee">
          <Route path="add" element={<PAHAddNotificationEmployee />} />
        </Route>

        <Route path="contractor">
          <Route index element={<PAHConnectContractor />} />
          <Route path="connect" element={<PAHConnectContractor />} />
        </Route>

        <Route path="job">
          <Route index element={<PAHAssignFirstJob />} />
          <Route path="first-job" element={<PAHAssignFirstJob />} />
        </Route>

        <Route path="employee">
          <Route index element={<PAHEmployeeSetup />} />
          <Route path="setup" element={<PAHEmployeeSetup />} />
          <Route path="add" element={<PAHAddEmployee />} />
        </Route>
      </Route>
      <Route path="admin">
        <Route index element={<Admin />} />
      </Route>
      {featureMethod?.showDocuments && featureMethod.showDocuments() && (
        <Route path="documents">
          <Route index element={<WorkSiteInfoDocs />} />
          <Route path="upload" element={<WorksiteInfoDocUpload />} />
          <Route path=":shareId">
            <Route index element={<WorkSiteInfoDocs />} />
            <Route path="sign">
              <Route path=":documentId">
                <Route index element={<WorksiteViewAndSignDocument />} />
              </Route>
            </Route>
          </Route>
          <Route path=":documentId">
            <Route path="view" element={<ViewDocument />} />
          </Route>
          <Route path="sign">
            <Route path=":documentId">
              <Route index element={<WorksiteViewAndSignDocument />} />
            </Route>
          </Route>
        </Route>
      )}
      <Route path="schedule" element={<Scheduler />} />
      {featureMethod?.showAudits && featureMethod.showAudits() && (
        <Route path="audits">
          <Route index element={<AuditHome />} />
          <Route path="create" element={<CreateAudit />} />
          <Route path="manage">
            <Route index element={<ManageAudit />} />
            <Route path="review/:auditId" element={<ReviewAudit />} />
          </Route>
          <Route path="assign">
            <Route
              path="site/:assignmentId"
              element={<ContractedAuditAssignmentDetail />}
            />
            <Route index element={<AssignAudit />} />
            <Route path=":auditId">
              <Route index element={<AssignAuditManagement />} />
              <Route path="manage-site" element={<AuditManageSite />} />
              <Route path="new-site" element={<AuditNewSite />} />
            </Route>
          </Route>
          <Route path="review">
            <Route index element={<ReviewCompletedAuditHome />} />
            <Route path=":auditId" element={<ReviewCompletedAuditHome />} />
          </Route>
          <Route path="web-link" element={<AuditWeblinkManagement />}></Route>
          <Route path="link/:Audituuid" element={<AuditResponse />} />
        </Route>
      )}
      <Route path="gantt">
        <Route index element={<GantChart />} />
      </Route>
      <Route path="quote-tool">
        <Route index element={<QuoteTool />} />
      </Route>
      <Route path="invoice">
        <Route index element={<GenerateInvoice />} />
        <Route path="generate" element={<GenerateInvoice />} />
      </Route>
      <Route path="data-management">
        <Route index element={<DataManagement />} />
      </Route>
      {featureMethod?.showInductions && featureMethod.showInductions() && (
        <Route path="induction">
          <Route index element={<Induction />} />
        </Route>
      )}
      {featureMethod?.showSwmsview && featureMethod?.showSwmsview() && (
        <Route path="view-swms" element={<ViewSWMS />} />
      )}
      {featureMethod?.showPssc && featureMethod.showPssc() && (
        <Route path="pssc" element={<ViewPSSC />} />
      )}
      {featureMethod?.showPst && featureMethod.showPst() && (
        <Route path="pst" element={<ViewPST />} />
      )}
      {featureMethod?.showPurchases && featureMethod.showPurchases() && (
        <Route path="record-purchases" element={<RecordedPurchases />} />
      )}
      {featureMethod?.showProgress && featureMethod.showProgress() && (
        <Route path="progress-reports" element={<ProgressReport />} />
      )}
      {featureMethod?.showIncident && featureMethod.showIncident() && (
        <Route path="incident-reports">
          <Route index element={<IncidentReports />} />
          <Route path=":incidentId" element={<IncidentReports />} />
        </Route>
      )}
      {featureMethod?.showJsa && featureMethod.showJsa() && (
        <Route path="jsa-reports">
          <Route index element={<JSAReports />} />
          <Route path=":jsaId" element={<JSAReports />} />
        </Route>
      )}

      {featureMethod?.showSwms && featureMethod.showSwms() && (
        <Route path="swms">
          <Route index element={<SelectJob />} />
          <Route path="select-job" element={<SelectJob />} />

          <Route path="section-one" element={<SWMSSectionOne />} />
          <Route path="pc-details" element={<PrincipalContractorDetails />} />
          <Route path="section-details" element={<SWMSSectionDetails />} />
          <Route path="contractor-detail" element={<ContractorDetails />} />
          <Route path="section-three" element={<SectionThree />} />
          <Route path="choose-trade" element={<SectionThreeChooseTrade />} />

          <Route path="view-activities" element={<SectionThreeViewWorks />} />
          <Route path="work-activities" element={<SectionThreeWorks />} />
          <Route
            path="work-activities-customize"
            element={<SectionThreeWorksEdit />}
          />
          <Route path="links" element={<Links />} />
          <Route path="generate-swms" element={<GenerateSWMS />} />
          <Route path="generated" element={<SWMSGenerated />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      )}

      {/* <Route path="work-activity">
        <Route index element={<SectionThreeViewWorks />} />
        <Route path="selected-wa" element={<SectionThreeWorks />} />
        <Route path=":workActivityId" element={<SectionThreeViewWorks />} />
      </Route> */}

      <Route path="staff">
        <Route path="employee">
          <Route index element={<HomeEmployeeSetup />} />
          <Route path="setup" element={<HomeEmployeeSetup />} />
          <Route path="add" element={<PAHAddEmployee />} />

          <Route path=":employeeId">
            <Route index element={<PAHAddEmployee />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>

          <Route path="notification">
            <Route path=":employeeId">
              <Route index element={<PAHAddNotificationEmployee />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Route>
        </Route>
        <Route path="contractors">
          <Route index element={<ManageContractor />} />
          <Route path="manage" element={<ManageContractor />} />
          <Route path="connect" element={<PAHConnectContractor />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path=":contractorId">
            <Route index element={<ViewContractor />} />
            <Route path="view" element={<ViewContractor />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
        <Route path="contacts">
          <Route index element={<ManageContacts />} />
          <Route path="add" element={<AddContact />} />
          <Route path=":contactId">
            <Route index element={<AddContact />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
      </Route>
      <Route path="worksite">
        <Route index element={<WorksiteHome />} />
        <Route path="review-contracts" element={<ReviewOffers />} />
        <Route path="manage" element={<ManageWorksite />} />
        <Route path="list" element={<ProjectList />} />

        <Route path="create" element={<CreateWorksite />} />
        <Route path=":worksiteId">
          <Route index element={<Navigate to={"/worksite/manage"} replace />} />
          <Route path="edit" element={<EditWorksite />} />
          <Route path="project">
            <Route index element={<CreateProjectIntro />} />
            <Route path="intro" element={<CreateProjectIntro />} />
            <Route path="create" element={<CreateProject />} />
            <Route path="list" element={<ProjectList />} />

            <Route path=":projectId">
              <Route index element={<ProjectList />} />
              <Route path="edit" element={<EditProjectDetails />} />
              <Route path="view" element={<EditProjectDetails />} />
              <Route
                path="pc-details"
                element={<PrincipalContractorDetails />}
              />
              <Route path="client-details" element={<EnterClientDetails />} />

              <Route path="job">
                <Route index element={<CreateJob />} />
                {/* 
              <Route path="associated" element={<JobListNew />} /> */}
                <Route path="list" element={<JobListNew />} />
                <Route path="create-job-option" element={<CreateJobOption />} />
                <Route path="create" element={<CreateJob />} />
                <Route path=":jobId">
                  <Route index element={<CreateJob />} />
                  <Route path="*" element={<PageNotFound />} />
                  <Route path="time-sheet" element={<TimeSheet />} />
                  <Route
                    path="assign-job-options"
                    element={<AssignJobOption />}
                  />
                  <Route path="confirm-edit-job" element={<CreateJob />} />
                  <Route path="contract-job" element={<ContractAJob />} />
                  <Route path="assign-staff" element={<AssignStaffToJob />} />
                  <Route path="view" element={<ViewJobDetails />} />
                  <Route
                    path="contractor-onsite"
                    element={<ManageContractorOnSite />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      {featureMethod?.showVariations && featureMethod.showVariations() && (
        <Route path="variation">
          <Route index element={<WorkVariation />} />
          <Route path="create" element={<CreateWorkVariation />} />
          <Route path=":variationId">
            <Route index element={<WorkVariationDetails />} />
          </Route>
        </Route>
      )}
      {featureMethod?.showDefects && featureMethod.showDefects() && (
        <Route path="defects">
          <Route index element={<WorkDefects />} />
          <Route path="create" element={<CreateWorkDefect />} />
          <Route path=":defectId">
            <Route index element={<WorkDefectDetails />} />
          </Route>
        </Route>
      )}
      <Route
        path="work-request/accept/"
        element={<WorkAccepted from={"pah-2"} />}
      />
      <Route
        path="work-request/decline/"
        element={<WorkRejected from={"pah-2"} />}
      />
      {featureMethod?.showNotifications &&
        featureMethod.showNotifications() && (
          <Route path="notification">
            <Route index element={<NotificationHome />} />
            <Route path="dashboard" element={<NotificationDashboard />} />
            <Route path=":notificationId">
              <Route index element={<NotificationDetail />} />
              <Route path="detail" element={<NotificationDetail />} />
              <Route path="job">
                <Route path=":jobId">
                  <Route path="accept" element={<AcceptContract />} />
                </Route>
              </Route>
              <Route path="issue-work" element={<NotificationIssueWork />} />
              <Route
                path="confirmation"
                element={<NotificationConfirmation />}
              />
              <Route path="management" element={<NotificationManagement />} />
            </Route>
          </Route>
        )}
      <Route path="cart" element={<UserCart />} />
      <Route path="help">
        <Route index element={<Help />} />
        <Route path="user-guide" element={<UserGuide />} />
        <Route path="faqs" element={<FAQ />} />
      </Route>
      <Route path="user">
        <Route index element={<PAHAccountDetails />} />
        <Route path="details" element={<PAHAccountDetails />} />
        <Route path="manage" element={<AccountManagement />} />

        {/* This flow is from company details to associate flow */}
        <Route path="account" element={<PAHCompanyDetails />} />
        <Route
          path="share-company-details"
          element={<PAHShareCompanyDetails />}
        />
      </Route>
      <Route path="manage-features" element={<AccountFeatures />} />
      <Route path="logout" element={<Logout />} />
    </Route>
  );
};

export const adminSWMSRoutes = (
  <Route path="admin-swms">
    <Route index element={<AdminSwmsChooseTrade />} />
    <Route path="view-wa" element={<ViewWorkActivities />} />
    <Route path="scope">
      <Route path=":scopeId">
        <Route path="edit" element={<EditScope />} />
        <Route path="edit-wa" element={<WorkActivities />} />
        <Route path="add-wa" element={<WorkActivities />} />
      </Route>
      <Route path="bulk-add">
        <Route index element={<CustomTrade />} />
        <Route
          path="existing-scope"
          element={<AdminSwmsChooseTrade selectScope />}
        />
      </Route>
      <Route path="bulk-add-wa" element={<WorkActivities />} />
    </Route>
    <Route path="custom-trade">
      <Route index element={<CustomTrade />} />
      <Route path=":tradeId">
        <Route path="add-scope" element={<CustomTrade />} />
        <Route path="edit" element={<CustomTrade />} />
        <Route
          path="existing-scope"
          element={<AdminSwmsChooseTrade selectScope />}
        />
        <Route path="work-activity" element={<WorkActivities />} />
      </Route>
    </Route>
  </Route>
);

export const withoutCompanyRoute = (
  <Route path="/" element={<AuthVerification />}>
    <Route index element={<Navigate to={"/user/account"} replace />} />
    <Route path="user">
      <Route path="account" element={<PAHCompanyDetails />} />
    </Route>
    <Route path="contact" element={<Contact />} />
    <Route path="help">
      <Route index element={<Help />} />
      <Route path="user-guide" element={<UserGuide />} />
      <Route path="faqs" element={<FAQ />} />
    </Route>
    <Route path="logout" element={<Logout />} />
    <Route path="*" element={<Navigate to={"/user/account"} replace />} />
  </Route>
);

export const staffAccountRoutes = (
  <Route path="/" element={<AuthVerification />}>
    <Route index element={<StaffHome />} />
    <Route path="home">
      <Route index element={<StaffHome />} />
    </Route>
    <Route path="user">
      <Route index element={<PAHAccountDetails />} />
    </Route>
    <Route
      path="worksite/:worksiteId/project/:projectId/job/:jobId/view"
      element={<ViewJobDetails />}
    />
    <Route path="my-jobs" element={<MyJobs />} />
    <Route
      path="work-request/accept/"
      element={<WorkAccepted from={"staff"} />}
    />
    <Route
      path="work-request/decline/"
      element={<WorkRejected from={"staff"} />}
    />
    <Route path="help">
      <Route index element={<Help />} />
      <Route path="user-guide" element={<UserGuide />} />
      <Route path="faqs" element={<FAQ />} />
    </Route>
    <Route path="contact" element={<Contact />} />
    <Route path="induction">
      <Route index element={<Induction />} />
    </Route>
    <Route />
  </Route>
);
