import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";

import auth from "services/auth";
import { AccountFeatureDto, Contact } from "services/users";

import StorageUtil from "utils/StorageUtil";

import { ReduxState } from "../store";

// action + user state types
export type UserProfile = {
  leadingHand?: number;
  roles?: string[];
  id?: number;
  ownerId?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  mobilePhone?: string;
  companyId?: number;
  customerId?: string;
  capacity?: number;
  pp_pah?: number;
  pp_sah?: number;
  pp_nah?: number;
  jobTitle?: string;
  status?: string;
  isActive?: boolean;
  createdOn?: string;
  groupId?: number;
  groupName?: string;
  companyName?: string;
  remember?: boolean;
  paymentStatus?: string;
  organizationId?: number;
  contactId?: number;
  emergencyContactId?: number;
  contact?: Contact;
  token?: string;
};

type AuthData = {
  features: AccountFeatureDto[];
  user: UserProfile;
  token: string;
  refreshToken: string;
  status?: object;
  statusCode?: number;
};

type AuthState = AuthData & {};

type LoginAction = {
  type: string;
  payload: {
    auth: AuthData;
    save: boolean;
  };
};

type LOGOUT_ACTION = {
  type: string;
  payload: undefined;
};

// export state types
export { AuthState, AuthData };

const emptyState: AuthData = {
  features: [],
  user: {},
  token: "",
  refreshToken: "",
};

// Common thunk function api calls for global use
const refreshUserDetails = createAsyncThunk(
  "users/refreshUserDetails",
  async (thunkAPI) => {}
);
const refreshLogout = createAsyncThunk(
  "users/refreshApi401",
  async (thunkAPI) => {
    try {
      const response = await auth.refreshUser();

      return response;
    } catch (err) {
      throw err;
    }
  }
);

// actual redux reducer + actions with initial state
const AuthSlice = createSlice({
  name: "auth",
  initialState: { ...emptyState },
  reducers: {
    login(state: AuthState, action: LoginAction) {
      state = action.payload.auth;

      global.authToken = state.token;
      global.refreshToken = state.refreshToken;

      if (
        action.payload.save &&
        action.payload.auth &&
        action.payload.auth.statusCode != 401
      ) {
        localStorage.setItem("_auth", JSON.stringify(state));
        localStorage.setItem(
          "_refreshToken",
          JSON.stringify(state.refreshToken)
        );
      }

      return state;
    },
    updateFeatureList(
      state: AuthState,
      action: PayloadAction<AccountFeatureDto[]>
    ) {
      state.features = action.payload.filter((e) => e.role === "PAH");
      localStorage.setItem("_auth", JSON.stringify(current(state)));
    },
    updateUserData(state: AuthState, action: LoginAction) {
      state = action.payload.auth;

      localStorage.setItem("_auth", JSON.stringify(state));

      return state;
    },
    logout(state: AuthState, action: LOGOUT_ACTION) {
      state = { ...emptyState };

      global.authToken = undefined;
      global.refreshToken = undefined;

      StorageUtil.logout();
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshUserDetails.rejected, (state, action) => {});

    builder.addCase(refreshLogout.fulfilled, (state, { payload }) => {
      if (localStorage.getItem("_auth")) {
        state.user = payload.user;
        state.token = payload.token;
        state.refreshToken = payload.refreshToken;
        if (payload.refreshToken == null) {
          localStorage.removeItem("_refreshToken");
        }
        localStorage.setItem("_auth", JSON.stringify(state));
        window.location.reload();
      }
    });
    // handle rejection in case you want to
    builder.addCase(refreshLogout.rejected, (state, action) => {});
  },
});

// actions
export const { login, updateUserData, logout, updateFeatureList } =
  AuthSlice.actions;

// export thunk functions
export { refreshUserDetails, refreshLogout };

//common method
const lowercaseArray = (arr?: string[]) => {
  if (arr === undefined) return [];

  return arr.map((r) => r.toLowerCase());
};

// selector

export const isAuthenticated = (state: ReduxState) => {
  return Boolean(state?.auth?.user?.id);
};

export const isPaid = (state: ReduxState) =>
  state?.auth?.user?.paymentStatus === "paid";
export const role = (state: ReduxState) => {
  return lowercaseArray(state?.auth?.user?.roles || ["guest"])[0];
};
export const isGuest = (state: ReduxState) => {
  return !state?.auth?.user?.id;
};
export const isAdmin = (state: ReduxState) => {
  return lowercaseArray(state?.auth?.user?.roles).includes("admin");
};
export const isPAH = (state: ReduxState) => {
  return lowercaseArray(state?.auth?.user?.roles).includes("pah");
};
export const isSAH = (state: ReduxState) => {
  return lowercaseArray(state?.auth?.user?.roles).includes("sah");
};
export const isNAH = (state: ReduxState) => {
  return lowercaseArray(state?.auth?.user?.roles).includes("nah");
};

export const checkPending = (state: ReduxState) => {
  return state.auth?.user?.status == "pending_company";
};

export const checkIsActivated = (state: ReduxState) => {
  return state.auth.user?.status != "data_filled";
};

export const checkPAHPayment = (user: UserProfile) => {
  return (
    user?.status == "data_filled" &&
    user?.paymentStatus == "unpaid" &&
    user?.companyId
  );
};

export const userProfile = (state: ReduxState) => {
  return state.auth?.user;
};

export const getFeaturesList = (state: ReduxState) => {
  return state.auth?.features;
};

// reducer
export default AuthSlice.reducer;
