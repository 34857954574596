import React from "react";

const AudioIcons = {
  AudioPlay: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.15 32.5L32.5 24L19.15 15.5V32.5ZM24 44C21.2667 44 18.6833 43.475 16.25 42.425C13.8167 41.375 11.6917 39.9417 9.875 38.125C8.05833 36.3083 6.625 34.1833 5.575 31.75C4.525 29.3167 4 26.7333 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05833 11.65 9.875 9.85C11.6917 8.05 13.8167 6.625 16.25 5.575C18.6833 4.525 21.2667 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7333 43.475 29.3167 42.425 31.75C41.375 34.1833 39.95 36.3083 38.15 38.125C36.35 39.9417 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44Z"
        fill="black"
      />
    </svg>
  ),

  AudioPause: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2311_6)">
        <path
          d="M24 0C10.7666 0 0 10.7666 0 24C0 37.2334 10.7666 48 24 48C37.2334 48 48 37.2334 48 24C48 10.7666 37.2334 0 24 0ZM21 33H15V15H21V33ZM33 33H27V15H33V33Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2311_6">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  AudioStop: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 31.5H31.5V16.5H16.5V31.5ZM24 44C21.2667 44 18.6833 43.475 16.25 42.425C13.8167 41.375 11.6917 39.9417 9.875 38.125C8.05833 36.3083 6.625 34.1833 5.575 31.75C4.525 29.3167 4 26.7333 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05833 11.65 9.875 9.85C11.6917 8.05 13.8167 6.625 16.25 5.575C18.6833 4.525 21.2667 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7333 43.475 29.3167 42.425 31.75C41.375 34.1833 39.95 36.3083 38.15 38.125C36.35 39.9417 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44Z"
        fill="black"
      />
    </svg>
  ),
  AudioTranscript: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 27.5H20V24.5H12V27.5ZM37.9941 23.5C36.4647 23.5 35.1667 22.9647 34.1 21.8941C33.0333 20.8235 32.5 19.5235 32.5 17.9941C32.5 16.4647 33.0347 15.1667 34.1042 14.1C35.1736 13.0333 36.4722 12.5 38 12.5C38.4333 12.5 38.8583 12.55 39.275 12.65C39.6917 12.75 40.1 12.8833 40.5 13.05V2H48V5H43.5V18C43.5 19.5278 42.9647 20.8264 41.8941 21.8958C40.8235 22.9653 39.5235 23.5 37.9941 23.5ZM12 21.5H26V18.5H12V21.5ZM12 15.5H26V12.5H12V15.5ZM12 36L4 44V7C4 6.175 4.29375 5.46875 4.88125 4.88125C5.46875 4.29375 6.175 4 7 4H31C31.825 4 32.5313 4.29375 33.1188 4.88125C33.7063 5.46875 34 6.175 34 7V9.95C32.5 10.75 31.2917 11.8667 30.375 13.3C29.4583 14.7333 29 16.3 29 18C29 19.7 29.4583 21.2667 30.375 22.7C31.2917 24.1333 32.5 25.25 34 26.05V33C34 33.825 33.7063 34.5313 33.1188 35.1188C32.5313 35.7063 31.825 36 31 36H12Z"
        fill="black"
      />
    </svg>
  ),
  AudioRewind: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.6998 36L25.3998 24L42.6998 12V36ZM22.5998 36L5.2998 24L22.5998 12V36Z"
        fill="black"
      />
    </svg>
  ),
};

export default AudioIcons;
