import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileDto } from "services/users";
import { ReduxState } from "./store";

export declare type AudioDto = {
  audioSrc: string | undefined;
  audioIndex: number;
  isPlay: boolean;
  fileId: number;
  audioID: number;
  audioData: FileDto;
};

export const audioPlayerSlice = createSlice({
  name: "audioPlayer",
  initialState: {
    audioSrc: undefined,
    audioIndex: -1,
    isPlay: false,
    audioID: null,
    audioData: undefined,
  } as AudioDto,
  reducers: {
    toggleAudio: (state, action: PayloadAction<string>) => {
      if (state.audioIndex === -1) {
        state.audioIndex = 0;
        state.audioSrc = action.payload;
      }
      if (window.audioRef.current.paused) {
        (document.getElementById("audio-player") as HTMLAudioElement)?.play();
        state.isPlay = true;
      } else {
        (document.getElementById("audio-player") as HTMLAudioElement)?.pause();
        state.isPlay = false;
      }
    },
    stopAudio: (state) => {
      state.audioIndex = -1;
      state.audioSrc = "";
      state.isPlay = false;
    },
    setAudioID: (state, action: PayloadAction<number>) => {
      state.audioID = action.payload;
    },
    setAudioData: (state, action: PayloadAction<FileDto>) => {
      state.audioData = action.payload;
    },

    setAudioSrc: (state, action: PayloadAction<string>) => {
      if (state.audioIndex === -1) {
        state.audioIndex = 0;
      }
      state.audioSrc = action.payload;
      if (window.audioRef.current.paused) {
        state.isPlay = true;
      } else {
        state.isPlay = false;
      }
    },
    changeisPlayStatus: (state, action: PayloadAction<boolean>) => {
      state.isPlay = action.payload;
    },
  },
});

export const getAudioSrc = (state: ReduxState) => {
  return state?.audioPlayer.audioSrc;
};

export const getAudioID = (state: ReduxState) => {
  return state?.audioPlayer.audioID;
};
export const getAudioData = (state: ReduxState) => {
  return state?.audioPlayer.audioData;
};

export const getPlayStatus = (state: ReduxState) => {
  return state?.audioPlayer.isPlay;
};

export const {
  toggleAudio,
  stopAudio,
  setAudioSrc,
  setAudioID,
  setAudioData,
  changeisPlayStatus,
} = audioPlayerSlice.actions;

export default audioPlayerSlice.reducer;
