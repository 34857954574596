import {
  AnyAction,
  AsyncThunk,
  PayloadAction,
  createAsyncThunk,
  createSlice,
  current,
} from "@reduxjs/toolkit";
import users, { CompanyDto, ScopeDto, TradeDto } from "services/users";
import { IJob } from "src/types";
import { ReduxState } from "./store";
import { AuditResponseTemplateDto, AuditTemplateDto } from "services/audit";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;

const isPendingAction = (action: AnyAction): action is PendingAction =>
  action.type.startsWith(userSlice.name) && action.type.endsWith("/pending");
const isRejectedAction = (action: AnyAction): action is RejectedAction =>
  action.type.startsWith(userSlice.name) && action.type.endsWith("/rejected");

// action + pricing state types
export declare type UserState = {
  loading: boolean;
  scopeLoading: boolean;
  companyDetails: CompanyDto | null;
  trades: TradeDto[];
  scopes: ScopeDto[];
  layoutAudit: AuditResponseTemplateDto | null;
};

const emptyState: UserState = {
  loading: false,
  scopeLoading: false,
  companyDetails: null,
  trades: [],
  scopes: [],
  layoutAudit: null,
};

export const getCompanyDetails = createAsyncThunk(
  `user/getcompanyDetails`,
  async (id: Number) => {
    try {
      const data = await users.companyDetails(Number(id));
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getTradeList = createAsyncThunk(`user/getTradeList`, async () => {
  try {
    const data: TradeDto[] = await users.trades();
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getScopesList = createAsyncThunk(
  `user/getScopesList`,
  async () => {
    try {
      const data: ScopeDto[] = await users.scopes();
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// slice with action and reducer
const userSlice = createSlice({
  name: "user",
  initialState: emptyState,
  reducers: {
    layoutAudit: (state, action: PayloadAction<AuditResponseTemplateDto>) => {
      state.layoutAudit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            loading: false,
            companyDetails: action.payload,
          };
        } else {
          return { ...state, isLoading: false };
        }
      })
      .addCase(getTradeList.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            loading: false,
            trades: action.payload,
          };
        } else {
          return { ...state, isLoading: false };
        }
      })
      .addCase(getScopesList.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            loading: false,
            scopeLoading: false,
            scopes: action.payload,
          };
        } else {
          return { ...state, isLoading: false, scopeLoading: false };
        }
      })
      .addCase(getScopesList.pending, (state, action) => {
        return {
          ...state,
          scopeLoading: true,
        };
      })
      .addMatcher(isPendingAction, (state) => {
        state.loading = true;
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = false;
      });
  },
});

export const getAuditTemplate = (state: ReduxState) => {
  return state.user.layoutAudit;
};

// actions
export const { layoutAudit } = userSlice.actions;

// reducer
export default userSlice.reducer;

export const userSelector = (state: ReduxState) => state.user;
