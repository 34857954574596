import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { getAutocompleteType } from "../components/contact/ContactForm";
import CenterLoading from "../components/core/CenterLoading";

import { useTitle } from "../utils/UseTitleUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHouse,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import users from "services/users";
import { Helmet } from "react-helmet";
import { GAEvents } from "utils/GoogleAnalytics";

type Input = {
  name?: string;
  phone?: string;
  email?: string;
  message?: string;
  global?: any;
};

const defaultInput = {
  name: "",
  phone: "",
  email: "",
  message: "",
};

const Contact = ({}) => {
  const [inputs, setInputs] = useState<Input>(defaultInput);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Input>({});
  const eventTracker = new GAEvents();

  function handleChange(
    fieldName: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    delete errors[fieldName];
    delete errors.global;

    setInputs({
      ...inputs,
      [fieldName]: e.target.value,
    });
    setErrors(errors);
  }

  function errorMessage(field: string) {
    if (!errors) return null;
    if (typeof errors[field] === "undefined") return null;

    return (
      <Form.Control.Feedback className="font-color-red poppins" type="invalid">
        {errors[field]}
      </Form.Control.Feedback>
    );
  }

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    const data = {
      fullName: inputs.name,
      contactNumber: inputs.phone,
      emailAddress: inputs.email,
      message: inputs.message,
    };

    setIsLoading(true);

    try {
      await users.contactUs(data);
      setInputs(defaultInput);
      setIsLoading(false);
      setErrors({});
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    // eventTracker.trackPageView(window.location.pathname);
  }, []);

  return (
    <div className="row contact-us g-0 content-section h-100">
      <Helmet>
        <title>Contact Us | insite.work</title>
        <meta
          name="description"
          content="Get in touch with a sales representative"
        />
      </Helmet>
      <CenterLoading isVisible={isLoading} />
      <div className="col-lg-6 col-md-12 d-flex flex-column">
        <div className="form-container">
          <h1 className="h1-text poppins font-color-black">Contact Us</h1>
          <p className="body-text poppins">
            Contact insite.work using the form below or directly via our
            details:
          </p>

          <Form onSubmit={onSubmit} className="validate">
            <div>
              <Form.Group controlId="name" className="mb-3">
                <Form.Label className="poppins mb-2">Full Name:</Form.Label>
                <Form.Control
                  autoComplete={getAutocompleteType("fullName")}
                  className="poppins"
                  type="text"
                  required
                  value={inputs.name}
                  onChange={(e) => handleChange("name", e as any)}
                  isInvalid={errors.name != undefined}
                />
                {errorMessage("name")}
              </Form.Group>
              <Form.Group controlId="phone" className="mb-3">
                <Form.Label className="poppins mb-2">
                  Contact Number:
                </Form.Label>
                <Form.Control
                  className="poppins"
                  type="text"
                  required
                  autoComplete={getAutocompleteType("phoneNumber")}
                  // value={new AsYouType().input(inputs.phone)}
                  value={inputs.phone}
                  onChange={(e) => handleChange("phone", e as any)}
                  isInvalid={errors.phone != undefined}
                />
                {errorMessage("phone")}
              </Form.Group>
            </div>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label className="poppins mb-2">Email Address:</Form.Label>
              <Form.Control
                className="poppins"
                type="text"
                required
                autoComplete={getAutocompleteType("email")}
                value={inputs.email}
                onChange={(e) => handleChange("email", e as any)}
                isInvalid={errors.email != undefined}
              />
              {errorMessage("email")}
            </Form.Group>
            <Form.Group controlId="message" className="mb-3">
              <Form.Label className="poppins mb-2">Message:</Form.Label>
              <Form.Control
                className="poppins"
                as="textarea"
                rows={5}
                value={inputs.message}
                onChange={(e) => handleChange("message", e as any)}
                isInvalid={errors.message != undefined}
              />
              {errorMessage("message")}
            </Form.Group>

            <button
              title="send"
              aria-label="Send Feedback"
              type="submit"
              disabled={isLoading}
              className="btn col-12 primary-btn primary-btn-lg"
            >
              SEND
            </button>
          </Form>
        </div>
      </div>
      <div className="col-lg-6 col-md-12">
        <div className="col-lg-12 d-lg-flex d-md-block flex-column form-container py-4 right-bg">
          <div className="contact-section">
            <h2 className="h2-text work-sans">Get in touch directly</h2>
            <div className="d-flex mt-4">
              <FontAwesomeIcon
                icon={faHouse}
                className="font-color-primary icon"
              />
              <p className="ml-3 poppins">
                Insite.Work
                <br />
                PO Box 68
                <br />
                Wilston, Brisbane
                <br />
                4051
              </p>
            </div>
            <div className="d-flex mt-4">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="icon font-color-primary"
              />
              <a
                href="mailto:admin@insite.work"
                className="ml-3 text-decoration-none poppins"
              >
                admin@insite.work
              </a>
            </div>
            <div className="d-flex mt-4">
              <FontAwesomeIcon
                icon={faPhone}
                className="icon font-color-primary"
              />
              <a
                href="tel:+61731023496"
                className="ml-3 poppins text-decoration-none"
              >
                (07) 3102 3496
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
