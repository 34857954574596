import { AuthData, logout, refreshLogout } from "../redux/auth";
import { store as reduxStore } from "../redux/store";

type ErrorResponse = {
  data: any;
  errors: object[];
  code: number;
  message: string;
};
let timeout;
var debounce = function (func, delay) {
  clearTimeout(timeout);
  timeout = setTimeout(func, delay);
};
async function checkForError(response: Response) {
  if (response.status >= 400 && response.status <= 511) {
    if (response.status == 401 && localStorage.getItem("_auth")) {
      if (response.url.includes("/auth/refresh")) {
        reduxStore.dispatch(logout());
        window.location.pathname = "/auth/login";
        return;
      }
      debounce(function () {
        reduxStore.dispatch(refreshLogout());
      }, 1000);
      return;
    }

    const contentType = response.headers.get("Content-Type");
    if (!contentType.includes("application/json")) {
      return response;
    }
    const error: ErrorResponse = await response.json();
    error.code = response.status;
    if (error.errors || error.message) {
      if (error.message) {
        // Show global alert on top right corner
        if (error?.data?.swmsStep == undefined) {
          if (window["alertContainer"]) {
            window["alertContainer"].error(error.message);
          }
        }
      } else {
        window["alertContainer"].error(`${Object.values(error.errors)[0]}`);
      }
      throw error;
    }
  }
  // return response to continue
  return response;
}

type RequestMethod =
  | "get"
  | "post"
  | "put"
  | "delete"
  | "GET"
  | "POST"
  | "PUT"
  | "DELETE";

function baseUrl(url) {
  if (window.location.host == "dev.insite.work") {
    return `https://api-dev.insite.work${url}`;
  } else if (window.location.host == "staging.insite.work") {
    return `https://api-staging.insite.work${url}`;
  } else if (
    window.location.host == "insite.work" ||
    window.location.host == "www.insite.work"
  ) {
    return `https://api.insite.work${url}`;
  }

  return `https://api-dev.insite.work${url}`;
}

export function doFetch(
  url: string,
  method: RequestMethod = "get",
  data = {},
  headers = {}
) {
  const params: RequestInit = { headers };

  /*   const baseUrl =
    typeof window.KS_ENV === "undefined"
      ? window.location.origin
      : `https://api-${window.KS_ENV}.keensafety.com.au/`;
  const urlObj = new URL(url, baseUrl);
  console.log(urlObj.href, "ur;"); */
  // const rewrittenUrl = urlObj.href;
  const rewrittenUrl = `https://api-dev.insite.work${url}`;

  // if token exist, set in header.
  const auth: AuthData = JSON.parse(localStorage.getItem("_auth"));

  if (auth?.token) {
    params.headers["Authorization"] = `Bearer ${auth.token}`;
  }

  // if request is to refresh user then pass refresh token.
  if (url.includes("/auth/refresh")) {
    const refreshTokenFromLocal = localStorage.getItem("_refreshToken") ?? "";
    if (refreshTokenFromLocal != "") {
      const refreshToken = JSON.parse(refreshTokenFromLocal);
      params.headers["Authorization"] = `Bearer ${refreshToken}`;
    }
  }

  // when trying to upload files.
  if (url.includes("/v3/files") && method.toUpperCase() !== "GET") {
    params.method = method;
    params.body = data as any;
  }

  if (method.toLowerCase() !== "get" && !url.includes("/v3/files")) {
    params.method = method;
    params.headers["Content-Type"] = "application/json";
    if (method.toLowerCase() !== "delete") {
      params.body = JSON.stringify(data);
    }
  }
  return (
    fetch(baseUrl(url), params)
      // .then(checkStatus)
      .then(checkForError)
      .then((response) => {
        const contentType = response.headers.get("Content-Type");
        if (contentType.includes("application/json")) {
          return response.json();
        } else {
          return response;
        }
      })
      .then((response) => {
        if (response?.message) {
          window["alertContainer"].info(response.message);
        }
        return response;
      })
  );
}

export { ErrorResponse };
