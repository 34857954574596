import React, { useEffect, useState } from "react";
import CenterLoading from "components/core/CenterLoading";
import { Form } from "react-bootstrap";
import users from "services/users";
import { useNavigate } from "react-router-dom";
import { updateFeatureList, updateUserData } from "reduxStore/auth";
import { useDispatch } from "react-redux";

const AdminTools = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitDetails = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email === "" || email === undefined) {
      setError("Please enter email address.");
      return;
    }
    sentImpersonateUser();
  };

  const sentImpersonateUser = async () => {
    setIsLoading(true);
    try {
      const impersonateUser = await users.ImpersonateUser({
        email: email,
      });

      const response = await users.getFeatures();

      if (impersonateUser) {
        dispatch(
          updateUserData({
            auth: impersonateUser,
            save: true,
          })
        );
        dispatch(updateFeatureList(response));
        navigate("/");
        setEmail("");
        setError("");
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  function errorMessage() {
    if (!error) return null;
    if (typeof error === "undefined") return null;

    return (
      <Form.Control.Feedback className="font-color-red poppins" type="invalid">
        {error}
      </Form.Control.Feedback>
    );
  }

  return (
    <div className="content-section poppins p-3 p-md-5">
      <CenterLoading isVisible={isLoading} />
      <h1 className="h1-text font-color-black">Admin Tools</h1>
      <div className="row">
        <Form className="mt-4 col-md-6 col-12" onSubmit={submitDetails}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Email address:</Form.Label>
            <Form.Control
              value={email}
              type="text"
              className="poppins"
              isInvalid={error !== undefined}
              onChange={(e) => {
                setError(undefined);
                setEmail(e.target.value);
              }}
            />
            {errorMessage()}
          </Form.Group>
          <button
            title="submit"
            aria-label="Impersonate User"
            disabled={isLoading}
            type="submit"
            className="align-items-center btn primary-btn col-12 col-sm-auto nav-btn w-100"
          >
            Impersonate
          </button>
        </Form>
      </div>
    </div>
  );
};

export default AdminTools;
