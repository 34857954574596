import React, { useState } from "react";
import BSModal, { BSModalDefaultProps } from "../core/BSModal";
import { Form } from "react-bootstrap";
import { emailRegex } from "pages/auth/SignUp";
import swms from "services/swms";
import CommonIcons from "utils/CommonIcons";

type SendSWMSModalProps = BSModalDefaultProps & {
  onOkay: () => void;
  swmsId: number;
};

const SendSWMSModal = ({
  isVisible,
  onClose,
  onOkay,
  swmsId,
}: SendSWMSModalProps) => {
  const [text, setText] = useState<string>("");
  const [emailError, setEmailError] = useState<string>();
  const [emails, setEmails] = useState<string[]>([]);

  const sendSWMS = async () => {
    try {
      const payload = {
        swmsId: swmsId,
        emails: emails,
      };
      if (text.length > 0) {
        const tempEmails = await onAddEmail();
        payload.emails = tempEmails;
      }
      if (payload.emails.length > 0) {
        const response = await swms.sendSwms(payload);
        onOkay();
      } else {
        setEmailError("Please enter valid email address");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onAddEmail = () => {
    if (!emailRegex.test(text)) {
      setEmailError("Please enter valid email address");
      return;
    }

    if (emails.includes(text)) {
      setEmailError("Email address already exist");
      return;
    }

    const temp = [...emails, text];
    setEmails(temp);
    setText("");
    return temp;
  };

  return (
    <BSModal
      size="lg"
      isVisible={isVisible}
      onClose={onClose}
      modalProps={{
        centered: true,
        onExit(node) {
          setEmails([]);
        },
      }}
      hideHeader
      modalBodyClassName="px-0 pt-0"
    >
      <div className="px-3 px-md-5 py-3">
        <h1 className="h1-text font-color-black text-center border-dark border-bottom pb-4">
          Send SWMS
        </h1>

        <div className="text-center">
          <span className="body-text d-block lh-2 mx-3 mx-md-5">
            Enter the email address that you would like your SWMS sent to.
            <br />
            <br />
            Please note the SWMS is automatically sent to all employees and
            contractors registered to the site when it is generated
          </span>
        </div>

        <div className="m-auto mb-4 col-12 col-md-8 mt-3">
          <div>
            {emails.map((e, index) => {
              return (
                <div
                  key={index}
                  className="border border-dark g-0 justify-content-between light-gray-bg m-0 mb-2 p-2 rounded-3 row"
                >
                  <span className="link text-break w-auto">{e}</span>
                  <div
                    tabIndex={0}
                    role="button"
                    className="w-auto p-0"
                    onKeyDown={(e) => {
                      if (e.key == " " || e.key == "Enter") {
                        e.currentTarget.click();
                        e.preventDefault();
                      }
                    }}
                    onClick={() => {
                      const temp = [...emails];
                      temp.splice(index, 1);
                      setEmails(temp);
                    }}
                  >
                    <CommonIcons.CancelIcon
                      style={{ height: 22, width: 22 }}
                      className="p-0"
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <Form.Group controlId="email">
            <Form.Control
              type="email"
              autoComplete="email"
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  onAddEmail();
                }
              }}
              placeholder="Enter your email address here"
              value={text}
              onChange={(e) => {
                setEmailError(undefined);
                setText(e.target.value);
              }}
              isInvalid={emailError != undefined}
            />
            <Form.Control.Feedback
              className="poppins font-color-red"
              type="invalid"
            >
              {emailError}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="my-3">
            <button
              title="add email"
              aria-label="Add Another Email Address"
              type="button"
              disabled={!emailRegex.test(text)}
              className="btn primary-btn w-100"
              onClick={() => onAddEmail()}
            >
              ADD ANOTHER EMAIL ADDRESS
            </button>
          </div>
        </div>

        <div className="row justify-content-between">
          <div className="col-12 col-md-5">
            <button
              title="close"
              aria-label="Close Modal"
              type="button"
              className="btn primary-btn-black fw-bold h-100 w-100"
              id="clear"
              onClick={onClose}
            >
              Close
            </button>
          </div>

          <div className="col-12 col-md-5 mt-3 mt-md-0">
            <button
              type="button"
              title="send"
              aria-label="Send SWMS"
              className="btn primary-btn fw-bold h-100 w-100"
              id="save"
              onClick={sendSWMS}
            >
              Send SWMS
            </button>
          </div>
        </div>
      </div>
    </BSModal>
  );
};

export default SendSWMSModal;
