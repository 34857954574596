import { AddressData } from "../components/address";

import { SignUpInput } from "../pages/auth/SignUp";
import { SelectedQualification } from "../pages/pah/employee/PAHAddEmployee";

import { doFetch } from "./fetcher";
import { Contact } from "./users";

type SignUpRequest = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  address: AddressData;
  leadingHand: boolean;
  role?: object;
};
export declare type JobProjectAttached = {
  action: "add" | "remove";
  id?: number;
};

export declare type RegisterStaffRequest = {
  photoFileId?: number;
  driverLicenseFileId?: number;
  address: AddressData;
  qualifications?: SelectedQualification[];
  managerFirstName?: string;
  managerLastName?: string;
  managerEmail?: string;
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber: string;
  jobTitle?: string;
  password?: string;
  confirmPassword?: string;
  jobsAttached?: JobProjectAttached[];
  projectsAttached?: JobProjectAttached[];
  leadingHand?: boolean;
  emergencyContactId?: number;
  emergencyContact?: Contact;
  role: "SAH" | "SAH_LH" | "NAH" | "PAH";
};

export { SignUpRequest };

export default {
  // new methods
  login: (data) => doFetch("/api/v3/auth/login", "POST", data),
  signup: (
    data: SignUpInput & {
      address: AddressData;
    }
  ) => doFetch("/api/v3/auth/register", "POST", data),

  registerStaff: (data: RegisterStaffRequest) =>
    doFetch("/api/v3/auth/register-staff", "POST", data),

  refreshUser: () => doFetch("/api/v3/auth/refresh", "GET"),

  //legacy methods
  emailConfirm: (activation_code) =>
    doFetch("/api/users/confirm", "PUT", {
      action: "confirm",
      activation_code,
    }),
  emailConfirmSAH: (activation_code, password, firstName, lastName) =>
    doFetch("/api/users/confirm", "PUT", {
      action: "confirm",
      activation_code,
      password,
      firstName,
      lastName,
    }),
  checkInviteCode: (code) =>
    doFetch("/api/users/check_confirm", "GET", {}, { "Activation-Code": code }),
  cart: (pah, sah, nah) =>
    doFetch("/api/users/pp", "PUT", { action: "update", pah, sah, nah }),
};
