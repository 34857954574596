import * as React from 'react';

type StepProgressProps = {
  activeIndex: number;
  data: string[];
};

const StepProgress = ({ data, activeIndex, ...props }: StepProgressProps) => {
  var progress = activeIndex == 0 ? 0 : 100 / (data.length - activeIndex);
  progress = activeIndex >= data.length ? 100 : progress;
  return (
    <div className="step-progress">
      <ul className="align-items-end d-flex justify-content-between steps">
        {data.map((e, index) => {
          return (
            <li
              key={`step-${index}`}
              className={`poppins${index <= activeIndex ? " done" : ""}${
                index == activeIndex ? " active" : ""
              }`}
            >
              {e}
            </li>
          );
        })}
      </ul>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default StepProgress;
