import React from "react";
const CommonIcons = {
  ArrowRight: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2312_18)">
        <path d="M10 17L15 12L10 7V17Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_2312_18">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),

  ExpandIcon: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.85 34.3H38.4V24.6H35.4V31.3H28.85V34.3ZM9.65 23.4H12.65V16.7H19.2V13.7H9.65V23.4ZM7 40C6.2 40 5.5 39.7 4.9 39.1C4.3 38.5 4 37.8 4 37V11C4 10.2 4.3 9.5 4.9 8.9C5.5 8.3 6.2 8 7 8H41C41.8 8 42.5 8.3 43.1 8.9C43.7 9.5 44 10.2 44 11V37C44 37.8 43.7 38.5 43.1 39.1C42.5 39.7 41.8 40 41 40H7ZM7 37H41V11H7V37Z"
        fill="black"
      />
    </svg>
  ),

  ArrowDown: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2312_25)">
        <path d="M0 2.99998L6.00003 9.00001L12 2.99998H0Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_2312_25">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  CancelIcon: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.45 32.55C15.75 32.85 16.1 33 16.5 33C16.9 33 17.25 32.85 17.55 32.55L24 26.1L30.5 32.6C30.7667 32.8667 31.1083 32.9917 31.525 32.975C31.9417 32.9583 32.2833 32.8167 32.55 32.55C32.85 32.25 33 31.9 33 31.5C33 31.1 32.85 30.75 32.55 30.45L26.1 24L32.6 17.5C32.8667 17.2333 32.9917 16.8917 32.975 16.475C32.9583 16.0583 32.8167 15.7167 32.55 15.45C32.25 15.15 31.9 15 31.5 15C31.1 15 30.75 15.15 30.45 15.45L24 21.9L17.5 15.4C17.2333 15.1333 16.8917 15.0083 16.475 15.025C16.0583 15.0417 15.7167 15.1833 15.45 15.45C15.15 15.75 15 16.1 15 16.5C15 16.9 15.15 17.25 15.45 17.55L21.9 24L15.4 30.5C15.1333 30.7667 15.0083 31.1083 15.025 31.525C15.0417 31.9417 15.1833 32.2833 15.45 32.55ZM24 44C21.1667 44 18.5333 43.4917 16.1 42.475C13.6667 41.4583 11.55 40.05 9.75 38.25C7.95 36.45 6.54167 34.3333 5.525 31.9C4.50833 29.4667 4 26.8333 4 24C4 21.2 4.50833 18.5833 5.525 16.15C6.54167 13.7167 7.95 11.6 9.75 9.8C11.55 8 13.6667 6.58333 16.1 5.55C18.5333 4.51667 21.1667 4 24 4C26.8 4 29.4167 4.51667 31.85 5.55C34.2833 6.58333 36.4 8 38.2 9.8C40 11.6 41.4167 13.7167 42.45 16.15C43.4833 18.5833 44 21.2 44 24C44 26.8333 43.4833 29.4667 42.45 31.9C41.4167 34.3333 40 36.45 38.2 38.25C36.4 40.05 34.2833 41.4583 31.85 42.475C29.4167 43.4917 26.8 44 24 44Z"
        fill="#FF0000"
      />
    </svg>
  ),
  EmailConfirmed: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 23.9C17.8 23.9 16 23.2 14.6 21.8C13.2 20.4 12.5 18.6 12.5 16.4C12.5 14.2 13.2 12.4 14.6 11C16 9.6 17.8 8.9 20 8.9C22.2 8.9 24 9.6 25.4 11C26.8 12.4 27.5 14.2 27.5 16.4C27.5 18.6 26.8 20.4 25.4 21.8C24 23.2 22.2 23.9 20 23.9ZM31.25 40.8L24.2 33.75L26.3 31.65L31.25 36.6L41.9 25.95L44 28.05L31.25 40.8ZM4 40V35.3C4 34.1667 4.28333 33.125 4.85 32.175C5.41667 31.225 6.26667 30.5 7.4 30C9.8 28.9333 12 28.1667 14 27.7C16 27.2333 18 27 20 27C20.9667 27 21.9917 27.0583 23.075 27.175C24.1583 27.2917 25.2667 27.5 26.4 27.8L20.2 34.25L25.95 40H4Z"
        fill="#1D65A6"
      />
    </svg>
  ),
  AddEmployee: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 23.75C17.8 23.75 16 23.05 14.6 21.65C13.2 20.25 12.5 18.45 12.5 16.25C12.5 14.05 13.2 12.25 14.6 10.85C16 9.45 17.8 8.75 20 8.75C22.2 8.75 24 9.45 25.4 10.85C26.8 12.25 27.5 14.05 27.5 16.25C27.5 18.45 26.8 20.25 25.4 21.65C24 23.05 22.2 23.75 20 23.75ZM4 39.8V35.05C4 33.8833 4.3 32.8333 4.9 31.9C5.5 30.9667 6.33333 30.2667 7.4 29.8C9.4 28.9333 11.45 28.2167 13.55 27.65C15.65 27.0833 17.8 26.8 20 26.8C20.2 26.8 20.3917 26.8 20.575 26.8C20.7583 26.8 20.95 26.8 21.15 26.8C20.4833 28.9333 20.2333 31.1083 20.4 33.325C20.5667 35.5417 21.0667 37.7 21.9 39.8H4ZM33.35 42L32.85 38.7C32.2833 38.5333 31.7083 38.2917 31.125 37.975C30.5417 37.6583 30.05 37.3 29.65 36.9L26.9 37.5L25.65 35.4L28 33.2C27.9333 32.9 27.9 32.4833 27.9 31.95C27.9 31.4167 27.9333 31 28 30.7L25.65 28.5L26.9 26.4L29.65 27C30.05 26.6 30.5417 26.2417 31.125 25.925C31.7083 25.6083 32.2833 25.3667 32.85 25.2L33.35 21.9H36.05L36.55 25.2C37.1167 25.3667 37.6917 25.6083 38.275 25.925C38.8583 26.2417 39.35 26.6 39.75 27L42.5 26.4L43.75 28.5L41.4 30.7C41.4667 31 41.5 31.4167 41.5 31.95C41.5 32.4833 41.4667 32.9 41.4 33.2L43.75 35.4L42.5 37.5L39.75 36.9C39.35 37.3 38.8583 37.6583 38.275 37.975C37.6917 38.2917 37.1167 38.5333 36.55 38.7L36.05 42H33.35ZM34.7 35.95C35.9 35.95 36.8667 35.5833 37.6 34.85C38.3333 34.1167 38.7 33.15 38.7 31.95C38.7 30.75 38.3333 29.7833 37.6 29.05C36.8667 28.3167 35.9 27.95 34.7 27.95C33.5 27.95 32.5333 28.3167 31.8 29.05C31.0667 29.7833 30.7 30.75 30.7 31.95C30.7 33.15 31.0667 34.1167 31.8 34.85C32.5333 35.5833 33.5 35.95 34.7 35.95Z"
        fill="white"
      />
    </svg>
  ),
  AddNotificationEmployee: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 42L24 4L46 42H2ZM22.7 30.6H25.7V19.4H22.7V30.6ZM24.2 36.15C24.6333 36.15 24.9917 36.0083 25.275 35.725C25.5583 35.4417 25.7 35.0833 25.7 34.65C25.7 34.2167 25.5583 33.8583 25.275 33.575C24.9917 33.2917 24.6333 33.15 24.2 33.15C23.7667 33.15 23.4083 33.2917 23.125 33.575C22.8417 33.8583 22.7 34.2167 22.7 34.65C22.7 35.0833 22.8417 35.4417 23.125 35.725C23.4083 36.0083 23.7667 36.15 24.2 36.15Z"
        fill="white"
      />
    </svg>
  ),
  AddManagerEmployee: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.2 28.95C32.9 28.95 31.7917 28.4917 30.875 27.575C29.9583 26.6583 29.5 25.55 29.5 24.25C29.5 22.95 29.9583 21.8417 30.875 20.925C31.7917 20.0083 32.9 19.55 34.2 19.55C35.5 19.55 36.6083 20.0083 37.525 20.925C38.4417 21.8417 38.9 22.95 38.9 24.25C38.9 25.55 38.4417 26.6583 37.525 27.575C36.6083 28.4917 35.5 28.95 34.2 28.95ZM24.4 40V37.45C24.4 36.5833 24.5833 35.8417 24.95 35.225C25.3167 34.6083 25.8333 34.1333 26.5 33.8C27.7333 33.1667 28.9833 32.7 30.25 32.4C31.5167 32.1 32.8333 31.95 34.2 31.95C35.5667 31.95 36.8833 32.0917 38.15 32.375C39.4167 32.6583 40.6667 33.1333 41.9 33.8C42.5667 34.1 43.0833 34.5667 43.45 35.2C43.8167 35.8333 44 36.5833 44 37.45V40H24.4ZM20 23.95C17.8 23.95 16 23.25 14.6 21.85C13.2 20.45 12.5 18.65 12.5 16.45C12.5 14.25 13.2 12.45 14.6 11.05C16 9.65 17.8 8.95 20 8.95C22.2 8.95 24 9.65 25.4 11.05C26.8 12.45 27.5 14.25 27.5 16.45C27.5 18.65 26.8 20.45 25.4 21.85C24 23.25 22.2 23.95 20 23.95ZM4 40V35.3C4 34.1667 4.28333 33.125 4.85 32.175C5.41667 31.225 6.26667 30.5 7.4 30C9.63333 29 11.775 28.25 13.825 27.75C15.875 27.25 17.9333 27 20 27C21.0667 27 22.0167 27.05 22.85 27.15C23.6833 27.25 24.5833 27.4 25.55 27.6L23 30.15C22.3333 30.8167 21.9 31.5167 21.7 32.25C21.5 32.9833 21.4 34.5667 21.4 37V40H4Z"
        fill="white"
      />
    </svg>
  ),
  CreateProject: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 47.4C8.2 47.4 7.5 47.1 6.9 46.5C6.3 45.9 6 45.2 6 44.4V14.4C6 13.6 6.3 12.9 6.9 12.3C7.5 11.7 8.2 11.4 9 11.4H29.25L15 25.7V38.4H27.7L42 24V44.4C42 45.2 41.7 45.9 41.1 46.5C40.5 47.1 39.8 47.4 39 47.4H9ZM18 35.4V26.9L33.1 11.8L41.65 20.2L26.5 35.4H18ZM43.8 18.05L35.25 9.65L38.1 6.8C38.6667 6.23333 39.375 5.95 40.225 5.95C41.075 5.95 41.7833 6.25 42.35 6.85L46.55 11.1C47.1167 11.7 47.4 12.4083 47.4 13.225C47.4 14.0417 47.1 14.7333 46.5 15.3L43.8 18.05Z"
        fill="white"
      />
    </svg>
  ),
  HomeIcon: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2312_43)">
        <path
          d="M505.586 250.004C514.867 239.797 513.971 224.152 503.585 215.069L274.765 14.8506C264.379 5.76801 247.728 5.95828 237.56 15.2796L7.96258 225.744C-2.20489 235.065 -2.70392 250.69 6.85771 260.629L12.614 266.624C22.164 276.563 37.6004 277.748 47.0703 269.263L64.2291 253.896V478.862C64.2291 492.665 75.413 503.839 89.2055 503.839H178.703C192.496 503.839 203.68 492.665 203.68 478.862V321.477H317.835V478.862C317.636 492.655 327.505 503.829 341.298 503.829H436.143C449.935 503.829 461.119 492.655 461.119 478.852V257.064C461.119 257.064 465.859 261.216 471.705 266.355C477.542 271.484 489.801 267.372 499.082 257.154L505.586 250.004Z"
          fill="#5D5D5D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2312_43">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  IssueSelfIcon: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.15 31.85L31.7 24.3L29.55 22.15L25.5 26V16.4H22.5V26L18.65 22.15L16.5 24.3L24.15 31.85ZM9 42C8.16667 42 7.45833 41.7083 6.875 41.125C6.29167 40.5417 6 39.8333 6 39V9C6 8.16667 6.29167 7.45833 6.875 6.875C7.45833 6.29167 8.16667 6 9 6H19.25C19.4167 4.83333 19.95 3.875 20.85 3.125C21.75 2.375 22.8 2 24 2C25.2 2 26.25 2.375 27.15 3.125C28.05 3.875 28.5833 4.83333 28.75 6H39C39.8333 6 40.5417 6.29167 41.125 6.875C41.7083 7.45833 42 8.16667 42 9V39C42 39.8333 41.7083 40.5417 41.125 41.125C40.5417 41.7083 39.8333 42 39 42H9ZM24 8.15C24.4667 8.15 24.875 7.975 25.225 7.625C25.575 7.275 25.75 6.86667 25.75 6.4C25.75 5.93333 25.575 5.525 25.225 5.175C24.875 4.825 24.4667 4.65 24 4.65C23.5333 4.65 23.125 4.825 22.775 5.175C22.425 5.525 22.25 5.93333 22.25 6.4C22.25 6.86667 22.425 7.275 22.775 7.625C23.125 7.975 23.5333 8.15 24 8.15Z"
        fill="white"
      />
    </svg>
  ),
  IssueContractorIcon: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2312_47)">
        <path
          d="M2.9502 42C2.1502 42 1.4502 41.7 0.850195 41.1C0.250195 40.5 -0.0498047 39.8 -0.0498047 39V9C-0.0498047 8.2 0.250195 7.5 0.850195 6.9C1.4502 6.3 2.1502 6 2.9502 6H45.0002C45.8002 6 46.5002 6.3 47.1002 6.9C47.7002 7.5 48.0002 8.2 48.0002 9V39C48.0002 39.8 47.7002 40.5 47.1002 41.1C46.5002 41.7 45.8002 42 45.0002 42H2.9502ZM16.4502 27.5C18.0835 27.5 19.4752 26.925 20.6252 25.775C21.7752 24.625 22.3502 23.2333 22.3502 21.6C22.3502 19.9667 21.7752 18.575 20.6252 17.425C19.4752 16.275 18.0835 15.7 16.4502 15.7C14.8169 15.7 13.4252 16.275 12.2752 17.425C11.1252 18.575 10.5502 19.9667 10.5502 21.6C10.5502 23.2333 11.1252 24.625 12.2752 25.775C13.4252 26.925 14.8169 27.5 16.4502 27.5ZM28.1002 21.8H41.5002V12.5H28.1002V21.8ZM3.4502 39H29.4502C27.9502 36.5333 26.0752 34.6667 23.8252 33.4C21.5752 32.1333 19.1169 31.5 16.4502 31.5C13.7835 31.5 11.3169 32.1333 9.0502 33.4C6.78353 34.6667 4.91686 36.5333 3.4502 39ZM34.8502 19.95L29.6002 16V14L34.8002 17.95L40.0002 14V16L34.8502 19.95Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2312_47">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  SubJobParent: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 30.45L32.65 21.8L30.5 19.7L24 26.2L17.5 19.7L15.35 21.8L24 30.45ZM24 44C21.2667 44 18.6833 43.475 16.25 42.425C13.8167 41.375 11.6917 39.9417 9.875 38.125C8.05833 36.3083 6.625 34.1833 5.575 31.75C4.525 29.3167 4 26.7333 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05833 11.65 9.875 9.85C11.6917 8.05 13.8167 6.625 16.25 5.575C18.6833 4.525 21.2667 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7333 43.475 29.3167 42.425 31.75C41.375 34.1833 39.95 36.3083 38.15 38.125C36.35 39.9417 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44Z"
        fill="black"
      />
    </svg>
  ),
  SubJobChild: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.1998 34.7H13.9998C10.9665 34.7 8.4248 33.675 6.3748 31.625C4.3248 29.575 3.2998 27.0333 3.2998 24C3.2998 20.9667 4.3248 18.4167 6.3748 16.35C8.4248 14.2833 10.9665 13.25 13.9998 13.25H22.1998V17.25H13.9998C12.0665 17.25 10.4581 17.8917 9.1748 19.175C7.89147 20.4583 7.2498 22.0667 7.2498 24C7.2498 25.9333 7.89147 27.5417 9.1748 28.825C10.4581 30.1083 12.0665 30.75 13.9998 30.75H22.1998V34.7ZM15.7498 25.65V22.35H32.2498V25.65H15.7498ZM25.7998 34.7V30.75H33.9998C35.9331 30.75 37.5415 30.1083 38.8248 28.825C40.1081 27.5417 40.7498 25.9333 40.7498 24C40.7498 22.0667 40.1081 20.4583 38.8248 19.175C37.5415 17.8917 35.9331 17.25 33.9998 17.25H25.7998V13.25H33.9998C37.0331 13.25 39.5831 14.2833 41.6498 16.35C43.7165 18.4167 44.7498 20.9667 44.7498 24C44.7498 27.0333 43.7165 29.575 41.6498 31.625C39.5831 33.675 37.0331 34.7 33.9998 34.7H25.7998Z"
        fill="black"
      />
    </svg>
  ),
  StarIcon: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.65 44L16.3 28.8L4 20H19.2L24 4L28.8 20H44L31.7 28.8L36.35 44L24 34.6L11.65 44Z"
        fill="black"
      />
    </svg>
  ),
  LockIcon: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 16.3H30.5V11.5C30.5 9.7 29.8667 8.16667 28.6 6.9C27.3333 5.63333 25.8 5 24 5C22.2 5 20.6667 5.63333 19.4 6.9C18.1333 8.16667 17.5 9.7 17.5 11.5H14.5C14.5 8.86667 15.425 6.625 17.275 4.775C19.125 2.925 21.3667 2 24 2C26.6333 2 28.875 2.925 30.725 4.775C32.575 6.625 33.5 8.86667 33.5 11.5V16.3H37C37.8333 16.3 38.5417 16.5917 39.125 17.175C39.7083 17.7583 40 18.4667 40 19.3V41C40 41.8333 39.7083 42.5417 39.125 43.125C38.5417 43.7083 37.8333 44 37 44H11C10.1667 44 9.45833 43.7083 8.875 43.125C8.29167 42.5417 8 41.8333 8 41V19.3C8 18.4667 8.29167 17.7583 8.875 17.175C9.45833 16.5917 10.1667 16.3 11 16.3ZM24 34C25.0667 34 25.975 33.6333 26.725 32.9C27.475 32.1667 27.85 31.2833 27.85 30.25C27.85 29.25 27.475 28.3417 26.725 27.525C25.975 26.7083 25.0667 26.3 24 26.3C22.9333 26.3 22.025 26.7083 21.275 27.525C20.525 28.3417 20.15 29.25 20.15 30.25C20.15 31.2833 20.525 32.1667 21.275 32.9C22.025 33.6333 22.9333 34 24 34Z"
        fill="#39A85D"
      />
    </svg>
  ),
};

export default CommonIcons;
