import React from "react";
import { Modal, ModalProps } from "react-bootstrap";

export declare type BSModalDefaultProps = {
  isVisible: boolean;
  onClose?: () => void;
};

type BSModalProps = BSModalDefaultProps & {
  hideHeader?: boolean;
  fullHeight?: boolean;
  title?: string;
  children?: React.ReactNode;
  modalStyle?: React.CSSProperties;
  modalBodyClassName?: string;
  modalHeaderClassName?: string | undefined;
  size?: "sm" | "lg" | "xl";
  modalProps?: ModalProps;
  onHide?: () => void;
  closeButton?: boolean;
};

const BSModal = ({
  isVisible = false,
  onClose,
  title,
  size,
  modalBodyClassName,
  modalStyle,
  modalHeaderClassName,
  modalProps,
  fullHeight = false,
  hideHeader,
  onHide,
  closeButton = true,
  ...props
}: BSModalProps) => {
  return (
    <Modal
      show={isVisible}
      onHide={onHide ?? onClose}
      size={size}
      className="poppins"
      contentClassName={fullHeight ? "h-100" : ""}
      style={modalStyle}
      scrollable
      {...modalProps}
    >
      {!hideHeader && (
        <Modal.Header
          closeVariant="white"
          closeButton={closeButton}
          className={modalHeaderClassName}
        >
          {title && <Modal.Title>{title}</Modal.Title>}
        </Modal.Header>
      )}
      <Modal.Body className={modalBodyClassName}>{props.children}</Modal.Body>
    </Modal>
  );
};

export default BSModal;
