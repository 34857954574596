import ReactGA from "react-ga4";
import { GA4, UaEventOptions } from "react-ga4/types/ga4";

export const eventConstants = {
  ORG_SELECT: "Organization Selected",
  ORG_UNSELECT: "Organization UnSelected",
  BUNDLE_SELECT: "Bundle Selected",
  BUNDLE_UNSELECT: "Bundle UnSelected",
  FEATURE_SELECT: "Feature Selected",
  FEATURE_UNSELECT: "Feature UnSelected",
  WEBLINK_COUNT_INCREASED: "Web-link Count Increased",
  WEBLINK_COUNT_DECREASED: "Web-link Count Decreased",
  EMPLOYEE_COUNT_INCREASED: "Employee Count Increased",
  EMPLOYEE_COUNT_DECREASED: "Employee Count Decreased",
  EXTRAS_COUNT_INCREASED: "Extras Count Increased",
  EXTRAS_COUNT_DECREASED: "Extras Count Decreased",
  SELECTED_PLAN_DETAILS: "Selected plan for final checkout",
  CART_CHECKOUT: "Final Cart Checkout",
  UPDATE_PLAN_CHECKOUT: "Update Plan Checkout",
  CREATE_JOB: "Create Job",
  UPDATE_JOB: "Update Job",
  CREATE_PROJECT: "Create Project",
  UPDATE_PROJECT: "Update Project",
  CREATE_WORKSITE: "Create Worksite",
  UPDATE_WORKSITE: "Update Worksite",
  ENABLE_FEATURE: "Enable Feature",
  DISABLE_FEATURE: "Disable Feature",
};

const streamKeys = {
  development: "G-344GTCMD9D",
  staging: "G-4KSBEKBHC8",
  production: "G-BFMTRB9G6S",
};

export class GAEvents {
  reactGa: GA4;
  constructor() {
    // Replace 'your-measurement-id' with your actual GA4 Measurement ID
    this.reactGa = ReactGA;
  }

  initializeGA4() {
    if (
      window.location.host.includes("dev") ||
      window.location.host.includes("localhost")
    )
      this.reactGa.initialize(streamKeys.development);
    else if (window.location.host.includes("staging"))
      this.reactGa.initialize(streamKeys.staging);
    else this.reactGa.initialize(streamKeys.production);
  }

  trackButtonClick(event: string, label: string, data?: any) {
    const payload = {
      category: "Button Click",
      action: event,
      label: label || "button",
      ...(data ? data : {}),
    };
    this.reactGa.event(event, payload);
  }

  addToCart(data: object) {
    this.reactGa.event("add_to_cart", data);
  }

  trackApiEvents(event: string, eventName: string, data?: any) {
    const payload = {
      category: "API Event",
      action: event,
      label: eventName,
      ...(data ? data : {}),
    };
    this.reactGa.event(event, payload);
  }

  trackFeatureEvents(event: string, featureName: string, data?: any) {
    const payload = {
      category: "Account Feature Events",
      action: event,
      label: featureName,
      ...(data ? data : {}),
    };
    this.reactGa.event(event, payload);
  }

  trackFormSubmission(event: string, formName: string, data?: any) {
    const payload = {
      category: "Form Submission",
      action: event,
      label: formName,
      ...(data ? data : {}),
    };
    this.reactGa.event(event, payload);
  }

  userConversion() {
    this.reactGa.event({
      category: "Conversion",
      action: "Completed",
      label: "Purchase Completed and Email Verified", // You can customize the label based on your conversion event
    });
  }
}
