import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  isAuthenticated,
  logout,
  refreshUserDetails,
} from "../../redux/auth/index";
import { useAppDispatch } from "../../redux/store";

import StepProgress from "../../components/core/StepProgress";
import { useTitle } from "../../utils/UseTitleUtils";

interface PurchaseCompletedProps {}

const PurchaseCompleted = (props: PurchaseCompletedProps) => {
  const authenticated = useSelector(isAuthenticated);
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  useTitle("Purchase Completed!");

  useEffect(() => {
    if (authenticated) {
      dispatch(logout);
      appDispatch(refreshUserDetails());
    }
  }, [authenticated]);

  return (
    <div className="content-section d-flex poppins">
      <div className="col-12 col-lg-7 m-auto text-center">
        <div className="align-items-center d-flex flex-column vertical-center">
          <h1 className="h1-text poppins font-color-black">
            Purchase Completed!
          </h1>
          <div className="col-12 col-lg-9 col-md-8 pt-4 px-4">
            <StepProgress
              activeIndex={1}
              data={["PURCHASE", "CREATE ACCOUNT", "COMPANY"]}
            />
          </div>
          <div className="col-12 col-lg-11 col-md-10 pt-4 px-4">
            <span className="body-text d-block mb-4 lh-2">
              The next step in setting up your account is to confirm your email
              address.
            </span>
            <span className="body-text d-block lh-2">
              Check your designated email account for an email from
              noreply@insite.work
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseCompleted;
