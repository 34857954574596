import React from "react";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../utils/UseTitleUtils";
interface PageNotFoundProps {}

const PageNotFound = (props: PageNotFoundProps) => {
  const navigate = useNavigate();
  useTitle("404");
  return (
    <div className="content-section my-5 py-5 text-center">
      <h1 className="h1-text font-color-black mb-0">404</h1>
      <div className="four_zero_four_bg"></div>
      <div className="text-center">
        <span className="d-block h2-text">Look like you're lost</span>
        <span className="d-block">
          the page you are looking for not available!
        </span>
      </div>
      <div className="justify-content-center row mt-3">
        <div className="col-12 col-md-4">
          <button
            title="navigate back"
            aria-label="Navigate Back"
            className="btn primary-btn w-100"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
