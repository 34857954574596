import React from "react";

import { useTitle } from "../../utils/UseTitleUtils";
import CommonIcons from "utils/CommonIcons";

interface PurchaseCancelProps {}

const PurchaseCancel = (props: PurchaseCancelProps) => {
  useTitle("Purchase Cancelled!");
  return (
    <div className="content-section d-flex poppins">
      <div className="col-12 col-lg-7 m-auto text-center">
        <div className="align-items-center d-flex flex-column vertical-center">
          <CommonIcons.CancelIcon
            className="mb-4"
            style={{ height: 70, width: 70 }}
          />
          <h1 className="h1-text poppins font-color-red">
            Purchase Cancelled!
          </h1>
          <div className="col-12 col-lg-11 col-md-10 pt-4 px-4">
            <span className="body-text d-block mb-4 lh-2">
              Purchase has been cancelled by you. Please complete purchase in
              case of any error faced or try again later if error still persist.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseCancel;
