import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import users, { ResetPasswordDto, UserDto } from "../../services/users";

import { getAutocompleteType } from "../../components/contact/ContactForm";
import CenterLoading from "../../components/core/CenterLoading";
import { useTitle } from "../../utils/UseTitleUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface ResetPasswordProps {}
declare type ResetPasswordParams = {
  user_id?: string;
};

export declare type SetupAccount = {
  password?: string;
  confirmPassword?: string;
};
const defaultInput = {
  password: "",
  confirmPassword: "",
};

type SignUpError = SetupAccount & {
  email?: string;
  address?: string;
};

const ResetPassword = (props: ResetPasswordProps) => {
  const [inputs, setInputs] = useState<SetupAccount>(defaultInput);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams<ResetPasswordParams>();
  const [errors, setErrors] = useState<SignUpError>({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  useTitle("Change Password");

  const sentForgotPassword = async () => {
    setIsLoading(true);
    try {
      const sentForgotPasswordLink: ResetPasswordDto =
        await users.getResetUserDetail(params.user_id!);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const resetPassword = async () => {
    setIsLoading(true);
    try {
      const sentForgotPasswordLink = await users.setResetPassword({
        password: inputs.password,
        confirmPassword: inputs.confirmPassword,
        resetPasswordToken: params.user_id,
      });

      setIsLoading(false);
      navigate("/auth/login", {
        replace: true,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  function onResetpassword(e: React.FormEvent<HTMLFormElement>) {
    if (e) {
      e.preventDefault();
    }

    const err: SignUpError = {};
    if (params.user_id) {
      if (inputs.password == inputs.confirmPassword) {
        resetPassword();
      } else {
        err["confirmPassword"] = "Password is not match";
      }
      if (err.confirmPassword) {
        setErrors(err);
      }
    }
  }
  function errorMessage(field: string) {
    if (!errors) return null;
    if (typeof errors[field] === "undefined") return null;

    return (
      <Form.Control.Feedback className="font-color-red poppins" type="invalid">
        {errors[field]}
      </Form.Control.Feedback>
    );
  }

  function handleChange(
    fieldName: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    if (fieldName == "password" || fieldName == "confirmPassword") {
      delete errors["password"];
      delete errors["confirmPassword"];
    } else {
      delete errors[fieldName];
    }

    setInputs({
      ...inputs,
      [fieldName]: e.target.value,
    });
    setErrors(errors);
  }

  useEffect(() => {
    sentForgotPassword();
  }, []);

  return (
    <div className="align-content-center content-section d-flex flex-fill p-3 p-md-5 poppins row reset-password">
      <CenterLoading isVisible={isLoading} />

      <div>
        <h1 className="h1-text font-color-black mb-4">Change Password</h1>
        <Form className="row" onSubmit={onResetpassword}>
          <Form.Group className="mb-3 col-6" controlId="password">
            <Form.Label className="fw-bold">Password:</Form.Label>
            <InputGroup>
              <Form.Control
                type={passwordVisible ? "text" : "password"}
                required
                autoComplete={getAutocompleteType("password")}
                value={inputs.password}
                onChange={(e) => handleChange("password", e as any)}
                isInvalid={errors.password != undefined}
              />
              <button
                style={{
                  zIndex: 1,
                }}
                id="password-toggle"
                className="bg-white input-group-text rounded-0"
                aria-label="toggle password visibility"
                title="toggle visibility"
                type="button"
                onKeyDown={(e) => {
                  if (e.key == " " || e.key == "Enter") {
                    e.currentTarget.click();
                    e.preventDefault();
                  }
                }}
                onClick={() => {
                  window.password_show_hide("#password");
                }}
              >
                <FontAwesomeIcon icon={faEye} id="show_eye" />
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  className="d-none"
                  id="hide_eye"
                />
              </button>
              {errorMessage("password")}
            </InputGroup>
          </Form.Group>
          <div className="col-6">
            <Form.Group className="mb-4" controlId="confirmPassword">
              <Form.Label className="fw-bold">Confirm Password:</Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  required
                  autoComplete={getAutocompleteType("confirmPassword")}
                  value={inputs.confirmPassword}
                  onChange={(e) => handleChange("confirmPassword", e as any)}
                  isInvalid={errors.confirmPassword != undefined}
                />
                <button
                  style={{
                    zIndex: 1,
                  }}
                  id="confirmPassword-toggle"
                  className="bg-white input-group-text rounded-0"
                  aria-label="toggle password visibility"
                  title="toggle visibility"
                  type="button"
                  onKeyDown={(e) => {
                    if (e.key == " " || e.key == "Enter") {
                      e.currentTarget.click();
                      e.preventDefault();
                    }
                  }}
                  onClick={() => {
                    window.password_show_hide("#confirmPassword");
                  }}
                >
                  <FontAwesomeIcon icon={faEye} id="show_eye" />
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    className="d-none"
                    id="hide_eye"
                  />
                </button>
                {errorMessage("confirmPassword")}
              </InputGroup>
            </Form.Group>

            <div>
              <button
                title="Reset password"
                aria-label="Reset Password"
                type="submit"
                className="btn primary-btn-lg w-100"
              >
                Reset Password
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
