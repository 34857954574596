import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import users, { UserDetailRes, UserDto } from "services/users";

import { getAutocompleteType } from "../../components/contact/ContactForm";
import CenterLoading from "../../components/core/CenterLoading";
import { useTitle } from "utils/UseTitleUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface AccountSetupProps {}
declare type AccountSetupParams = {
  user_id?: string;
};

export declare type SetupAccount = {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  ppAccepted?: boolean;
  tcAccepted?: boolean;
  accountSetupToken?: string;
};
const defaultInput = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  ppAccepted: false,
  tcAccepted: false,
  accountSetupToken: "",
};

type SignUpError = SetupAccount & {
  email?: string;
  address?: string;
};
const AccountSetup = (props: AccountSetupProps) => {
  const [inputs, setInputs] = useState<SetupAccount>(defaultInput);
  const [errors, setErrors] = useState<SignUpError>({});
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const params = useParams<AccountSetupParams>();
  const navigate = useNavigate();
  useTitle("Employee Account Setup");

  const setUserDetails = async () => {
    setIsLoading(true);
    try {
      const setUserDetailRes = await users.setUserSetup(inputs);
      setIsLoading(false);
      navigate("/auth/login", {
        replace: true,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getUserDetail = async () => {
    setIsLoading(true);
    try {
      const getUserDetailRes: UserDetailRes = await users.getUserSetup(
        params.user_id
      );
      setInputData(getUserDetailRes);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  function setInputData(value: UserDetailRes) {
    setInputs({
      firstName: value?.firstName ?? "",
      lastName: value?.lastName ?? "",
      email: value?.email ?? "",
      accountSetupToken: value.accountSetupToken ?? "",
      password: "",
      confirmPassword: "",
      ppAccepted: false,
      tcAccepted: false,
    });
  }

  function handleChange(
    fieldName: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    if (fieldName == "password" || fieldName == "confirmPassword") {
      delete errors["password"];
      delete errors["confirmPassword"];
    } else {
      delete errors[fieldName];
    }

    setInputs({
      ...inputs,
      [fieldName]: e.target.value,
    });
    setErrors(errors);
  }

  function onSetupAccount(e: React.FormEvent<HTMLFormElement>) {
    if (e) {
      e.preventDefault();
    }
    setUserDetails();
  }
  function errorMessage(field: string) {
    if (!errors) return null;
    if (typeof errors[field] === "undefined") return null;

    return (
      <Form.Control.Feedback className="font-color-red poppins" type="invalid">
        {errors[field]}
      </Form.Control.Feedback>
    );
  }

  useEffect(() => {
    if (params.user_id) {
      getUserDetail();
    }
  }, []);

  return (
    <div className=" p-3 p-md-5">
      <CenterLoading isVisible={isLoading} />

      <div>
        <h1 className="h1-text font-color-black mb-4">
          Employee Account Setup
        </h1>
        <span className="body-text">
          Confirm the details your employer has provided for you and create a
          password
        </span>

        <Form className="row" onSubmit={onSetupAccount}>
          <div className="col-12 col-md-6">
            <div className="row">
              <Form.Group className="mb-3 col-6" controlId="newPassword">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  autoComplete={getAutocompleteType("firstName")}
                  value={inputs.firstName}
                  onChange={(e) => handleChange("firstName", e as any)}
                  isInvalid={errors.firstName != undefined}
                />
                {errorMessage("firstName")}
              </Form.Group>

              <Form.Group className="mb-3 col-6" controlId="lastName">
                <Form.Label>Last Name:</Form.Label>
                <Form.Control
                  type="text"
                  required
                  autoComplete={getAutocompleteType("lastName")}
                  value={inputs.lastName}
                  onChange={(e) => handleChange("lastName", e as any)}
                  isInvalid={errors.firstName != undefined}
                />
                {errorMessage("lastName")}
              </Form.Group>
            </div>

            <Form.Group className="col-12" controlId="identity">
              <Form.Label className="fw-bold">Email Address:</Form.Label>
              <Form.Control
                type="email"
                required
                value={inputs.email}
                disabled
                isInvalid={errors.email != undefined}
              />
              {errorMessage("email")}
            </Form.Group>
          </div>

          <div className="col-12 col-md-6">
            <Form.Group
              className="col-12 mb-3 mt-md-0 mt-3 "
              controlId="password"
            >
              <Form.Label className="fw-bold">Password:</Form.Label>
              <InputGroup>
                <Form.Control
                  type={passwordVisible ? "text" : "password"}
                  required
                  autoComplete={getAutocompleteType("password")}
                  value={inputs.password}
                  onChange={(e) => handleChange("password", e as any)}
                  isInvalid={errors.password != undefined}
                />
                <button
                  style={{
                    zIndex: 1,
                  }}
                  id="password-toggle"
                  className="bg-white input-group-text rounded-0"
                  aria-label="toggle password visibility"
                  title="toggle visibility"
                  type="button"
                  onKeyDown={(e) => {
                    if (e.key == " " || e.key == "Enter") {
                      e.currentTarget.click();
                      e.preventDefault();
                    }
                  }}
                  onClick={() => {
                    window.password_show_hide("#password");
                  }}
                >
                  <FontAwesomeIcon icon={faEye} id="show_eye" />
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    className="d-none"
                    id="hide_eye"
                  />
                </button>
                {errorMessage("password")}
              </InputGroup>
            </Form.Group>
            <Form.Group className="col-12 mb-3 " controlId="confirmPassword">
              <Form.Label className="fw-bold">Confirm Password:</Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  required
                  autoComplete={getAutocompleteType("confirmPassword")}
                  value={inputs.confirmPassword}
                  onChange={(e) => handleChange("confirmPassword", e as any)}
                  isInvalid={errors.confirmPassword != undefined}
                />
                <button
                  style={{
                    zIndex: 1,
                  }}
                  id="confirmPassword-toggle"
                  className="bg-white input-group-text rounded-0"
                  aria-label="toggle password visibility"
                  title="toggle visibility"
                  type="button"
                  onKeyDown={(e) => {
                    if (e.key == " " || e.key == "Enter") {
                      e.currentTarget.click();
                      e.preventDefault();
                    }
                  }}
                  onClick={() => {
                    window.password_show_hide("#confirmPassword");
                  }}
                >
                  <FontAwesomeIcon icon={faEye} id="show_eye" />
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    className="d-none"
                    id="hide_eye"
                  />
                </button>
                {errorMessage("confirmPassword")}
              </InputGroup>
            </Form.Group>
          </div>

          <div className="row pe-0">
            <div className="col-12 col-12 col-md-6 mt-md-0 mt-3 pe-0 ">
              <div>
                <Form.Group className="align-items-center checkbox d-flex mb-4">
                  <Form.Check
                    required
                    value={inputs.ppAccepted ? 1 : 0}
                    onChange={(e) =>
                      setInputs({
                        ...inputs,
                        ppAccepted: e.target.checked,
                      })
                    }
                  />
                  <label
                    htmlFor="show_pass"
                    className="body-text poppins ps-2 d-inline-block"
                  >
                    I have read and agree to the{" "}
                    <a
                      href="/assets/legal/privacy_policy.pdf"
                      role="link"
                      target="_blank"
                      onKeyDown={(e) => {
                        if (e.key == " " || e.key == "Enter") {
                          e.currentTarget.click();
                          e.preventDefault();
                        }
                      }}
                    >
                      Privacy Policy
                    </a>
                  </label>
                </Form.Group>

                <Form.Group className="align-items-center checkbox d-flex mb-4">
                  <Form.Check
                    required
                    onChange={(e) =>
                      setInputs({
                        ...inputs,
                        tcAccepted: e.target.checked,
                      })
                    }
                  />
                  <label
                    htmlFor="show_pass"
                    className="body-text poppins ps-2 d-inline-block"
                  >
                    I have read and agree to the{" "}
                    <a
                      href="/assets/legal/terms_condition.pdf"
                      role="link"
                      target="_blank"
                      onKeyDown={(e) => {
                        if (e.key == " " || e.key == "Enter") {
                          e.currentTarget.click();
                          e.preventDefault();
                        }
                      }}
                    >
                      {" "}
                      Terms and Conditions
                    </a>
                  </label>
                </Form.Group>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-3 mt-md-0 pe-0 ps-md-3 ps-2">
              <button
                title="next"
                aria-label="Next Step"
                type="submit"
                className="btn primary-btn-lg w-100"
                disabled={
                  !(
                    inputs.ppAccepted &&
                    inputs.tcAccepted &&
                    inputs?.password != "" &&
                    inputs?.confirmPassword != "" &&
                    inputs?.password?.trim() == inputs?.confirmPassword?.trim()
                  )
                }
              >
                Next
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AccountSetup;
