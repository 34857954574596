import React, { useState } from "react";
import Slider from "react-slick";
import { FileDto } from "services/users";
import ViewPhotoModal from "../../pages/pah/worksites/jobs/ViewPhotoModal";
import { getPhotoQuestion } from "utils/CommonUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Svgs } from "utils/IconUtils";
import CommonIcons from "utils/CommonIcons";

declare type ViewPhotoProps = {
  imageFiles?: FileDto[];
  imageTitleText?: string;
  title?: string;
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className="end-0 bg-transparent border-0 me-3 position-absolute top-50 translate-middle-y"
      onClick={onClick}
      title="next"
      aria-label="Next Photo"
    >
      <FontAwesomeIcon icon={faChevronRight} className="image-arrow fa-sharp" />
    </button>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      onClick={onClick}
      className="position-absolute ms-3 bg-transparent border-0 start-0 top-50 translate-middle-y"
      style={{ zIndex: 1 }}
      aria-label="Previous Page"
      title="previous"
    >
      <FontAwesomeIcon icon={faChevronLeft} className="fa-sharp image-arrow" />
    </button>
  );
}
const ViewPhoto = ({
  imageFiles,
  title,
  imageTitleText,
  ...props
}: ViewPhotoProps) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (indexOfCurrentSlide) => {
      setCurrentSlide(indexOfCurrentSlide);
    },
  };

  const [isViewPhotoModal, setIsViewPhotoModal] = useState(false);

  const downloadImages = (e: number) => {
    const currentImage = imageFiles[e];
    if (currentImage) {
      const link = document.createElement("a");
      link.href = currentImage.url;
      link.download = currentImage.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="col-12 d-flex flex-column h-100 position-relative">
      <ViewPhotoModal
        isVisible={isViewPhotoModal}
        imageList={imageFiles}
        title={title}
        onClose={() => {
          setIsViewPhotoModal(false);
        }}
      />
      <div className="background-border p-2 fw-bold text-center">
        <span>{title ?? "Issue/Hazard Notification Photos:"}</span>
      </div>
      <div className="view-photo h-100">
        {imageFiles?.length > 0 ? (
          <Slider {...settings}>
            {imageFiles.map((e, index) => {
              return (
                <div key={`image=${index}`}>
                  <img src={e?.inlineUrl} alt={e.fileType} />
                </div>
              );
            })}
          </Slider>
        ) : (
          <span className="d-flex flex-column h-100 justify-content-center text-white text-center fs-3">
            No Images Provided
          </span>
        )}
      </div>
      <div className="align-items-center background-border d-flex fw-bold justify-content-between">
        <div className="flex-grow-1">
          <span className="d-block text-center">
            {imageFiles?.length > 0 ? currentSlide + 1 : currentSlide} of{" "}
            {imageFiles?.length ?? "0"}
          </span>
        </div>
        <div className="p-1 h-100 border-start border-dark">
          <button
            className="bg-transparent d-flex gap-3 justify-content-center align-items-center h-100 border-0"
            onClick={() => {
              downloadImages(currentSlide);
            }}
            aria-label="Download Images"
            title="download"
          >
            <span className="fw-600">DOWNLOAD PHOTO</span>
            <FontAwesomeIcon icon={faDownload} size="1x" color="#000" />
          </button>
        </div>
        <div className="p-1 border-start border-dark">
          <button
            className="bg-transparent border-0"
            onClick={() => {
              setIsViewPhotoModal(true);
            }}
            aria-label="Expand Image"
            title="expand"
            aria-expanded={isViewPhotoModal}
          >
            <CommonIcons.ExpandIcon
              style={{ height: "35px", width: "35px", cursor: "pointer" }}
            />
          </button>
        </div>
      </div>
      <div
        className="bg-dark d-flex flex-fill flex-row justify-content-center p-1 position-absolute w-100"
        style={{ top: "41px" }}
      >
        <span className="font-color-white text-center">
          {(imageFiles?.length > 0 &&
            getPhotoQuestion(imageFiles[currentSlide].fileType)) ||
            imageTitleText ||
            "Photos of issue:"}
        </span>
      </div>
    </div>
  );
};

export default ViewPhoto;
