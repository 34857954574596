import { useDispatch } from "react-redux";

import { configureStore } from "@reduxjs/toolkit";

import AudioReducer, { AudioDto } from "./audioplayer";
import AuthReducer, { AuthState } from "./auth";
import CartReducer, { PricingState } from "./cart";
import swmsSliceReducer, { SWMSState } from "./swms";
import workSiteSliceReducer, { WorkSiteDocState } from "./worksiteDoc";
import userSliceReducer, { UserState } from "./user";
import AuditReducer from "./audit";
import { CreateAuditTemplateDto } from "services/audit";

type ReduxState = {
  auth: AuthState;
  workSite: WorkSiteDocState;
  cart: PricingState;
  swms: SWMSState;
  user: UserState;
  audioPlayer: AudioDto;
  audit: CreateAuditTemplateDto;
};

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    cart: CartReducer,
    workSite: workSiteSliceReducer,
    swms: swmsSliceReducer,
    user: userSliceReducer,
    audioPlayer: AudioReducer,
    audit: AuditReducer,
  },
  devTools:
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
});

export type AppDispatch = typeof store.dispatch;

export { ReduxState };
