import { AddressData } from "components/address";

/**
 * Component: Which is used in address component to pass
 */
declare type FormatAddressType = "component" | "plain";
export default {
  formatAddress: (
    address: AddressData | undefined,
    type: FormatAddressType = "component"
  ) => {
    if (type == "component") {
      const arr: string[] = [];
      arr.push(address?.subpremise ?? "");
      arr.push(
        `${address?.streetNumber ?? ""} ${address?.streetName ?? ""}`.trim()
      );
      arr.push(
        `${address?.suburb ?? ""} ${address?.state ?? ""} ${
          address?.postCode ?? ""
        }`.trim()
      );
      arr.push(address?.country ?? "");

      return arr.filter((e) => e.length).join(", ");
    }

    return "";
  },
  verifyAddress: (
    address: AddressData | undefined,
    type: FormatAddressType = "component"
  ): string | undefined => {
    if (!address) return "Please enter address";
    const postCode = address?.postCode || address.postalCode;
    const addrError = [];
    if (address.streetName == "") addrError.push("Street Name");
    if ((address?.suburb || "").trim() == "") addrError.push("Suburb/Province");
    if (address.country == "") addrError.push("Country");
    if ((postCode || "").trim() == "") addrError.push("Postcode");

    if (type == "component") {
      if (
        address.postCode == "" ||
        address.streetName == "" ||
        (address?.suburb || "").trim() == "" ||
        address.country == ""
      ) {
        return `Required address fields missing`;
      }
    }

    return undefined;
  },
};
